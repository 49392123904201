import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"


const FamilyInformationTable = () => {
  const [familyInformation, setFamilyInformation] = useState({ data: [] })
  const [addFamilyMember, setAddFamilyMember] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${user_id}`
      )
      const data = await response.json()
      setFamilyInformation({ ...familyInformation, data: data?.data || [] })
    }
    fetchData()
  }, []);

  useEffect(() => {
    setAddFamilyMember(canAddFamilyMember());

  }, [familyInformation])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/delete_family_information`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        ac_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setFamilyInformation({
          ...familyInformation,
          data: familyInformation?.data?.filter(each => each.ac_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  
  const canAddFamilyMember = () => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const { user_role, membershipInfo} = user;

    if(user_role !== 0) {
      return true;
    }
    if(membershipInfo?.memberShipType !== 'SOF_RC_CUS_FREE'){
      return true;
    }
    if(membershipInfo?.memberShipType === 'SOF_RC_CUS_FREE' && familyInformation.data.length > 0 ) {
      return false;
    }
    return true;
  }


  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email Address",
        field: "email_address",
        sort: "asc",
        width: 100,
      },
      {
        label: "Phone Number",
        field: "phone_number",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: familyInformation?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add-family-information?id=${each.ac_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        relationship: each?.ac_reliation || "",
        name: each?.ac_fname + " " + each?.ac_lname || "",
        email_address: each?.ac_email || "",
        phone_number: each?.ac_phone || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.ac_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.ac_fname} ${each.ac_lname}?`
                  ) &&
                  handleRemoveRole(each.ac_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Family Information list</CardTitle>
              { addFamilyMember && 
                <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add-family-information"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Family Information
                  </Link>
                </div>
                <div className="mx-2">
                  <Link
                    to="/send-family-request"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Send Family Request
                  </Link>
                </div>
                </div>
              }
              {
                !addFamilyMember && 
                <div class="invalid-input">
                  <strong>You have One Entry only please subscribe!</strong>
                </div>
              }
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FamilyInformationTable
