import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const BpContactTable = () => {

  const [BpContactTable, setBpContactTable] = useState({ data: [] })
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
 

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_bp_contact_info?user_id=${user_id}`
      )
      const data = await response.json()
      setBpContactTable({ ...BpContactTable, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_bp_contact`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        pfl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setBpContactTable({
          ...BpContactTable,
          data: BpContactTable?.data?.filter(each => each.pfl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "View",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Designation",
        field: "CDesignation",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "CEmailID",
        sort: "asc",
        width: 100,
      },
      {
        label: "Phone",
        field: "CPhone",
        sort: "asc",
        width: 100,
      },
      {
        label: "ContactKey",
        field: "ContactKey",
        sort: "asc",
        width: 100,
      },      
    ],
    rows: BpContactTable?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/view-bp-contact?id=${each.vcl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-eye"></i>
            </Link>
          ) || "",
        fullname: `${each?.First_name} ${each?.Last_name}`,
        CDesignation: each?.CDesignation,
        CEmailID: each?.CEmailID || "",
        CPhone: each?.CPhone || "",
        ContactKey: each?.ContactKey || "",  
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>              
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BpContactTable
