import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { userId } from "helpers/userId"  
  import React, { memo, useEffect, useState } from "react"
  import { useNavigate, useLocation } from "react-router-dom"
  import { Container, Form, Button, Spinner } from "reactstrap"
  
  const intialForm = {
    
    regionsList: [],    
    First_name: "",
    Middle_name: "",
    Last_name: "",
    CEmailID: "",
    CPhone: "",
    CDesignation: "",    
    CStreet1: "",
    CStreet2: "",
    CCity: "",
    CCountry: "",
    CRegion: "",
    CZip: "",
  }
  
  const EditBusinessContact = () => {
    const [form, setForm] = useState(intialForm)
    // history for navigation
    const history = useNavigate()
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id") 
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      if (name === "CCountry") {
        const regionList = (await fetchRegionsList(value)) || []
        setForm({
          ...form,
          [name]: value,
          regionsList: regionList,
          CRegion: regionList[0]?.region || "",
        })
      } else {
        setForm({ ...form, [name]: value })
      }
    }
  
    const handleFormSubmit = async () => {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_single_list_of_contact`
  
        const data = {
          vcl_id: form.vcl_id,
          First_name: form.First_name,
          Middle_name: form.Middle_name,
          Last_name: form.Last_name,
          CEmailID: form.CEmailID,
          CPhone: form.CPhone,
          CDesignation: form.CDesignation,          
          CStreet1: form.CStreet1,
          CStreet2: form.CStreet2,
          CCity: form.CCity,
          CCountry: form.CCountry,
          CRegion: form.CRegion,
          CZip: form.CZip,
        }
  
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
  
        const response = await fetch(url, options, {})
  
        const updateContactList = await response.json()
  
        if (updateContactList?.success === true) {
          setApiStatus({ ...apiStatus, inProgress: false })
          showMessage(updateContactList.message, "success")
  
          setTimeout(() => {
            history(-1)
          }, [2000])
        } else {
          showMessage(updateContactList?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(updateContactList?.message, "error", false)
      }
    }

  
    async function fetchRegionsList(countryName) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        )
  
        const data = await response.json()
  
        return data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  

    useEffect(() => {
        async function fetchData() {
          try {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_edit_contact_list?vcl_id=${id}`
    
            const response = await fetch(url)
    
            const data = await response.json()
    
            if (data?.success) {
              const regionsList = await fetchRegionsList(data?.data.CCountry);
    
              const conctactData = {
                  ...data?.data,               
                  regionsList: regionsList || [],
              };
    
              setForm({ ...form, ...conctactData })
            }
          } catch (err) {
            console.log(err)
          }
        }
    
        fetchData()
      }, [])
    return (
      <React.Fragment>
        <div className="page-content">
          <AlertMessage />
          <Container fluid>
          <div className="page-title-box">
              <h4 className="font-size-18">EDIT Contact Persons</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="/">Operations</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/">Edit Contact Persons</a>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
  
                      <Form className="my-4">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="First_name" className="form-label">
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="First_name"
                                id="First_name"
                                placeholder="First Name"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.First_name}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="Middle_name" className="form-label">
                                Middle Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="Middle_name"
                                id="Middle_name"
                                placeholder="Middle Name"
                                value={form.Middle_name}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="Last_name" className="form-label">
                                Last name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="Last_name"
                                id="Last_name"
                                placeholder="Last name"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.Last_name}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label
                                htmlFor="validationTooltipUsername"
                                className="form-label"
                              >
                                Email Address
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="validationTooltipUsernamePrepend"
                                  >
                                    @
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="CEmailID"
                                  id="validationTooltipUsername"
                                  placeholder="Email Address"
                                  aria-describedby="validationTooltipUsernamePrepend"
                                  disabled=""
                                  onChange={e => handleForm(e, "ownersList")}
                                  value={form.CEmailID}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="validationTooltip03" className="form-label">
                                Phone Number
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                name="CPhone"
                                id="validationTooltip03"
                                placeholder="Phone Number"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.CPhone}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="validationTooltip01" className="form-label">
                              Designation
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                name="CDesignation"
                                id="validationTooltip03"
                                placeholder="Designation"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.CDesignation}
                              />                             
                            </div>
                          </div>
                        
                          <h4 className="card-title">Address</h4>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Street 1
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="CStreet1"
                                id="validationCustom05"
                                placeholder="Street 1"
                                value={form.CStreet1}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="validationCustom06" className="form-label">
                                Street 2
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="CStreet2"
                                id="validationCustom06"
                                placeholder="Street 2"
                                value={form.CStreet2}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
  
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="validationCustom07" className="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="CCity"
                                id="validationCustom07"
                                placeholder="City"
                                value={form.CCity}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="country" className="form-label">
                                Country
                              </label>
                              <select
                                className="form-select"
                                name="CCountry"
                                id="country"
                                value={form.CCountry}
                                onChange={e => handleForm(e, "ownersList")}
                              >
                                <option value="">Select Country</option>
                                <option value="USA" selected="">
                                  USA
                                </option>
                                <option value="INDIA">INDIA</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="region" className="form-label">
                                Region
                              </label>
                              <select
                                className="form-select"
                                name="CRegion"
                                id="CRegion"
                                value={form.CRegion}
                                onChange={e => handleForm(e, "ownersList")}
                              >
                                {form.regionsList?.length === 0 ? (
                                  <option value="" selected="">
                                    Select Region
                                  </option>
                                ) : (
                                  form.regionsList?.map(each => (
                                    <option value={each.region} key={each.region}>
                                      {each.region}
                                    </option>
                                  )) 
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="validationCustom07" className="form-label">
                                zip
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="CZip"
                                oninput="validateZIP()"
                                id="zipCode"
                                placeholder="zip"
                                value={form.CZip}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                              <div id="zipError"></div>
                            </div>
                          </div>
                        </div>
                      </Form>
                      
                  </div>
                </div>
              </div>           
            </div>
  
            
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        Save
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        history(-1)
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default memo(EditBusinessContact)
  
