import React from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import VerticalLayout from "components/VerticalLayout"
import HorizontalLayout from "components/HorizontalLayout"

//constants
import { layoutTypes } from "../../constants/layout"

const AuthmiddleWarePrimary = props => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))

  const getLayout = layoutType => {
    let Layout = VerticalLayout
    switch (layoutType) {
      case layoutTypes.VERTICAL:
        Layout = VerticalLayout
        break
      case layoutTypes.HORIZONTAL:
        Layout = HorizontalLayout
        break
      default:
        break
    }
    return Layout
  }

  if (
    !!localStorage.getItem("userData") &&
    !!parseInt(localStorage.getItem("otp_status"))
  ) {
    return (
      <Navigate
        to={{ pathname: "/dashboard", state: { from: props.location } }}
      />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default AuthmiddleWarePrimary
