import FriendInformationTable from "components/DataTables/FriendInformationTable"
import React from "react"
import { Container } from "reactstrap"

const FriendInformation = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">FRIEND INFORMATION</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Friend Information</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <FriendInformationTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FriendInformation
