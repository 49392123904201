import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, Container } = require("reactstrap")
import "../../styles/datatables.scss"
import { userId } from "helpers/userId"
import { Link } from "react-router-dom"
import { notificationPages } from "components/CommonForBoth/TopbarDropdown/NotificationDropdown"

const Notifications = ({ userData = [] }) => {
  const [notifications, setNotifications] = useState({ data: [] })
  const fetchNotifications = async each => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/notifications/get_notifications?user_id=${userId}&type=1`
      )
      const data = await response.json()
      if (data?.success) {
        setNotifications({ ...notifications, data: data?.data || [] })
      } else {
        showMessage(data?.message, "error")
      }
    } catch (e) {}
  }

  useEffect(() => {
    fetchNotifications()
  }, [])

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Content",
        field: "content",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sent by",
        field: "sent_by",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: notifications.data.map((each, index) => {
      const status = each?.notify_status || "";
      const statusStyle = status === "No" ? { backgroundColor: "green", color: "white" } : {};
  
      return {
        sno: index + 1,
        date: each?.notify_date || "",
        type: each?.notify_type || "",
        content: each?.content || "",
        sent_by: each?.byUidName || "",
        status: (
          <span style={statusStyle}>
            {status}
          </span>
        ),
        action: (
          <Link
            to={`${notificationPages[each.notify_type]}?id=${each.id}&type=1`}
            className="btn btn-outline-primary btn-sm edit"
            title="Edit"
          >
            <i className="fas fas fa-eye"></i>
          </Link>
        ),
      };
    }),
  };
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="page-title-box">
            <h4 className="font-size-18">Notifications</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Workspace</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Notifications List</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body"></div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      bordered
                      data={data}
                      noBottomColumns={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notifications
