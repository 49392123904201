import BusinessPartnerTable from "components/DataTables/BusinessPartnerTable"
import React, { useEffect } from "react"
import { Container } from "reactstrap"

const BusinessPartnersList = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("assetId")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">Business Partner</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Operations</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Business Partner</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <BusinessPartnerTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BusinessPartnersList

