import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  
  import GetSofAssetTable from "components/Customers/Services/GetSofAssetTable"
  import { loginUserData, userId } from "helpers/userId"
  import React, { useState } from "react"
  import { useNavigate, useLocation } from "react-router-dom"
  import { Container } from "reactstrap"
  
  const initialFormDetails = {
    type: 0,
    searchFilter: "",
    userData: [],
    ac_id: 0,
    userFullName: "",
    city: "",
    country: "",
    relationsList: [],
    relationship: "",
    usersListData: [],
  }
  
  const GetSofWillBeneficiary = () => {
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
    const history = useNavigate()
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
  
    const fetchUserInfo = async value => {
      try {
        let queryParams = ""
        switch (value) {
          case "1":
            queryParams = `family=${value}`
            break
          case "2":
            queryParams = `friend=${value}`
            break
          case "3":
            queryParams = `ac_id=${form.username}`
            break
          default:
            return []
        }
  
        let url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/account_info?${queryParams}&user_id=${userId}`
        const response = await fetch(url)
        const data = await response.json()
  
        return data?.data || []
      } catch (e) {
        showMessage(e, "error", false)
        return []
      }
    }
  
    const handleForm = async e => {
      let { name, value } = e.target
      // setForm({...form, searchFilter: fasdfsa })
      if (name == "type") {
        const usersData = await fetchUserInfo(value)
        setForm({
          ...form,
          [name]: value,
          usersListData: usersData,
        })
      } else {
        setForm({ ...form, [name]: value })
      }
    }
  
    const fetchUserRelations = async () => {
      try {
        let url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list?type=1`
        const response = await fetch(url)
        const data = await response.json()
  
        return data?.data || []
      } catch (e) {
        showMessage(e, "error", false)
        return []
      }
    }
  
    const handleFormSubmit = async () => {
      if (form.type && form.username) {
        const userData = await fetchUserInfo("3")
        setForm({
          ...form,
          userData,
          userFullName: "",
          city: "",
          country: "",
          ac_id: 0,
          relationsList: [],
        })
      }
    }
  
    const handleSelectedUser = async each => {
      try {
        if (each?.ac_id) {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_account_info_from_beneficiaries_sof`
  
          const data = {
            ac_id: each.ac_id,
            wl_id: id,
            user_id: userId,
          }
  
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
  
          const result = await response.json()
  
          if (result?.success) {
            showMessage(result?.message, "success")
            history(-1)
          } else {
            showMessage(result?.message, "error", false)
          }
        }
      } catch (e) {
        showMessage(e, "error", false)
      }
    }
  
    const handleSendRequest = async () => {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_sof_owner`
  
        const data = {
          by_uid: userId,
          to_uid: form.ac_id,
          notify_relation: form.relationship,
          notify_type: "Family Request",
          user_phone: form.user_phone,
          notify_name: "",
          notify_status: "No",
        }
  
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
  
        const response = await fetch(url, options, {})
  
        const sendNofication = await response.json()
  
        if (sendNofication?.success === true) {
          showMessage(sendNofication?.message, "success")
          setTimeout(() => {
            history("/family-information")
          }, 3000)
        } else {
          showMessage(sendNofication?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(e, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    }
  
    return (
      <React.Fragment>
        <div className="page-content">
          <AlertMessage />
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">Get SOF</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="/">Services</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/">Get SOF</a>
                </li>
              </ol>
            </div>
  
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <button
                    type="button"
                    onClick={() => history(-1)}
                    className="btn btn-danger my-2"
                  >
                    Back to Will Info
                  </button>
                </div>
              </div>
            </div>
  
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="h4 card-title">Search Family Member</div>
                  <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                      <div className="mb-3 position-relative">
                        <label htmlFor="type" className="form-label">
                          Search
                        </label>
                        <select
                          className="form-select"
                          name="type"
                          required
                          onChange={handleForm}
                          value={form.type}
                        >
                          <option value={"0"}>Select Search</option>
                          <option value={"1"}>by Family</option>
                          <option value={"2"}>by Friend</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3 position-relative">
                        <label htmlFor="username" className="form-label">
                          Select User
                        </label>
                        <select
                          className="form-select"
                          name="username"
                          id="username"
                          required
                          onChange={handleForm}
                          value={form.username}
                        >
                          <option value={"0"}>Select Search</option>
                          {form.usersListData.map(each => (
                            <option key={each.ac_id} value={each.ac_id}>
                              {each.ac_fname} {each.ac_lname}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mt-3"
                        onClick={handleFormSubmit}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            {form.userFullName ? (
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="h4 card-title">Send Family Member</div>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-3">
                        <div className="mb-3 position-relative">
                          <label className="form-label" htmlFor="searchFilter">
                            Request To
                          </label>
                          <p>{form.userFullName || ""}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3 position-relative">
                          <label htmlFor="relationship" className="form-label">
                            Relation
                          </label>
                          <select
                            className="form-select"
                            name="relationship"
                            id="relationship"
                            required
                            onChange={handleForm}
                            value={form.relationship}
                          >
                            <option value="">Select Search</option>
                            {form.relationsList.map(each => (
                              <option value={each.url_name} key={each.url_name}>
                                is {each.url_name} of
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3 position-relative">
                          <label className="form-label" htmlFor="searchFilter">
                            Request By:
                          </label>
                          <p>
                            {loginUserData.user_fname} {loginUserData.user_lname}
                          </p>
                        </div>
                      </div>
  
                      <div className="col-md-3 d-flex align-items-center justify-content-center">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mt-3"
                          onClick={handleSendRequest}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="h4 card-title m-4">List of Names</div>
                    <GetSofAssetTable
                      userData={form.userData}
                      handleSelectedUser={handleSelectedUser}
                    />
                  </div>
                </div>
              </div>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default GetSofWillBeneficiary
  