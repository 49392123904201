import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
  Alert,
  Spinner
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

// import images
import logoSm from "../../assets/images/soflogo-min.png"
import { useState } from "react"
import { useEffect } from "react"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
// import axios from "axios"

const Register = () => {
  const history = useNavigate()
  const [isActive, setisActive] = useState(false)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })


  // const dispatch = useDispatch()

  const { user } = useSelector(state => ({
    user: state.Account.user,
  }))
  const handleIsActive = () => {
    setisActive(true)
  }

  // useEffect(() => {
  //   if (user) {
  //     setTimeout(() => history("/login"), 3000)
  //   }

  //   // setTimeout(() => {
  //   //     dispatch(resetRegisterFlag());
  //   // }, 3000);
  // }, [dispatch, user, history])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(/[A-Z]/, "Atleast one Capital letter")
        .matches(/[a-z]/, "Atleast one lower letter")
        .matches(/[0-9]/, "Atleast one number")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Atleast one special character")
        .min(8, "Password minimum of 8 characters"),
      firstName: Yup.string().required("Please Enter first name"),
      lastName: Yup.string().required("Please Enter last name"),
    }),

    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/signup`
      const data = {
        user_name: values.username,
        user_email: values.email,
        user_pass: values.password,
        user_fname: values.firstName,
        user_lname: values.lastName,
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})

        const { result: signUp } = await response.json()

        if (signUp?.success === true) {
          showMessage("Your account registered successfully", "success")
          setTimeout(() => {
            history("/register-success")
          }, 2000)
        } else {
          showMessage(signUp?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }

      // dispatch(registerUser(values))
    },
  })
  const capitalRegex = /[A-Z]/
  const lowerCaseLetter = /[a-z]/
  const numRegex = /[0-9]/
  const specialRegex = /[!@#$%^&*(),.?":{}|<>]/

  const isCapital = capitalRegex.test(validation.values.password)
  const isLowercase = lowerCaseLetter.test(validation.values.password)
  const isNum = numRegex.test(validation.values.password)
  const isSpecialChar = specialRegex.test(validation.values.password)
  const isMinLength = validation.values.password.length > 7

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   props.registerUser(values)
  // }

  document.title = "Register | SOF"
  return (
    <React.Fragment>
      <AlertMessage />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Free Register</h5>
                    <p className="text-white-50">
                      Get your free sof account now.
                    </p>
                    <Link to="/login" className="logo logo-admin">
                      <img src={logoSm} height="60" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className="p-3">
                    {user ? (
                      <Alert
                        color="success"
                        style={{ marginTop: "13px" }}
                        className="mt-5"
                      >
                        Register User Successful
                      </Alert>
                    ) : null}
                    <Form
                      className="mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="firstName">
                          First Name
                        </Label>
                        <Input
                          name="firstName"
                          className="form-control"
                          placeholder="Enter first name"
                          type="text"
                          id="firstName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ""}
                          invalid={
                            validation.touched.firstName &&
                            validation.errors.firstName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="lastName">
                          Last Name
                        </Label>
                        <Input
                          name="lastName"
                          className="form-control"
                          placeholder="Enter last name"
                          type="text"
                          id="lastName"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ""}
                          invalid={
                            validation.touched.lastName &&
                            validation.errors.lastName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastName}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="useremail">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="useremail"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          Password
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          id="userpassword"
                          className="form-control mb-3"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          onClick={handleIsActive}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {isActive && (
                          <div className="verify-password">
                            <div
                              className={
                                !isLowercase
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one lower letter
                            </div>
                            <div
                              className={
                                !isCapital
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one captital letter
                            </div>
                            <div
                              className={
                                !isNum ? "password-invalid" : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one Number
                            </div>
                            <div
                              className={
                                !isSpecialChar
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one Special Character
                            </div>
                            <div
                              className={
                                !isMinLength
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Minimum 8 charaters long
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="mb-3 row">
                        { apiStatus.inProgress ? 
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          </div> 
                        :
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              >
                              Register
                            </button>
                          </div>
                        }
                      </div>

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <p className="mb-0">
                            By registering you agree to the SOF{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} SOF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
