import React, { useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody } = require("reactstrap")
import "../../../styles/datatables.scss"

const GetSofBusinessTable = ({ userData, handleSelectedUser }) => {
  const handleFormSubmit = async each => {
    handleSelectedUser(each)
  }
  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Business Name",
        field: "businessname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Service Type",
        field: "sertype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Charge to Customer",
        field: "charge",
        sort: "asc",
        width: 100,
      },
      {
        label: "Rating",
        field: "rating",
        sort: "asc",
        width: 100,
      },
      {
        label: "Active Type",
        field: "active",
        sort: "asc",
        width: 150,
      },
    ],
    rows: userData.map((each, index) => {
      return {
        sno: index + 1,
        businessname: `${each?.Bus_Name}` || "",
        sertype: each?.ServiceType || "",
        charge: each?.chargecustomer || "",
        rating: each?.rating || "",
        active: (
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleFormSubmit(each)}
          >
            Select
          </button>
        ),
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GetSofBusinessTable
