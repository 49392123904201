import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { Suspense, lazy, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"
import "../../../../src//styles/errorfield.scss"


import {
  Spinner,
  Container,
  Form,
  NavItem,
  NavLink,
  Button,
  TabContent,
  TabPane,
} from "reactstrap"
import { loginUserData, userId } from "helpers/userId"
import { randomId } from "helpers/randomGen"
import { FileUpload } from "helpers/file_uploads/upload_handler"
import { change } from "redux-form" // changes


const ListOfPrice = lazy(() =>
  import("components/Customers/BusinessPartner/ListOfPrice")
)

const ListOfStep = lazy(() =>
  import("components/Customers/BusinessPartner/ListOfStep")
)

const ListofDocuments = lazy(() =>
  import("components/Customers/BusinessPartner/ListofDocuments")
)

const initialFormDetails = {
               
  scode: "",
  sname: "",
  ServiceType: "",       
  descrip: "",
  amount: "",
  sofamount: "",
  subtype: "",
  Features: "",
  PackageDetails: "",
  chargecustomer: "",
  Currency: "",


  ListOfPrice: [],
  ListOfStep: [],
  ListofDocuments: [],
  
 

 // BusKey: `LIF${randomId(6)}`,
        
}

const EditBusinessService = () => {
  const [activeTab, setactiveTab] = useState(
    (localStorage.getItem("tab") &&
      parseInt(JSON.parse(localStorage.getItem("tab")))) ||
      1
  )
  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [typeofservicelist, setTypeofservicelist] = useState([]);

  const relationsList = JSON.parse(localStorage.getItem("relationsList"))

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    if (!form.user_fname.trim()) {
      newErrors.user_fname = "First name is required"
      isValid = false
    }

    if (!form.user_lname.trim()) {
      newErrors.user_lname = "Last name is required"
      isValid = false
    }

    setErrors(newErrors)
    
    return isValid
  }


 // -- Fetch Type of Service list start -- //
 async function fetchTypeofService() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/typeofservice_list`
    )
    const data = await response.json()
    return data?.data
  } catch (error) {
    console.log(error)
  }
}

useEffect(() => {
  async function fetchData(){
    const data = await fetchTypeofService();
    setTypeofservicelist(data);
  }
  fetchData();
}, [])


  const [errors, setErrors] = useState({})
  const [changed, setChanged] = useState(undefined) // changed

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const vslid = searchParams.get("vslid")
  const type = searchParams.get("type")
 
  async function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        localStorage.setItem("tab", tab)

       
      }
    }
  }
  
  async function fetchPriceListData() {
    try {
      const url = `${
        process.env.REACT_APP_DOMAIN_URL
      }api/v1/customer/services/service_price_list?vsl_id=${vslid}`

      const response = await fetch(url)

      const data = await response.json()

      const decryptedData = data?.data?.map((each, idx) => {
        return {
          ...each,
          number: idx + 1,
          vpl_Price: each.vpl_Price || "",
          vpl_Currency: each.vpl_Currency || "",
          Descrip: each.Descrip || "",          
        }
      })

      setTimeout(() => {
        setForm(prev => {
          return {
            ...prev,
            ListOfPrice: decryptedData || [],
          }
        })
      }, [500])
    } catch (err) {}
  }

  const handleRemovePricesList = async (vpl_id, idx) => {
    try {
      if (vpl_id) {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_service_price`
        const data = {
          vpl_id: vpl_id,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})
        const removeData = await response.json()

        if (removeData?.success === true) {
          showMessage(removeData.message, "success")
          setForm(prev => {
            return {
              ...prev,
              ListOfPrice: form.ListOfPrice.filter(
                each => each.vpl_id !== vpl_id
              ),
            }
          })
        } else {
          showMessage(removeData.message, "error", false)
        }
      } else if (idx >= 0) {
        setForm({
          ...form,
          ListOfPrice: form.ListOfPrice.filter(
            (each, index) => index != idx
          ),
        })
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  let timeoutId;
  useEffect(() => {
    if (activeTab === 2) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fetchPriceListData();
      }, 300); // Delay to debounce API calls
    }
    return () => clearTimeout(timeoutId); // Clean up on unmount
  }, []);

  async function fetchStepListData() {
    try {
      const url = `${
        process.env.REACT_APP_DOMAIN_URL
      }api/v1/customer/services/service_steps_list?vsl_id=${vslid}`

      const response = await fetch(url)

      const data = await response.json()

      const decryptedData = data?.data?.map((each, idx) => {
        return {
          ...each,
          number: idx + 1,
          step_name: each.step_name || "",
          ss_sdesc: each.ss_sdesc || "",  
        }
      })

      setTimeout(() => {
        setForm(prev => {
          return {
            ...prev,
            ListOfStep: decryptedData || [],
          }
        })
      }, [500])
    } catch (err) {}
  }

  const handleRemoveStepList = async (ss_id, idx) => {
    try {
      if (ss_id) {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_service_step`
        const data = {
          ss_id: ss_id,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})
        const removeData = await response.json()

        if (removeData?.success === true) {
          showMessage(removeData.message, "success")
          setForm(prev => {
            return {
              ...prev,
              ListOfStep: form.ListOfStep.filter(
                each => each.ss_id !== ss_id
              ),
            }
          })
        } else {
          showMessage(removeData.message, "error", false)
        }
      } else if (idx >= 0) {
        setForm({
          ...form,
          ListOfStep: form.ListOfStep.filter(
            (each, index) => index != idx
          ),
        })
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  useEffect(() => {
    if (activeTab === 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fetchStepListData();
      }, 300); // Delay to debounce API calls
    }
    return () => clearTimeout(timeoutId); // Clean up on unmount
  }, []);

  async function fetchListOfDocuments() {
    try {
      const url = `${
        process.env.REACT_APP_DOMAIN_URL
      }api/v1/customer/services/service_document_list?vsl_id=${vslid}`

      const response = await fetch(url)

      const data = await response.json()

      const decryptedData = data?.data?.map(each => {
        return {
          ...each,
          vsil_docname: each.vsil_docname || "",
          vsil_doclink: each.vsil_doclink || "",          
        }
      })
     
      setTimeout(() => {
        setForm(prev => {
          return {
            ...prev,
            ListofDocuments: decryptedData || [],
          }
        })
      }, [500])
    } catch (err) {}
  }

  const handleRemoveDocuments = async (vsil_id, idx) => {
    try {
      if (vsil_id) {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_service_document`
        const data = {
          vsil_id: vsil_id,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})
        const removeData = await response.json()

        if (removeData?.success === true) {
          showMessage(removeData.message, "success")
          setForm({
            ...form,
            ListofDocuments: form.ListofDocuments.filter(
              each => each.vsil_id !== vsil_id
            ),
          })
        } else {
          showMessage(removeData.message, "error", false)
        }
      } else if (idx >= 0) {
        setForm({
          ...form,
          ListofDocuments: form.ListofDocuments.filter(
            (each, index) => index != idx
          ),
        })
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  useEffect(() => {
    if (activeTab === 4) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fetchListOfDocuments();
      }, 300); // Delay to debounce API calls
    }
    return () => clearTimeout(timeoutId); // Clean up on unmount
  }, []);
 
  useEffect(() => {
    if (activeTab) {
      async function setFormData(data) {
        if (data.success) {
          const decryptedData = data?.data[0]
          if (decryptedData) {
            
            const assetInformation = {
              ...decryptedData,
              scode: decryptedData.scode,
              sname: decryptedData.sname,
              ServiceType: decryptedData.ServiceType,      
              descrip: decryptedData.descrip,
              amount: decryptedData.amount,
              sofamount: decryptedData.sofamount,
              subtype: decryptedData.subtype,
              Features: decryptedData.Features,
              PackageDetails: decryptedData.PackageDetails,
              chargecustomer: decryptedData.chargecustomer,
              Currency: decryptedData.Currency,
                           
            }
            setForm({
              ...form,
              ...assetInformation,              
            })
          }
        }

        return true
      }   

      if (vslid) {
        try {
          const fetchDataEditData = async () => {
            const response = await fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_single_business_service_list?vsl_id=${vslid}`
            )

            const data = await response.json()
            setFormData(data)
          }

          fetchDataEditData()
        } catch (e) {}
      }
    }
  }, [activeTab])

  const handleForm = async (e, action, key) => {
    
    let { name, value, type, checked } = e?.target || {}

    switch (action) {
      case "vendorService":
          
              setForm({
                ...form,
                [name]: value,
              })
          

        break
      
      
      case "addListOfPrice":
        let latestNumbers = 2
        if (form.ListOfPrice.length) {
          let numberList = form.ListOfPrice.map(each => each.number)

          latestNumbers = Math.max(...numberList) + 1
        }
        form.ListOfPrice.push({
          number: form.ListOfPrice.length ? latestNumbers : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, ListOfPrice: form.ListOfPrice })
        break
      case "removeListOfPrice":
          const modifiedListsprice = form.ListOfPrice.find(
            (each, idx) => idx === key
          )
          handleRemovePricesList(modifiedListsprice?.vpl_id || 0, key)
          break
      case "priceList":
        setForm({
          ...form,
          ListOfPrice: form.ListOfPrice.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break
         


      case "addListofDocuments":
        let latestNumberss = 2
        if (form.ListofDocuments.length) {
          let numberList = form.ListofDocuments.map(each => each.number)

          latestNumberss = Math.max(...numberList) + 1
        }
        form.ListofDocuments.push({
          number: form.ListofDocuments.length ? latestNumberss : 1,
        })
        setForm({ ...form, ListofDocuments: form.ListofDocuments })
        break
      case "removeListofDocuments":
          const modifiedListDocument = form.ListofDocuments.find(
            (each, idx) => idx === key
          )
          handleRemoveDocuments(modifiedListDocument?.vsil_id || 0, key)
          break
      case "documentList":
        setForm({
          ...form,
          ListofDocuments: form.ListofDocuments.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break
          

      case "addListOfStep":
          let latestNumbersss = 2
          if (form.ListOfStep.length) {
          let numberList = form.ListOfStep.map(each => each.number)
  
          latestNumbersss = Math.max(...numberList) + 1
          }
          form.ListOfStep.push({
          number: form.ListOfStep.length ? latestNumbersss : 1,
          })
          setForm({ ...form, ListOfStep: form.ListOfStep })
          break
      case "removeListOfStep":
        const modifiedListsstep = form.ListOfStep.find(
          (each, idx) => idx === key
        )
        handleRemoveStepList(modifiedListsstep?.ss_id || 0, key)
        break            
      case "stepList":
        setForm({
          ...form,
          ListOfStep: form.ListOfStep.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break
      
      default:
        null
    }
  }

    // for image upload

  const handleImage = async (e, action, key) => {
  const { name, files } = e?.target || {}
  
  
  switch (action) {

    case "documentList":
        if (true) {
          const result = await FileUpload(files)

          const urlsList = result.files.map(each => each.url)

          setForm({
            ...form,
            ListofDocuments: form.ListofDocuments.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: urlsList.join(","),
                }
              }

              return each
            }),
          })
        }
        break

  }
  

 // setForm({ ...form, [name]: result.files?.[0]?.url })
}




  
  const handleFormSubmit = async () => {
    switch (activeTab) {
      case 1:
        if (form.scode) {
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_service_list`;
            const data = {              
              vsl_id: vslid,                
              scode: form.scode,
              sname: form.sname,
              ServiceType: form.ServiceType,      
              descrip: form.descrip,
              amount: form.amount,
              sofamount: form.sofamount,
              subtype: form.subtype,
              Features: form.Features,
              PackageDetails: form.PackageDetails,
              chargecustomer: form.chargecustomer,
              Currency: form.Currency,
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }

            setApiStatus({ ...apiStatus, inProgress: true })

            const response = await fetch(url, options, {})

            const addOrUpdateBusinessInformation = await response.json()

            if (addOrUpdateBusinessInformation?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(2)
              localStorage.setItem("tab", 2)
              await fetchPriceListData()             
              showMessage(addOrUpdateBusinessInformation.message, "success")
            } else {
              showMessage(addOrUpdateBusinessInformation?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
        }
        else {
          setErrors(prev => {
            return {
              ...prev,
              Bus_Name: "Please enter Business Name"
            }
          })
        }
        break
      case 2:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_price`

          const data = {
            vsl_id: vslid,            
            ListOfPrice: form.ListOfPrice.map(
              eachPrice => {
                return {
                  vpl_id: eachPrice.vpl_id || 0,
                  vpl_Price: eachPrice.vpl_Price || "",
                  vpl_Currency: eachPrice.vpl_Currency || "",
                  Descrip: eachPrice.Descrip || "",
                }
              }
            ),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateListOfprice = await response.json()

          if (addOrUpdateListOfprice?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(3)
            localStorage.setItem("tab", 3)
            await fetchStepListData()
            showMessage(addOrUpdateListOfprice.message, "success")
            // setTimeout(() => {
            //   history("/asset-wallet")
            // }, 3000)
            setactiveTab(activeTab + 1)
            localStorage.setItem("tab", activeTab + 1)
          } else {
            showMessage(addOrUpdateListOfprice?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break        
      case 3:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_steps`

          const data = {
            vsl_id: vslid,
            ListOfStep: form.ListOfStep.map(
              eachStep => {
                return {
                  ss_id: eachStep.ss_id || 0,                  
                  step_name: eachStep.step_name || "",  
                  ss_sdesc: eachStep.ss_sdesc || "",                
                }
              }
            ),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateListOfSteps = await response.json()

          if (addOrUpdateListOfSteps?.success === true) {
            setactiveTab(4)
              localStorage.setItem("tab", 4)
              await fetchListOfDocuments()
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage(addOrUpdateListOfSteps.message, "success")
            // setTimeout(() => {
            //   history("/asset-wallet")
            // }, 3000)
            setactiveTab(activeTab + 1)
            localStorage.setItem("tab", activeTab + 1)
          } else {
            showMessage(addOrUpdateListOfSteps?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 4:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_service_documents`

          const data = {
            vsl_id: vslid,
            ListofDocuments: form.ListofDocuments.map(
              eachDocuments => {
                return {
                  vsil_id: eachDocuments.vsil_id || 0,
                  vsil_docname: eachDocuments.vsil_docname || "",
                  vsil_doclink: eachDocuments.vsil_doclink || "",                  
                }
              }
            ),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateListOfDocuments = await response.json()

          if (addOrUpdateListOfDocuments?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage(addOrUpdateListOfDocuments.message, "success")
            // setTimeout(() => {
            //   history("/asset-wallet")
            // }, 3000)
            setactiveTab(activeTab + 1)
            localStorage.setItem("tab", activeTab + 1)
          } else {
            showMessage(addOrUpdateListOfDocuments?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break        
    }

    if (!(vslid) && activeTab != 1) {
      setactiveTab(1)
      localStorage.setItem("tab", 1)
    }
  }

  const handleBlur = e => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value?.trim(),
      errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
    })
  }
  useEffect(() => {
    if (changed) {
      setErrors(prev => {
        const copy = JSON.parse(JSON.stringify(prev))
        delete copy[changed]
        return copy
      })
    }
  }, [changed])  // changed

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">BUSINESS PARTNER</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Operations</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/asset-wallet">Business Partner</Link>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                       
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                              localStorage.setItem("tab", 1)
                            }}
                          >
                            
                            Vendor Service Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}                         
                            onClick={async () => {
                              setactiveTab(2)
                              localStorage.setItem("tab", 2)
                              await fetchPriceListData()
                            }}
                          >
                            
                            Price List
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={async () => {
                              setactiveTab(3)
                              localStorage.setItem("tab", 3)
                              await fetchStepListData()
                            }}
                          >
                           
                            Steps List
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={async () => {
                              setactiveTab(4)
                              localStorage.setItem("tab", 4)
                              await fetchListOfDocuments()
                            }}
                          >
                            
                            Documents List
                          </NavLink>
                        </NavItem>  
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        {/**4,5,6 dynamic forms and tables */}
                       
                        <TabPane tabId={1}>                          
                            <Form className="mb-4">   
                              <div className="row">
                                  <div className="col-md-3">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="scode"
                                          className="form-label"
                                      >
                                          Service Code{" "}
                                          <span className="text-danger">*</span>
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="scode"
                                          id="scode"
                                          placeholder="Service Code"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.scode}
                                      />
                                      

                                      </div>
                                  </div>
                                  <div className="col-md-3">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="sname"
                                          className="form-label"
                                      >
                                          Service Name
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="sname"
                                          id="sname"
                                          placeholder="Service Name"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.sname}
                                      />

                                      </div>
                                  </div>
                                  <div className="col-md-3">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="ServiceType"
                                          className="form-label"
                                      >
                                          Service Type
                                      </label>
                                      {/* <select
                                          className="form-select"
                                          id="ServiceType"
                                          name="ServiceType"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.ServiceType}
                                      > 
                                      <option value="">Select Service Type</option>
                                      <option value="Will">Will</option>
                                      <option value="Trust">Trust</option>
                                      <option value="Will and Trust">Will and Trust</option>

                                      </select> */}
                                      <select
                                                className="form-select"
                                                id="ServiceType"
                                                name="ServiceType"
                                                required
                                                onChange={e => handleForm(e, "vendorService")}
                                                value={form.ServiceType}
                                            >
                                                <option value="" selected="">
                                                Select Type of Service
                                                </option>
                                                
                                                {typeofservicelist.map(each => (
                                                <option key={each.type} value={each.type}>
                                                {each.type}
                                                </option> 
                                            ))}
                                            </select>
                                      </div>
                                  </div>
                                  <div className="col-md-3">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="subtype"
                                          className="form-label"
                                      >
                                          Sub Type
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="subtype"
                                          id="subtype"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.subtype}
                                      />
                                      </div>
                                  </div>  
                                  <div className="col-md-6">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="descrip"
                                          className="form-label"
                                      >
                                          Service Description{" "}
                                          
                                      </label>
                                      <textarea
                                          id="descrip"
                                          name="descrip"
                                          rows="3"
                                          className="form-control"
                                          onChange={e => handleForm(e, "vendorService")}  
                                          value={form.descrip}
                                      ></textarea>
                                      
                                      </div>
                                  </div>
                                  <div className="col-md-3">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="amount"
                                          className="form-label"
                                      >
                                          Total Price to Customer 
                                      </label>
                                      <input
                                          type="number"
                                          className="form-control"
                                          name="amount"
                                          id="amount"
                                          placeholder="Total Price to Customer "
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.amount}
                                      />
                                      </div>
                                  </div>
                                  <div className="col-md-3">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="Currency"
                                          className="form-label"
                                      >
                                          Currency
                                          <span className="text-danger">*</span>
                                      </label>
                                      <select
                                          className="form-select"
                                          id="Currency"
                                          name="Currency"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.Currency}
                                      > 
                                      <option value="" selected="">Select Currency</option>
                                      <option value="INR">INR</option>
                                      <option value="USD">USD</option>                                                            
                                      <option value="EURO">EURO</option> 
                                      </select>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="sofamount"
                                          className="form-label"
                                      >
                                          Total Price to Novus Life 
                                      </label>
                                      <input
                                          type="number"
                                          className="form-control"
                                          name="sofamount"
                                          id="sofamount"
                                          placeholder="Total Price to Novus Life "
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.sofamount}
                                      />
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="chargecustomer"
                                          className="form-label"
                                      >
                                          Charge to Customer
                                      </label>
                                      <input
                                          type="number"
                                          className="form-control"
                                          name="chargecustomer"
                                          id="chargecustomer"
                                          placeholder="Charge to Customer"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.chargecustomer}
                                      />
                                      </div>
                                  </div>  
                                  <div className="col-md-6">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="Features"
                                          className="form-label"
                                      >
                                          Features
                                          
                                      </label>
                                      <textarea
                                          id="Features"
                                          name="Features"
                                          rows="3"
                                          className="form-control"
                                          placeholder="Financier Address"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.Features}
                                      ></textarea>
                                          
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="PackageDetails"
                                          className="form-label"
                                      >
                                          Package Details
                                          
                                      </label>
                                      <textarea
                                          id="PackageDetails"
                                          name="PackageDetails"
                                          rows="3"
                                          className="form-control"
                                          placeholder="Financier Address"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.PackageDetails}
                                      ></textarea>
                                      </div>
                                  </div>
                              </div>
                            </Form>                         
                        </TabPane>
                        <TabPane tabId={2}>
                        <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">
                              List Of Price
                            </h4>

                            <ListOfPrice
                              form={form}
                              handleForm={handleForm}                              
                              vsl_id={vslid}
                              activeTab={activeTab}
                            />
                          </Suspense>
                        </TabPane>  
                        <TabPane tabId={3}>
                          <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">
                              List Of Steps
                            </h4>

                            <ListOfStep
                              form={form}
                              handleForm={handleForm}                              
                              vsl_id={vslid}
                              activeTab={activeTab}
                            />
                          </Suspense>
                        </TabPane>  
                        <TabPane tabId={4}>
                          <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">
                              List of Documents
                            </h4>

                            <ListofDocuments
                              form={form}
                              handleForm={handleForm}
                              handleImage={handleImage}
                              vsl_id={vslid}
                            />
                          </Suspense>
                        </TabPane>  
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Button
                            type="button"
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                            onClick={() => {
                              activeTab != handleFormSubmit()
                              toggleTab(activeTab - 1)
                            }}
                            disabled={activeTab === 1}
                          >
                            Previous
                          </Button>
                        </li>
                        <li
                          className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Button
                            disabled={activeTab === 4}
                            type="button"
                            onClick={() => {
                              handleFormSubmit()
                              toggleTab(activeTab + 1)
                            }}
                          >
                            Next
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {vslid || vslid ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        history(-1)
                      }}
                    >
                      Cancel
                    </Button>                 
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditBusinessService
