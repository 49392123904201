import React from "react"
import { Row, Col, Card, CardBody, Container } from "reactstrap"

import { Link } from "react-router-dom"
import logoSm from "../../assets/images/soflogo-min.png"

const RegisterSuccess = () => {
  document.title = "Login | SOF"
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Thank you for signing up
                    </h5>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="60" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  <div className=" mt-5 text-center font-size-17 ">
                    We have sent a verification link to the registered email id.
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Verified Email ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Sign In here{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} SOF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterSuccess
