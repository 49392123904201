export const updateNotificationView = async id => {
  try {
    let url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/notifications/update_notification?notify_id=${id}&type=1`
    const response = await fetch(url)
    const data = await response.json()

    await fetchNotificationsCount()

    return data?.success
  } catch (e) {
    return false
  }
}
