import * as React from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useState } from "react"
import { SyncLoader } from "react-spinners"
import { v4 as uuid } from 'uuid'
import SuccessPayment from "../../../assets/images/purchase-success.png"
import FailedPayment from "../../../assets/images/purchase-fail.png"
import { toast } from 'react-toastify'

const PaymentFailed = ({ status }) => {
	const [loading, setLoading] = useState(false)

	const { uid, price, request_id, currency_code, txn_id, checkout_session_id } = useParams()

    const navigate = useNavigate()

	const updatesuccess = async () => {
		setLoading(true)

		const { data } = await axios.post(`${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/Payments/save`, {
            uid, item_price_currency: currency_code, request_id, item_price: price,
            paid_amount: status === "success" ? price : 0, paid_amount_currency: currency_code, txn_id, checkout_session_id, payment_status: status
        })

        if (data.success) {
			setLoading(false)
        }
		else {
			toast.warn("Something went wrong")
			navigate("/dashboard")
		}
	}

	useEffect(() => {
		updatesuccess()
	}, [status])

	return (
		<>
			<div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white" }} className="app-page-thankyou">
				<div className="app-page-thankyou-wrapper">
					{loading ? (
						<div>
							<SyncLoader color="#ff9774" />
						</div>
					) : (
						<div className="app-page-thankyou-card">
							<img
								style={{ width: "400px" }}
								className="app-page-thankyou-card-image"
								src={status === "success" ? SuccessPayment : FailedPayment}
							/>
							{status === "success" ? <h1>Thankyou</h1> : <h1>Sorry..</h1>}
							{status === "success" ? <p>Your payment is successful</p> : <p>We couldn't complete your payment</p>}
							<div><strong>Transaction:</strong> {txn_id}</div>
							<div><strong>Session:</strong> {checkout_session_id}</div>
							<div className="app-page-thankyou-card-btn">
								<Link to={`/edit_request_service?Reqid=${request_id}&type=0`}>
									<button
										style={{ marginTop: "30px", border: "none", borderRadius: "4px", backgroundColor: "#A5B68D", color: "white", padding: "10px 18px" }}
									>
										{' '}
										Go to request services{' '}
									</button>
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
export default PaymentFailed
