import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const InvoiceListTable = () => {
  const [invoiceInformation, setInvoiceInformation] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/invoice_list?uid=${user_id}`
      )
      const data = await response.json()
      setInvoiceInformation({ ...invoiceInformation, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_invoice_list`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        uid: userData?.user?.user_id,
        id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setInvoiceInformation({
          ...invoiceInformation,
          data: invoiceInformation?.data?.filter(each => each.id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Number",
        field: "item_number",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "item_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Price",
        field: "item_price",
        sort: "asc",
        width: 200,
      },
      {
        label: "Currency",
        field: "item_price_currency",
        sort: "asc",
        width: 100,
      },
      {
        label: "Transanction Id",
        field: "txn_id",
        sort: "asc",
        width: 150,
      },      
      {
        label: "Status",
        field: "payment_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment Date",
        field: "created",
        sort: "asc",
        width: 150,
      },
    ],
    rows: invoiceInformation?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,    
        item_number: each?.item_number || "",
        item_name: each?.item_name || "",
        item_price: each?.item_price || "",
        item_price_currency: each?.item_price_currency || "",
        txn_id: each?.txn_id || "",        
        payment_status: each?.payment_status || "",
        created: each?.created || "",       
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
      <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default InvoiceListTable
