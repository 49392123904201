import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"

const AssetWalletTable = () => {
  const [assetwallet, setAssetWallet] = useState({ data: [] })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_asset_info?user_id=${userId}`
        )

        if (!response.ok) {
          throw new Error("Failed to fetch asset wallet data")
        }

        const data = await response.json()
        setAssetWallet({ data: data?.data || [] })
      } catch (error) {
        console.error("Error fetching asset wallet data:", error)
        showMessage(error.message || "Failed to load data", "error", false)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_asset_wallet`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        pro_uid: userData?.user?.user_id,
        pro_id: id,
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options)

      if (!response.ok) {
        throw new Error("Failed to delete the asset")
      }

      const removeData = await response.json()

      if (removeData?.success) {
        setAssetWallet(prevState => ({
          ...prevState,
          data: prevState.data.filter(each => each.pro_id !== id),
        }))

        showMessage(removeData.message, "success")
      } else {
        throw new Error(removeData.message || "Failed to delete the asset")
      }
    } catch (error) {
      console.error("Error removing asset:", error)
      showMessage(error.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      { label: "S. No.", field: "sno", sort: "asc" },
      { label: "Edit", field: "edit", sort: "asc" },
      { label: "Name of Asset", field: "name_of_the_asset", sort: "asc" },
      { label: "Date of Purchase", field: "date_of_purchase", sort: "asc" },
      { label: "Type of Property", field: "type_of_property", sort: "asc" },
      {
        label: "Original Asset Value",
        field: "original_asset_value",
        sort: "asc",
      },
      { label: "Num of Owners", field: "num_of_owners", sort: "asc" },
      { label: "Country of Asset", field: "country_ofasset", sort: "asc" },
      { label: "Number of Risks", field: "number_of_risks", sort: "asc" },
      { label: "Action", field: "action", sort: "asc" },
    ],
    rows: assetwallet?.data?.map((each, index) => ({
      sno: index + 1,
      edit: (
        <Link
          to={`/add-asset-wallet?id=${each.pro_id}&type=0`}
          className="btn btn-outline-primary btn-sm edit"
          title="Edit"
        >
          <i className="fas fa-pencil-alt"></i>
        </Link>
      ),
      name_of_the_asset: each?.pro_asset_name,
      date_of_purchase: each?.pro_purchase_date,
      type_of_property: each?.pro_property_type || "",
      original_asset_value: each?.pro_asset_value || "",
      num_of_owners: each?.pro_number_of_owners || "",
      country_ofasset: each?.pro_county_asset || "",
      number_of_risks: each?.number_of_risks || "",
      action: (
        <Col xl={3} lg={4} sm={6}>
          <i
            className="ion ion-md-trash"
            title="Delete"
            type="button"
            onClick={() =>
              each.pro_id &&
              window.confirm(
                `Are you sure you want to delete ${each.pro_asset_name}?`
              ) &&
              handleRemoveRole(each.pro_id)
            }
          ></i>
        </Col>
      ),
    })),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Asset Wallet List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add-asset-wallet"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Property
                  </Link>
                </div>
              </div>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={data}
                  noBottomColumns={true}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AssetWalletTable

export const ListOfExistingOwners = ({ proId, activeTab }) => {
  const [listOfOwners, setListOfOwners] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/owners_list?pro_id=${proId}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()
        setListOfOwners({ ...listOfOwners, data: data?.data || [] })
      } catch (err) {}
    }

    fetchData()
  }, [proId, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_asset_owner`
      const data = {
        pol_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfOwners({
          ...listOfOwners,
          data: listOfOwners?.data?.filter(each => each.pol_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "% of Ownership",
        field: "ownership",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: listOfOwners?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit-owner?id=${each.pol_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pol_fname} ${each?.pol_lname}`,
        relationship: each?.pol_relitionship,
        ownership: each?.pol_persant || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pol_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pol_fname} ${each?.pol_lname}?`
                  ) &&
                  handleRemoveRole(each.pol_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ListofExistingBeneficiaries = ({ proId, activeTab }) => {
  const [ListofBeneficiaries, setListofBeneficiaries] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/beneficiaries_list?pro_id=${proId}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()
        setListofBeneficiaries({
          ...ListofBeneficiaries,
          data: data?.data || [],
        })
      } catch (err) {}
    }

    fetchData()
  }, [proId, activeTab])

  const handleRemoveRole = async pbl_id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_beneficiaries`
      const data = {
        pbl_id: pbl_id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "% of Ownership",
        field: "ownership",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofBeneficiaries?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/?id=${each.pbl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pbl_name}`,
        relationship: each?.pbl_relitionship,
        ownership: each?.pbl_persant || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pbl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pbl_name}?`
                  ) &&
                  handleRemoveRole(each.pbl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ListofExistingDocuments = ({ proId, activeTab }) => {
  const [ListofDocuments, setListofDocuments] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/document_list?pro_id=${proId}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()
        setListofDocuments({ ...ListofDocuments, data: data?.data || [] })
      } catch (err) {}
    }

    fetchData()
  }, [proId, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document`
      const data = {
        pdl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListofDocuments({
          ...ListofDocuments,
          data: ListofDocuments?.data?.filter(each => each.pdl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name of Document",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Type of Document",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "Images",
        field: "Image",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofDocuments?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add-asset-wallet?id=${each.pdl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pdl_document_name}`,
        relationship: each?.pdl_tyep_document,
        Image: each?.pdl_image || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pdl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pdl_document_name}?`
                  ) &&
                  handleRemoveRole(each.pdl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
