import FamilyRelationsTable from "components/DataTables/FamilyRelationsTable"
import React from "react"
import { Container } from "reactstrap"
import { Link } from "react-router-dom"

const FamilyRelations = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">FAMILY RELATIONS</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Family Relations</a>
              </li>
            </ol>
          </div> 
          <div className="card">
                <div className="col-12 d-flex">
                    <div>
                        <Link
                        to="/family-tree"
                        className="btn btn-danger my-2"
                        type="button"
                        >
                        Back to Family Tree
                        </Link>
                    </div>               
                </div>          
            <FamilyRelationsTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FamilyRelations
