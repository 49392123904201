
import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"


const AssetQuestionTable = () => {

  const [AssetQuestion, setAssetQuestion] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_asset_question_list`
      )
      const data = await response.json()
      setAssetQuestion({ ...AssetQuestion, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async pq_qid => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_asset_question`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        pq_qid: pq_qid,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeAQData = await response.json()

      if (removeAQData?.success === true) {
        setAssetQuestion({
          ...AssetQuestion,
          data: AssetQuestion?.data?.filter(each => each.pq_qid !== pq_qid),
        })

        showMessage(removeAQData.message, "success")
      } else {
        showMessage(removeAQData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeAQData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 270,
      },
      {
        label: "Question",
        field: "pq_name",
        sort: "asc",
        width: 200,
      },  
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: AssetQuestion?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add_assetquestion?id=${each.pq_qid}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
          pq_name: each?.pq_name || "",                  
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pq_qid &&
                  window.confirm(
                    `Pls confirm deletion of ${each.pq_name}?`
                  ) &&
                  handleRemoveRole(each.pq_qid)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),  
  }

  return (
    <React.Fragment>
      <Row>
      <Col className="col-12">
          <div className="page-title-box">
            <h4 className="font-size-18">ASSET QUESTION LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Asset Question List</a>
              </li>
            </ol>
          </div>
        
          <Card>
            <CardBody>
              
             
              <div className="col-12">
                <Link
                  to="/add_assetquestion"
                  className="btn btn-danger my-2"
                  type="button"
                >
                  Add Asset Question
                </Link>
              </div>

              <MDBDataTable responsive bordered data={data} noBottomColumns={true} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AssetQuestionTable
