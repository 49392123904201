import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import BpServiceTable from "components/DataTables/BpServiceTable"
 
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { randomId } from "helpers/randomGen"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
   const ListOfService = lazy(() =>
    import("components/Customers/BP/ListOfService")
  )
  
  
  const initialFormDetails = {
    uid: "",
    ListOfService: [],    
  }
  
  const BusinessPartnersServiceInfo = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const relationsList = JSON.parse(localStorage.getItem("relationsList"))
  
      
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
      
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const decryptedData = data?.data[0]
            if (decryptedData) {
             
              setForm({
                ...form,
                ...assetInformation,
                RegionList: await fetchRegionsList(
                  assetInformation.Country,
                  1
                ),
                
              })
            }
          }
  
          return true
        }  
        
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_bp_info?user_id=${userId}`
              )
  
              const data = await response.json()
              setFormData(data)
            }
  
            fetchDataEditData()
          } catch (e) {}
        
      }
    }, [activeTab])

    const getBusinessKey = (businessType) => {
      const randoomKey = randomId(2);
      if (businessType == 'Attorney') {
        return `ATTCT${randoomKey}`;
      }
      if (businessType == 'Financial Advisor') {
        return `FADCT${randoomKey}`;
      }
      if (businessType == 'Insurance Agent') {
        return `IAGCT${randoomKey}`;
      }
      if (businessType == 'Laywer') {
        return `LAWCT${randoomKey}`;
      }
      return `RLTCT${randoomKey}`;
    }
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "addListOfService":
          let latestNumber1 = 2
          if (form.ListOfService.length) {
            let numberList = form.ListOfService.map(each => each.number)
  
            latestNumber1 = Math.max(...numberList) + 1
          }
          form.ListOfService.push({
            number: form.ListOfService.length ? latestNumber1 : 1,
            relationsList: relationsList,
          })
          setForm({ ...form, ListOfService: form.ListOfService })
          break
        case "vendorService":
          const modfiedListOfService = []
  
          for (let i = 0; i < form.ListOfService.length; i++) {
            const owner = {
              ...form.ListOfService[i],
            }
  
            if (i === key) {
              let regionsList = owner.regionsList || []
              if (name === "pol_country") {
                regionsList = await fetchRegionsList(value)
                owner.pol_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfService.push({
                ...owner,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfService.push(owner)
            }
          }
  
          setForm({
            ...form,
            ListOfService: modfiedListOfService,
          })
          break
        case "removeListOfService":
          const modifiedList1 = form.ListOfService.filter(
            (each, idx) => idx !== key
          )
          setForm({ ...form, ListOfService: modifiedList1 })
          break    
  
       
        default:
          null
      }
    }
  
      // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, bp_logo: result.files?.[0]?.url })
  }
  
    useEffect(() => {
      if (changed) {
        setErrors(prev => {
          const copy = JSON.parse(JSON.stringify(prev))
          delete copy[changed]
          return copy
        })
      }
    }, [changed])  // changed
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">SERVICE INFORMATION LIST</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">My Account</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/">Service Information List</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          
                          <TabPane tabId={1}>                          
                            <Suspense
                                fallback={
                                  <div className="text-center m-4">Loading...</div>
                                }
                              >
                                    
                                <BpServiceTable
                                  form={form}
                                  handleForm={handleForm}
                                  handleImage={handleImage}
                                  user_id={userId}                                  
                                />
                            </Suspense>                            
                          </TabPane>                         
                        </TabContent>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
          
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default BusinessPartnersServiceInfo
  