import GeneratedWillDocumentListTable from "components/DataTables/GeneratedWillDocumentListTable"
import React, { useEffect } from "react"
import { Container } from "reactstrap"

const GenerateWillTableList = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("assetId")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
          <h4 className="font-size-18">Generate Will</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                <a href="/">Services</a>
                </li>

                <li className="breadcrumb-item">
                <a href="/">Generate Will List</a> 
                </li>
              </ol>
          </div>
          <div className="card">
            <GeneratedWillDocumentListTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GenerateWillTableList
