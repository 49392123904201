import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"


const LifeInsuranceTable = () => {
  const [lifeInsurance, setLifeInsurance] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_life_insurance?user_id=${user_id}`
      )
      const data = await response.json()
      setLifeInsurance({ ...lifeInsurance, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_life_insurance`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        pl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setLifeInsurance({
          ...lifeInsurance,
          data: lifeInsurance?.data?.filter(each => each.pl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Policy Number",
        field: "policy_number",
        sort: "asc",
        width: 270,
      },
      {
        label: "Insured Name",
        field: "insured_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Insurance Company Name",
        field: "insurance_company_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Face Amount",
        field: "face_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Billing Amount",
        field: "billing_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Policy Status",
        field: "policy_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: lifeInsurance?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add-life-insurance?id=${each.pl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
          policy_number: each.policy_number || "",
          insured_name: each.insured_name  || "",
          insurance_company_name: each.company_name || "",
          face_amount: each.face_amount || "",
          billing_amount: each.billing_amount || "",
          policy_status: each.policy_status || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.policy_number}?`
                  ) &&
                  handleRemoveRole(each.pl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Policy List</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add-life-insurance"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Policy
                  </Link>
                </div>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default LifeInsuranceTable


export const ListofExistingBeneficiaries = ({ proId, activeTab }) => {
  const [ListofBeneficiaries, setListofBeneficiaries] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/life_beneficiaries_list?pro_id=${proId}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()
        setListofBeneficiaries({ ...ListofBeneficiaries, data: data?.data || [] })
                
      } catch (err) {}
    }

    fetchData()
  }, [proId, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_beneficiaries`
      const data = {
        pbl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListofBeneficiaries({
          ...ListofBeneficiaries,
          data: ListofBeneficiaries?.data?.filter(each => each.pbl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "% of Ownership",
        field: "ownership",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofBeneficiaries?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/?id=${each.pbl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pbl_name}`,
        relationship: each?.pbl_relitionship,
        ownership: each?.pbl_persant || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pbl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pbl_name}?`
                  ) &&
                  handleRemoveRole(each.pbl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}