import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const UniversalDataTable = () => {
  const [universaldata, setUniversalData] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/universal_data_list`
      )
      const data = await response.json()
      setUniversalData({ ...universaldata, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_universla_data`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        mal_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      debugger
      const removeData = await response.json()

      if (removeData?.success === true) {
        setUniversalData({
          ...universaldata,
          data: universaldata?.data?.filter(each => each.mal_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }
  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Message No",
        field: "mal_mid",
        sort: "asc",
        width: 270,
      },
      {
        label: "Popup Text",
        field: "mal_text",
        sort: "asc",
        width: 200,
      },
      {
        label: "Replacements",
        field: "mal_rep",
        sort: "asc",
        width: 100,
      },
      {
        label: "Process",
        field: "mal_process",
        sort: "asc",
        width: 150,
      },
      {
        label: "Application",
        field: "mal_app",
        sort: "asc",
        width: 150,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: universaldata?.data?.map((each, index) => {
      return {
        sno: (
          <div>
            {index + 1}{" "}           
          </div>
        ),
        edit: (
          <div>            
            <Link
              to={`/add_universal_data?id=${each.mal_id}`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </div>
        ),
        mal_mid: each?.mal_mid || "",
        mal_text: each?.mal_text || "",
        mal_rep: each?.mal_rep || "",
        mal_process: each?.mal_process || "",
        mal_app: each?.mal_app || "", 
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.mal_id &&
                  window.confirm(
                    `Pls confirm deletion of ${each.mal_mid}?`
                  ) &&
                  handleRemoveRole(each.mal_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
      <Col className="col-12">
          <div className="page-title-box">
            <h4 className="font-size-18">UNIVERSAL DATA ELEMENTS LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Universal Data Elements List</a>
              </li>
            </ol>
          </div>
       
          <Card>
            <CardBody>
              
              <div className="col-12">
                <Link
                  to="/add_universal_data"
                  className="btn btn-danger my-2"
                  type="button"
                >
                  Add Universal Data Elements
                </Link>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default UniversalDataTable
