import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
 
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { randomId } from "helpers/randomGen"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
  const ListOfOwners = lazy(() =>
    import("components/Customers/BusinessPartner/ListOfOwners")
  )

  const ListOfService = lazy(() =>
    import("components/Customers/BusinessPartner/ListOfService")
  )
  
  
  const initialFormDetails = {
    uid: "",
    Bus_Name: "",
    EIN_Number: "",
    Bus_Website: "",
    Bus_Leg_Struc: "",
    BusType: "",
    BusKey: "",
    EmailID: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Country: "", 
    Region: "", 
    Zip: "",
    bp_logo: "",
        
    RegionList: [],
  }
  
  const BusinessPartnersInfo = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    
    async function fetchRegionsList(countryName, type) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        )
  
        const data = await response.json()
        if (type != 1) {
          setForm({
            ...form,
            RegionList: data?.data || [],
            Region: data.data[0]?.region || "",
            Country: countryName,
          })
        }
  
        return data?.data || []
      } catch (error) {
        console.log(error)
      }
    }  
    
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
  
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const decryptedData = data?.data
            if (decryptedData) {
              
              const assetInformation = {
                ...decryptedData,
                Bus_Name: decryptedData.Bus_Name,
                EIN_Number: decryptedData.EIN_Number,
                Bus_Website: decryptedData.Bus_Website,
                Bus_Leg_Struc: decryptedData.Bus_Leg_Struc,
                BusType: decryptedData.BusType,
                BusKey: decryptedData.BusKey,
                EmailID: decryptedData.EmailID,
                Phone: decryptedData.Phone,
                Street1: decryptedData.Street1,
                Street2: decryptedData.Street2,
                City: decryptedData.City,
                Country: decryptedData.Country,
                Region: decryptedData.Region,
                Zip: decryptedData.Zip,
                bp_logo: decryptedData.bp_logo,
               
              }
              setForm({
                ...form,
                ...assetInformation,
                RegionList: await fetchRegionsList(
                  assetInformation.Country,
                  1
                ),
                
              })
            }
          }
  
          return true
        }  
        
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_bp_info?user_id=${userId}`
              )
  
              const data = await response.json()
              setFormData(data)
            }
  
            fetchDataEditData()
          } catch (e) {}
        
      }
    }, [activeTab])

    const getBusinessKey = (businessType) => {
      const randoomKey = randomId(2);
      if (businessType == 'Attorney') {
        return `ATTCT${randoomKey}`;
      }
      if (businessType == 'Financial Advisor') {
        return `FADCT${randoomKey}`;
      }
      if (businessType == 'Insurance Agent') {
        return `IAGCT${randoomKey}`;
      }
      if (businessType == 'Laywer') {
        return `LAWCT${randoomKey}`;
      }
      return `RLTCT${randoomKey}`;
    }
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "businessInfo":
            if (name === "Country") {
                fetchRegionsList(value)
              } else if( name === "BusType") {
                setForm({
                  ...form,
                  [name]: value,
                  ['BusKey']: getBusinessKey(value)
                })
              } else{

                setForm({
                  ...form,
                  [name]: value,
                })
              }
  
          break      
        
        default:
          null
      }
    }
  
      // for image upload
  const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, bp_logo: result.files?.[0]?.url })
  }  
  
    const handleBlur = e => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value?.trim(),
        errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
      })
    }
  
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">BUSINESS INFORMATION</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">My Account</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/">Business information</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {/**4,5,6 dynamic forms and tables */}
                          <TabPane tabId={1}>
                            <Form>
                              
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="Bus_Name"
                                      className="form-label"
                                    >
                                      Business Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        errors.Bus_Name
                                          ? "invalid-inputfield form-control"
                                          : "Bus_Name form-control"
                                      }
                                      name="Bus_Name"
                                      id="Bus_Name"
                                      placeholder="Business Name"
                                      required
                                      onChange={e => {
                                        setChanged("Bus_Name")
                                        handleForm(e, "businessInfo")
                                      }}
                                      value={form.Bus_Name}
                                      onBlur={handleBlur}
                                      disabled
                                    />
                                    {errors.Bus_Name && (
                                      <div className="invalid-input">
                                        {errors.Bus_Name}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="EIN_Number"
                                      className="form-label"
                                    >
                                      EIN Number<span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        errors.Bus_Name
                                          ? "invalid-inputfield form-control"
                                          : "EIN_Number form-control"
                                      }
                                      name="EIN_Number"
                                      id="EIN_Number"
                                      disabled
                                      placeholder="EIN Number"
                                      value={form.EIN_Number}
                                      onChange={e =>
                                        handleForm(e, "businessInfo")
                                      }                                      
                                    />
                                     {errors.EIN_Number && (
                                      <div className="invalid-input">
                                        {errors.EIN_Number}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                    <img
                                    id="output_image"
                                    className="rounded avatar-lg card-img img-thumbnail border border-2 p-0 d-inline-block"
                                    alt="Your Image"
                                    width="100"
                                    height="80"
                                    src={form.bp_logo || "default-profile.png"}
                                    />
                                    
                                </div>

                               {/*  <div className="col-md-4">
                                    <div className="mb-3 position-relative">
                                    <label
                                        htmlFor="ac_img"
                                        className="form-label"
                                    >
                                        Business Logo
                                    </label>
                                    <div className="input-group">
                                        <input
                                        type="file"
                                        name="bp_logo"
                                        accept="image/*"
                                        className="upload"
                                        id="ac_img"
                                        style={{ display: "none" }}
                                        onChange={e => handleImage(e, "businessInfo")}                                                                                
                                        />
                                        <label htmlFor="ac_img">
                                        <p className="btn btn-info">Upload Image </p>
                                        </label>

                                    </div>
                                    </div>
                                </div>   */}

                                 
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="Bus_Leg_Struc"
                                      className="form-label"
                                    >
                                      Legal Business Structure{" "}
                                      
                                    </label>
                                    <select
                                      className="form-select"
                                      id="Bus_Leg_Struc"
                                      name="Bus_Leg_Struc"                                      
                                      onChange={e => {
                                        setChanged("Bus_Leg_Struc")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.Bus_Leg_Struc}
                                      disabled
                                    >    
                                    <option value="" selected>Select Legal Business Structure</option>
                                    <option value="Benefit Corporation">Benefit Corporation</option>
                                    <option value="Corporation LLC">Corporation LLC</option>
                                    <option value="General Partnership">General Partnership</option>
                                    <option value="Joint venture">Joint Venture</option> 
                                    <option value="Limited Liability Company (LLC)">Limited Liability Company (LLC)</option>                                    
                                    <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option> 
                                    <option value="Limited Partnership">Limited Partnership</option>
                                    <option value="Nonprofit">Nonprofit</option> 
                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                    <option value="S Corporation">S Corporation</option> 
                                    </select>                                    
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="BusType"
                                      className="form-label"
                                    >
                                      Business Type{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                     className={
                                        errors.Bus_Name
                                          ? "invalid-inputfield form-control"
                                          : "BusType form-control"
                                      }
                                      id="BusType"
                                      name="BusType"
                                      disabled
                                      onChange={e => {
                                        setChanged("BusType")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.BusType}
                                    >                                       
                                    <option value="" selected="">Select Business Type</option>                                    
                                    <option value="Attorney/Lawyers">Attorney/Lawyers</option> 
                                    <option value="Consulting Services">Consulting Services</option>
                                    <option value="Financial Advisor">Financial Advisor</option>
                                    <option value="Insurance Agent">Insurance Agent</option>                                   
                                    <option value="Realtor">Realtor</option>                                                                                     
                                    </select>
                                    {errors.BusType && <div className="invalid-input">
                                      {errors.BusType}
                                      
                                    </div>}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                     BusKey{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="BusKey"
                                      id="validationTooltip02"
                                      disabled                                      
                                      value={form.BusKey}
                                    /> 
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Business Email{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="EmailID"
                                      id="validationTooltip02"
                                      placeholder="Business Email"
                                      
                                      onChange={e => {
                                        setChanged("EmailID")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.EmailID}
                                      disabled
                                    />                                    
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Business Phone{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="Phone"
                                      id="validationTooltip02"
                                      placeholder="Business Phone"
                                      
                                      onChange={e => {
                                        setChanged("Phone")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.Phone}
                                      disabled
                                    />                                   
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      htmlFor="validationTooltip02"
                                      className="form-label"
                                    >
                                      Business Website{" "}
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="Bus_Website"
                                      id="validationTooltip02"
                                      placeholder="Business Website"
                                      
                                      onChange={e => {
                                        setChanged("Bus_Website")
                                        handleForm(e, "businessInfo")
                                      }
                                      }
                                      value={form.Bus_Website}
                                      disabled
                                    />                                    
                                  </div>
                                </div>
                                
                                <h4 className="card-title">Address</h4>  

                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom05"
                                        className="form-label"
                                      >
                                        Street 1
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Street1"
                                        id="validationCustom05"
                                        placeholder="Street 1"
                                        value={form.Street1}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom06"
                                        className="form-label"
                                      >
                                        Street 2
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Street2"
                                        id="validationCustom06"
                                        placeholder="Street 2"
                                        value={form.Street2}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom07"
                                        className="form-label"
                                      >
                                        City
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="City"
                                        id="validationCustom07"
                                        placeholder="City"
                                        value={form.City}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="country"
                                        className="form-label"
                                      >
                                        Country
                                      </label>
                                      <select
                                        className="form-select"
                                        name="Country"
                                        id="Country"
                                        value={form.Country}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                        disabled
                                      >
                                        <option value="" selected="">Select Country</option>
                                        <option value="USA">USA</option>
                                        <option value="INDIA">INDIA</option>
                                      </select>
                                    </div>
                                  </div>
  
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="region"
                                        className="form-label"
                                      >
                                        Region
                                      </label>
                                      <select
                                        className="form-select"
                                        name="Region"
                                        id="region"
                                        value={form.Region}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                        disabled
                                      >
                                        {form.RegionList.length ===
                                        0 ? (
                                          <option value="" selected="">
                                            Select Region
                                          </option>
                                        ) : (
                                          <>
                                          <option value="" defaultValue>
                                            Select Region
                                          </option>
                                          { form.RegionList.map(each => (
                                            <option
                                              value={each.region}
                                              key={each.region}
                                            >
                                              {each.region}
                                            </option>
                                          ))}
                                          </>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="validationCustom07"
                                        className="form-label"
                                      >
                                        Zip
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="Zip"
                                        oninput="validateZIP()"
                                        id="zipCode"
                                        placeholder="Zip"
                                        value={form.Zip}
                                        onChange={e =>
                                          handleForm(e, "businessInfo")
                                        }
                                        disabled
                                      />
                                      <div id="zipError"></div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </Form>
                          </TabPane>
                                               
                        </TabContent>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default BusinessPartnersInfo
  