import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"  
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane, 
    Modal, ModalBody, ModalHeader,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { randomId } from "helpers/randomGen"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
  
  const ListOfDocumentStep = lazy(() =>
    import("components/Customers/DocumentRepository/ListOfDocumentStep")
  )
  
  const ListOfDocumentQuestion = lazy(() =>
    import("components/Customers/DocumentRepository/ListOfDocumentQuestion")
  ) 
 
  
  const initialFormDetails = {
                 
    doc_name: "",
    doc_type: "",
    doc_subtype: "",       
    country: "",
    region: "",
    short_description: "",
    instruction: "",  
    upload_file: "",
  
    ListOfDocumentStep: [],
    ListOfDocumentQuestion: [],
    RegionList: [],
          
  }
  
  const AddDocumentRepository = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    
  
    const validateForm = () => {
      var isValid = true
      const newErrors = {}
  
      // Validation logic for each input
      if (!form.user_fname.trim()) {
        newErrors.user_fname = "First name is required"
        isValid = false
      }
  
      if (!form.user_lname.trim()) {
        newErrors.user_lname = "Last name is required"
        isValid = false
      }
  
      setErrors(newErrors)
      
      return isValid
    }
  
  
    async function fetchRegionsList(countryName, type) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
          )
    
          const data = await response.json()
          if (type != 1) {
            setForm({
              ...form,
              RegionList: data?.data || [],
              region: data.data[0]?.region || "",
              country: countryName,
            })
          }
    
          return data?.data || []
        } catch (error) {
          console.log(error)
        }
      }
  
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed


    console.log("Documenrts FOrm", form)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [isUserValidated, setIsUserValidated] = useState(false)
  const [userKayVal, setUserKayVal] = useState("")
  const [displayImg, setDisplayImg] = useState({})

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }
  const tog_small = () => {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const tog_xlarge = () => {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  const handleUserKay = () => {
    if (userKayVal && userKayVal === loginUserData.user_key) {
      setIsUserValidated(false)
      tog_xlarge()
      tog_small()
    } else {
      setIsUserValidated(true)
    }
    setUserKayVal("")
  }

  const displayModel = (title, url) => {
    tog_small()
    const fileType = /[^.]+$/.exec(url)
    const isImage = ["jpg", "jpeg", "png"].some(ext => {
      return fileType.some(ext2 => {
        return ext === ext2
      })
    })
    
    
    console.log(url, fileType)
    let path =
      fileType[0] === "doc" || fileType[0] === "docx"
        ? `https://docs.google.com/gview?url=${url}&embedded=true`
        : url
    setDisplayImg({
      path: path,
      title: title,
      isImage: isImage,
      show: true,
      fileType: fileType,
    })
  }

  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")

     const docIdString = localStorage.getItem("docId");
     const docId = docIdString ? parseInt(docIdString) : undefined;

   // const docId = parseInt(JSON.parse(localStorage.getItem("docId")))
   
    async function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
          localStorage.setItem("tab", tab)
  
         
        }
      }
    }
    
    async function fetchDocumentStepListData() {
      try {
        const url = `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/document_steps_list?doc_id=${docId || id}`
  
        const response = await fetch(url)
  
        const data = await response.json()
  
        const decryptedData = data?.data?.map((each, idx) => {
          return {
            ...each,
            number: idx + 1,
            step_no: each.step_no || "",
            step_name: each.step_name || "",
            step_description: each.step_description || "", 
            icon: each.icon || "",          
          }
        })
  
        setTimeout(() => {
          setForm(prev => {
            return {
              ...prev,
              ListOfDocumentStep: decryptedData || [],
            }
          })
        }, [500])
      } catch (err) {}
    }
  
    const handleRemoveDocumentStepList = async (ds_id, idx) => {
      try {
        if (ds_id) {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document_step`
          const data = {
            ds_id: ds_id,
          }
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
          const removeData = await response.json()
  
          if (removeData?.success === true) {
            showMessage(removeData.message, "success")
            setForm(prev => {
              return {
                ...prev,
                ListOfDocumentStep: form.ListOfDocumentStep.filter(
                  each => each.ds_id !== ds_id
                ),
              }
            })
          } else {
            showMessage(removeData.message, "error", false)
          }
        } else if (idx >= 0) {
          setForm({
            ...form,
            ListOfDocumentStep: form.ListOfDocumentStep.filter(
              (each, index) => index != idx
            ),
          })
        }
      } catch (error) {
        showMessage(removeData?.message || "Something went wrong", "error", false)
      }
    }
  
    let timeoutId;
    useEffect(() => {
      if (activeTab === 2) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          fetchDocumentStepListData();
        }, 300); // Delay to debounce API calls
      }
      return () => clearTimeout(timeoutId); // Clean up on unmount
    }, []);
  
    async function fetchDocumentQsListData() {
      try {
        const url = `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/document_question_list?doc_id=${docId || id}`
  
        const response = await fetch(url)
  
        const data = await response.json()
  
        const decryptedData = data?.data?.map((each, idx) => {
          return {
            ...each,
            number: idx + 1,
            qno: each.qno || "",
            question_place_holder: each.question_place_holder || "",
            question: each.question || "",
            information: each.information || "",  
          }
        })
  
        setTimeout(() => {
          setForm(prev => {
            return {
              ...prev,
              ListOfDocumentQuestion: decryptedData || [],
            }
          })
        }, [500])
      } catch (err) {}
    }
  
    const handleRemoveStepList = async (dq_id, idx) => {
      try {
        if (dq_id) {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document_quetion`
          const data = {
            dq_id: dq_id,
          }
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
          const removeData = await response.json()
  
          if (removeData?.success === true) {
            showMessage(removeData.message, "success")
            setForm(prev => {
              return {
                ...prev,
                ListOfDocumentQuestion: form.ListOfDocumentQuestion.filter(
                  each => each.dq_id !== dq_id
                ),
              }
            })
          } else {
            showMessage(removeData.message, "error", false)
          }
        } else if (idx >= 0) {
          setForm({
            ...form,
            ListOfDocumentQuestion: form.ListOfDocumentQuestion.filter(
              (each, index) => index != idx
            ),
          })
        }
      } catch (error) {
        showMessage(removeData?.message || "Something went wrong", "error", false)
      }
    }
  
    useEffect(() => {
      if (activeTab === 3) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          fetchDocumentQsListData();
        }, 300); // Delay to debounce API calls
      }
      return () => clearTimeout(timeoutId); // Clean up on unmount
    }, []);
  
 
   
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const decryptedData = data?.data[0]
            if (decryptedData) {
              
              const assetInformation = {
                ...decryptedData,
                doc_name: decryptedData.doc_name,
                doc_type: decryptedData.doc_type,
                doc_subtype: decryptedData.doc_subtype,      
                country: decryptedData.country,
                region: decryptedData.region,
                short_description: decryptedData.short_description,
                instruction: decryptedData.instruction,   
                upload_file: decryptedData.upload_file,                                    
              }
              setForm({
                ...form,
                ...assetInformation,   
                RegionList: await fetchRegionsList(
                    assetInformation.country,
                    1
                  ),           
              })
            }
          }
  
          return true
        }   
  
        if (docId && !id) {
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_document_repository?doc_id=${docId}`
              )
  
              const data = await response.json()
              setFormData(data)
            }
  
            fetchDataEditData()
          } catch (e) {}
        }

        if (id) {
            try {
              const fetchDataEditData = async () => {
                const response = await fetch(
                  `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_document_repository?doc_id=${id}`
                )
    
                const data = await response.json()
                setFormData(data)
              }
    
              fetchDataEditData()
            } catch (e) {}
          }
      }
    }, [activeTab])
  
    const handleForm = async (e, action, key) => {
      
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "documentrepository":

        if (name === "country") {
            fetchRegionsList(value)
          } else{
            setForm({
              ...form,
              [name]: value,
            })
          }          
                
  
          break
        
        
        case "addListOfDocumentStep":
          let latestNumbers = 2
          if (form.ListOfDocumentStep.length) {
            let numberList = form.ListOfDocumentStep.map(each => each.number)
  
            latestNumbers = Math.max(...numberList) + 1
          }
          form.ListOfDocumentStep.push({
            number: form.ListOfDocumentStep.length ? latestNumbers : 1,
            
          })
          setForm({ ...form, ListOfDocumentStep: form.ListOfDocumentStep })
          break
        case "removeListOfDocumentStep":
            const modifiedListsprice = form.ListOfDocumentStep.find(
              (each, idx) => idx === key
            )
            handleRemoveDocumentStepList(modifiedListsprice?.ds_id || 0, key)
            break
        case "documentstepList":
          setForm({
            ...form,
            ListOfDocumentStep: form.ListOfDocumentStep.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: value,
                }
              }
  
              return each
            }),
          })
          break
          
  
        case "addListOfDocumentQuestion":
            let latestNumbersss = 2
            if (form.ListOfDocumentQuestion.length) {
            let numberList = form.ListOfDocumentQuestion.map(each => each.number)
    
            latestNumbersss = Math.max(...numberList) + 1
            }
            form.ListOfDocumentQuestion.push({
            number: form.ListOfDocumentQuestion.length ? latestNumbersss : 1,
            })
            setForm({ ...form, ListOfDocumentQuestion: form.ListOfDocumentQuestion })
            break
        case "removeListOfDocumentQuestion":
          const modifiedListsstep = form.ListOfDocumentQuestion.find(
            (each, idx) => idx === key
          )
          handleRemoveStepList(modifiedListsstep?.dq_id || 0, key)
          break            
        case "documentQuestionList":
          setForm({
            ...form,
            ListOfDocumentQuestion: form.ListOfDocumentQuestion.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: value,
                }
              }
  
              return each
            }),
          })
          break
        
        default:
          null
      }
    }

  const handleImage = async (e) => {
    const { files } = e.target;
  
    if (!files || files.length === 0) {
      showMessage("No file selected. Please select an image.","error");
      return;
    }
  
    const file = files[0];

    if (!file.type === "application/msword" && 
      !file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    showMessage("Only Word document files are allowed!", "error");
    e.target.value = "";
    return;
  }
  
    try {
      const result = await FileUpload(files);
  
      setForm({ ...form, [name]: result.files?.[0]?.url })
    } catch (error) {
      showMessage("Failed to upload the image. Please try again.","error");
    }
  };
    
  
    
    const handleFormSubmit = async () => {
      switch (activeTab) {
        case 1:
          if (form.doc_name) {
            try {
             
              const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_document_repository`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_document_repository`
              
              const data = {              
                doc_id: id || docId,                
                doc_name: form.doc_name,
                doc_type: form.doc_type,
                doc_subtype: form.doc_subtype,      
                country: form.country,
                region: form.region,
                short_description: form.short_description,
                instruction: form.instruction,
                
              }
  
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
  
              setApiStatus({ ...apiStatus, inProgress: true })
  
              const response = await fetch(url, options, {})
  
              const addOrUpdateBusinessInformation = await response.json()
  
              if (addOrUpdateBusinessInformation?.success === true) {
                setApiStatus({ ...apiStatus, inProgress: false })
                setactiveTab(2)
                localStorage.setItem("tab", 2)
                localStorage.setItem(
                  "docId",
                  addOrUpdateBusinessInformation.docId
                )
                await fetchDocumentStepListData()             
                showMessage(addOrUpdateBusinessInformation.message, "success")
              } else {
                showMessage(addOrUpdateBusinessInformation?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
              }
            } catch (error) {}
          }
          else {
            setErrors(prev => {
              return {
                ...prev,
                Bus_Name: "Please enter Document Name"
              }
            })
          }
          break
        case 2:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_doc_step`
  
            const data = {
              doc_id: docId || id,            
              ListOfDocumentStep: form.ListOfDocumentStep.map(
                eachDocStep => {
                  return {
                    ds_id: eachDocStep.ds_id || 0,
                    step_no: eachDocStep.step_no || "",
                    step_name: eachDocStep.step_name || "",
                    step_description: eachDocStep.step_description || "",
                    icon: eachDocStep.icon || "",
                  }
                }
              ),
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            const response = await fetch(url, options, {})
  
            const addOrUpdateListOfprice = await response.json()
  
            if (addOrUpdateListOfprice?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              await fetchDocumentQsListData()
              showMessage(addOrUpdateListOfprice.message, "success")
              // setTimeout(() => {
              //   history("/asset-wallet")
              // }, 3000)
              setactiveTab(activeTab + 1)
              localStorage.setItem("tab", activeTab + 1)
            } else {
              showMessage(addOrUpdateListOfprice?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break        
        case 3:
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_doc_quetion`
  
            const data = {
              doc_id: docId || id,
              ListOfDocumentQuestion: form.ListOfDocumentQuestion.map(
                eachDocQs => {
                  return {
                    dq_id: eachDocQs.dq_id || 0,                  
                    qno: eachDocQs.qno || "",  
                    question_place_holder: eachDocQs.question_place_holder || "", 
                    question: eachDocQs.question || "",  
                    information: eachDocQs.information || "",                 
                  }
                }
              ),
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            const response = await fetch(url, options, {})
  
            const addOrUpdateDocumentQuestion = await response.json()
  
            if (addOrUpdateDocumentQuestion?.success === true) {
              setactiveTab(4)
                localStorage.setItem("tab", 4)
               // await fetchListOfDocuments()
              setApiStatus({ ...apiStatus, inProgress: false })
              showMessage(addOrUpdateDocumentQuestion.message, "success")
              // setTimeout(() => {
              //   history("/asset-wallet")
              // }, 3000)
              setactiveTab(activeTab + 1)
              localStorage.setItem("tab", activeTab + 1)
            } else {
              showMessage(addOrUpdateDocumentQuestion?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
          break
          case 4:
            try {
              setApiStatus({ ...apiStatus, inProgress: true })
              const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_documentupload`
    
              const data = {                
                doc_id: docId || id,               
                document: form.document || ""
              }
    
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
    
              const response = await fetch(url, options, {})
    
              const addOrUpdateLifeInsurance = await response.json()
    
              if (addOrUpdateLifeInsurance?.success === true) {
                showMessage(addOrUpdateLifeInsurance.message, "success")
                 setTimeout(() => {
                    history("/document_repository_list")
                  }, 3000)
              } else {
                showMessage(addOrUpdateLifeInsurance?.message, "error", false)
                setApiStatus({ ...apiStatus, inProgress: false })
              }
            } catch (error) {
              showMessage(error, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
            break
          default:
            break 
              
      }
  
      if (!(id || docId) && activeTab != 1) {
        setactiveTab(1)
        localStorage.setItem("tab", 1)
      }
    }
  
    const handleBlur = e => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value?.trim(),
        errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
      })
    }
    useEffect(() => {
      if (changed) {
        setErrors(prev => {
          const copy = JSON.parse(JSON.stringify(prev))
          delete copy[changed]
          return copy
        })
      }
    }, [changed])  // changed
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">DOCUMENT REPOSITORY</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Operations</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/asset-wallet">Document Repository</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                         
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 1 })}
                              onClick={() => {
                                if (id) {                                
                                  setactiveTab(1);
                                 } else {                                
                                  activeTab > 1 && setactiveTab(1)
                                 }  
                                localStorage.setItem("tab", 1)
                              }}
                            >
                              
                              Document Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}                         
                              onClick={async () => {
                                if (id) {                                
                                  setactiveTab(2);
                                 } else {                                
                                  activeTab > 2 && setactiveTab(2)
                                 }  
                                localStorage.setItem("tab", 2)
                                await fetchDocumentStepListData()
                              }}
                            >
                              
                              Document Steps
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={async () => {
                                if (id) {                                
                                  setactiveTab(3);
                                 } else {                                
                                  activeTab > 3 && setactiveTab(3)
                                 }  
                                localStorage.setItem("tab", 3)
                                await fetchDocumentQsListData()
                              }}
                            >
                             
                             Document Questions 
                            </NavLink>
                          </NavItem>
  
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={async () => {
                                setactiveTab(4)
                                localStorage.setItem("tab", 4)
                               
                              }}
                            >
                              
                              Document Upload
                            </NavLink>
                          </NavItem>  
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {/**4,5,6 dynamic forms and tables */}
                         
                          <TabPane tabId={1}>                          
                              <Form className="mb-4">   
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="doc_name"
                                            className="form-label"
                                        >
                                            Name of Document{" "}
                                            <span className="text-danger"></span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="doc_name"
                                            id="doc_name"
                                            placeholder="Name of Document"
                                            required
                                            onChange={e => handleForm(e, "documentrepository")}
                                            value={form.doc_name}
                                        />
                                        
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="doc_type"
                                            className="form-label"
                                        >
                                            Doc Type
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="doc_type"
                                            id="doc_type"
                                            placeholder="Doc Type"
                                            onChange={e => handleForm(e, "documentrepository")}
                                            value={form.doc_type}
                                        />
  
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="doc_subtype"
                                            className="form-label"
                                        >
                                            Sub Type
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="doc_subtype"
                                            id="doc_subtype"
                                            placeholder="Sub Type"
                                            onChange={e => handleForm(e, "documentrepository")}
                                            value={form.doc_subtype}
                                        />
  
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="country"
                                        className="form-label"
                                      >
                                        Country
                                      </label>
                                      <select
                                        className="form-select"
                                        name="country"
                                        id="country"
                                        value={form.country}
                                        onChange={e =>
                                          handleForm(e, "documentrepository")
                                        }
                                      >
                                        <option value="" selected="">Select Country</option>
                                        <option value="USA">USA</option>
                                        <option value="INDIA">INDIA</option>
                                      </select>
                                    </div>
                                    </div>  
                                    <div className="col-md-6">
                                    <div className="mb-3">
                                        <label
                                        htmlFor="region"
                                        className="form-label"
                                        >
                                        Region
                                        </label>
                                        <select
                                        className="form-select"
                                        name="region"
                                        id="region"
                                        value={form.region}
                                        onChange={e =>
                                            handleForm(e, "documentrepository")
                                        }
                                        >
                                        {form.RegionList.length ===
                                        0 ? (
                                            <option value="" selected="">
                                            Select Region
                                            </option>
                                        ) : (
                                          <>
                                          <option value="" defaultValue>
                                            Select Region
                                          </option>
                                          {  form.RegionList.map(each => (
                                            <option
                                                value={each.region}
                                                key={each.region}
                                            >
                                                {each.region}
                                            </option>
                                            )) }
                                           </>
                                        )}
                                        </select>
                                    </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="short_description"
                                            className="form-label"
                                        >
                                            Short Description{" "}
                                            
                                        </label>
                                        <textarea
                                            id="short_description"
                                            name="short_description"
                                            rows="3"
                                            className="form-control"
                                            onChange={e => handleForm(e, "documentrepository")}  
                                            value={form.short_description}
                                        ></textarea>
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                        <label
                                            htmlFor="instruction"
                                            className="form-label"
                                        >
                                            Document Instructions{" "}
                                            
                                        </label>
                                        <textarea
                                            id="instruction"
                                            name="instruction"
                                            rows="3"
                                            className="form-control"
                                            onChange={e => handleForm(e, "documentrepository")}  
                                            value={form.instruction}
                                        ></textarea>
                                        
                                        </div>
                                    </div>
                                
                                </div>
                              </Form>                         
                          </TabPane>
                          <TabPane tabId={2}>
                          <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >
                              <h4 className="page-title-box">
                                List Of Document Steps
                              </h4>
  
                              <ListOfDocumentStep
                                form={form}
                                handleForm={handleForm}   
                                handleImage={handleImage}                           
                                docId={id || docId}
                                activeTab={activeTab}
                              />
                            </Suspense>
                          </TabPane>  
                          <TabPane tabId={3}>
                            <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >
                              <h4 className="page-title-box">
                                List Of Document Questions
                              </h4>
  
                              <ListOfDocumentQuestion
                                form={form}
                                handleForm={handleForm}                              
                                docId={id || docId}
                                activeTab={activeTab}
                              />
                            </Suspense>
                          </TabPane>  
                          <TabPane tabId={4}>
                            <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >
                              <h4 className="page-title-box">
                              Document Upload
                              </h4>

                              <div className="card">
                              <div className="card-body">
                               
                                <div className="row">
                                  <div className="col-md-4">
                                    <input
                                      type="file"
                                      name="document"
                                      accept=".doc, .docx,/*"
                                      className="upload"
                                      // style={{ display: "none" }}
                                      onChange={handleImage}
                                    />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            
                              <Button
                                color="primary"
                                onClick={() =>
                                  displayModel(form.doc_name, form.upload_file) 
                                }
                              >
                                View document
                              </Button>
                              <Modal isOpen={modal_small} toggle={tog_small} size="sm">
                                <ModalHeader className="mt-0" toggle={tog_small}>
                                  Please enter "User Key" to open file
                                </ModalHeader>
                                <ModalBody>
                                  <p>
                                    Note: Please find your "User Key" in personal
                                    information section
                                  </p>
                                  <p>
                                    <input
                                      type="userKay"
                                      className="form-control"
                                      name="userKay"
                                      id="userKay"
                                      value={userKayVal}
                                      onChange={e => setUserKayVal(e.target.value)}
                                      onBlur={e => setIsUserValidated(false)}
                                    />
                                  </p>
                                  <p>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleUserKay}
                                    >
                                      Verify User Key
                                    </button>
                                  </p>
                                  {isUserValidated && (
                                    <p className="alert alert-danger ">
                                      Please enter valid "User Key" value.
                                    </p>
                                  )}
                                </ModalBody>
                              </Modal>
                              {displayImg.show && (
                                <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                                  <ModalHeader className="mt-0" toggle={tog_xlarge}>
                                    {displayImg.title}
                                  </ModalHeader>
                                  <ModalBody>
                                    {displayImg.isImage ? (
                                      <img src={displayImg.path} width="100%"></img>
                                    ) : (
                                      <iFrame
                                        src={displayImg.path}
                                        width="100%"
                                        height="650px"
                                      ></iFrame>
                                    )}
                                  </ModalBody>
                                </Modal>
                              )}

                           


                            </Suspense>
                          </TabPane>  
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              type="button"
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                              onClick={() => {
                                // activeTab != handleFormSubmit()
                                toggleTab(activeTab - 1)
                              }}
                              disabled={activeTab === 1}
                            >
                              Previous
                            </Button>
                          </li>
                          <li
                            className={activeTab === 4 ? "next disabled" : "next"}
                          >
                            <Button
                              disabled={activeTab === 4}
                              type="button"
                              onClick={() => {
                                handleFormSubmit()
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {docId || id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                    to={"/document_repository_list"}
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                      localStorage.removeItem("docId")
                      localStorage.removeItem("tab")
                    }}
                  >
                    Cancel
                  </Link>              
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddDocumentRepository
  