import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddApplication = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,    
  })

  const [inititalState, setInitialState] = useState({
    pal_process_name: "",
    pal_application: "",
    
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
        pal_process_name: Yup.string().required("Please Select Process Name"),
        pal_application: Yup.string().required("Please Select Aplication"),             
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/updateapllication`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/addapplication`

      const data = {
        pal_id: id || 0,
        pal_process_name: values.pal_process_name,
        pal_application: values.pal_application,            
        
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addRole = await response.json()

        if (addRole?.success === true) {
          showMessage(addRole.message, "success")
          setTimeout(() => {
            history("/application_list")
          }, 3000)
        } else {
          showMessage("Adding Application failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/application_list?pal_id=${
            id || 0
          }`
        )
        const data = await response.json()
        const appinfo = data?.data[0] || ""
        if (appinfo) {
          let reInitialValues = { ...inititalState }

          reInitialValues.pal_process_name = appinfo.pal_process_name || ""
          reInitialValues.pal_application = appinfo.pal_application || ""       
          
          setApiStatus({
            ...apiStatus,
            pal_process_name: [appinfo.pal_process_name],
            pal_application: [appinfo.pal_application],
          })

          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

 

  document.title = "Add Applications list | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
          <Row>
            <Col xs="12" className="m-6">
                <div className="page-title-box">
                  <h4 className="font-size-18"> {id ? "EDIT APPLICATION" : "ADD APPLICATION"} </h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/">Admin</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/">  {id ? "Edit Application" : "Add Application"} </a>
                    </li>
                  </ol>
                </div>               
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      
                      <Row>
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="pal_process_name">
                              Process Name
                            </Label>
                            <Input
                              name="pal_process_name"
                              className="form-control"
                              type="select"
                              id="pal_process_name"
                              onChange={validation.handleChange}
                              value={validation.values.pal_process_name}
                            >
                              <option value="">Select</option>
                              <option value="Account Info">Account Info</option>
                              <option value="Services">Services</option>
                              <option value="Workspace">Workspace</option>
                              <option value="Platform">Platform</option>
                            </Input>
                          </div>
                        </Col>
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="pal_application">
                              Application
                            </Label>
                            <Input
                              name="pal_application"
                              className="form-control"
                              placeholder="Enter Applications"
                              type="text"
                              id="pal_application"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.pal_application || ""}
                            />                        
                          </div>
                        </Col>
                      </Row>
                     

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"}
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/application_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddApplication
