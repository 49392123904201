import { userId } from "helpers/userId"
import { Link } from "react-router-dom"
import React, { useEffect, useState, useCallback } from "react"
import ReactFamilyTree from "react-family-tree"
import { Container } from "reactstrap"
import FamilyNode from "./FamilyNode"

const initialFormDetails = {
  user_name: "",
  user_fname: "",
  user_mname: "",
  user_lname: "",
  gender: "",
  profile_pic: "",
  relation: "",
}

const ExtendedFamily = () => {
  const [familyInformation, setFamilyInformation] = useState({ data: [] })
  const [nodes, setNodes] = useState([])
  const [form, setForm] = useState(initialFormDetails)
  const [error, setError] = useState(null)
  const WIDTH = 260
  const HEIGHT = 280
  const rootId = userId.toString()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/family_information?user_id=${userId}`
        )
        if (!response.ok) {
          throw new Error(
            `Error fetching family information: ${response.statusText}`
          )
        }
        const data = await response.json()
        setFamilyInformation({ data: data?.data || [] })
      } catch (error) {
        console.error("Error fetching family information:", error)
        setError("Failed to load family information. Please try again later.")
      }
    }

    const fetchSelfData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userId}`
        )
        if (!response.ok) {
          throw new Error(
            `Error fetching personal information: ${response.statusText}`
          )
        }
        const data = await response.json()
        if (data?.success) {
          const personalInfo = data.data
          const decryptedUserName = `${personalInfo.user_fname || ""} ${
            personalInfo.user_lname || ""
          }`.trim()

          setForm({
            ...form,
            user_id: personalInfo.user_id.toString(),
            user_key: personalInfo.user_key,
            user_name: decryptedUserName,
            user_fname: personalInfo.user_fname,
            user_mname: personalInfo.user_mname,
            user_lname: personalInfo.user_lname,
            gender: personalInfo.gender,
            profile_pic: personalInfo.profile_pic,
            relation: "self",
            associated: "yes",
          })
        } else {
          throw new Error(
            "Failed to load self-data. Check your profile information."
          )
        }
      } catch (error) {
        console.error("Error fetching personal information:", error)
        setError("Failed to load personal information. Please try again later.")
      }
    }

    fetchData()
    fetchSelfData()
  }, [])

  const mapFamilyTree = useCallback(() => {
    const nodeMap = {}
    if (form.user_id > 0) {
      const mainPerson = {
        id: form.user_id.toString(),
        gender: form.gender,
        profilePic: form.profile_pic || "",
        displayName: form.user_name,
        relation: "Self",
        associated: form.associated,
        parents: [],
        siblings: [],
        spouses: [],
        children: [],
      }
      nodeMap[mainPerson.id] = mainPerson
    }

    familyInformation.data.forEach(person => {
      const gender =
        person.ac_reliation === "Mother" ||
        person.ac_reliation === "Sister" ||
        person.ac_reliation === "Daughter"
          ? "female"
          : person.ac_reliation === "Father" ||
            person.ac_reliation === "Brother" ||
            person.ac_reliation === "Son" ||
            person.ac_reliation === "Spouse"
          ? "male"
          : "unknown"

      const node = {
        id: person.ac_id.toString(),
        gender: gender,
        profilePic: person.ac_image || "",
        displayName: `${person.ac_fname} ${person.ac_lname}`,
        relation: person.ac_reliation,
        associated: person.Associated,
        parents: [],
        siblings: [],
        spouses: [],
        children: [],
      }
      nodeMap[node.id] = node
    })

    familyInformation.data.forEach(person => {
      const node = nodeMap[person.ac_id]
      const mainNode = nodeMap[form.user_id]

      if (!node || !mainNode) return

      switch (person.ac_reliation) {
        case "Father":
        case "Mother":
          if (!mainNode.parents.includes(node)) mainNode.parents.push(node)
          if (!node.children.includes(mainNode)) node.children.push(mainNode)
          break

        case "Brother":
        case "Sister":
          if (!mainNode.siblings) mainNode.siblings = []
          if (!node.siblings) node.siblings = []

          if (!mainNode.siblings.includes(node)) {
            mainNode.siblings.push(node)
          }
          if (!node.siblings.includes(mainNode)) node.siblings.push(mainNode)

          mainNode.parents.forEach(parent => {
            if (!node.parents.includes(parent)) node.parents.push(parent)
            if (!parent.children.includes(node)) parent.children.push(node)
          })

          node.parents.forEach(parent => {
            if (!mainNode.parents.includes(parent))
              mainNode.parents.push(parent)
            if (!parent.children.includes(mainNode))
              parent.children.push(mainNode)
          })
          break

        case "Spouse":
          if (!mainNode.spouses.includes(node)) mainNode.spouses.push(node)
          if (!node.spouses.includes(mainNode)) node.spouses.push(mainNode)
          break

        case "Son":
        case "Daughter":
          if (!mainNode.children.includes(node)) mainNode.children.push(node)

          if (mainNode.spouses.length > 0) {
            mainNode.spouses.forEach(spouse => {
              if (!spouse.children.includes(node)) spouse.children.push(node)
            })
          }

          if (!node.parents.includes(mainNode)) node.parents.push(mainNode)

          mainNode.spouses.forEach(spouse => {
            if (!node.parents.includes(spouse)) node.parents.push(spouse)
          })
          break

        default:
          break
      }
    })

    const nodesArray = Object.values(nodeMap)

    const mainNode = nodeMap[form.user_id]
    if (mainNode && !nodesArray.includes(mainNode)) {
      nodesArray.unshift(mainNode)
    }

    setNodes(nodesArray)
  }, [form, familyInformation])

  useEffect(() => {
    if (form.user_id && familyInformation.data.length > 0) {
      mapFamilyTree()
    }
  }, [form, familyInformation, mapFamilyTree])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">EXTENDED FAMILY</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Extended Family</a>
              </li>
            </ol>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <h4 className="font-size-18">Extended Family Tree</h4>
                <div>
                  <Link
                    to="/family-tree"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Back to Family Tree
                  </Link>
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                {!error && nodes.length > 0 && (
                  <ReactFamilyTree
                    nodes={nodes}
                    rootId={rootId}
                    width={WIDTH}
                    height={HEIGHT}
                    canvasClassName="'tree'"
                    renderNode={node => {
                      if (node.placeholder) {
                        return null
                      }

                      return (
                        <FamilyNode
                          key={node.id}
                          node={node}
                          isRoot={userId === rootId}
                          style={{
                            width: WIDTH,
                            height: HEIGHT,
                            transform: `translate(${
                              node.left * (WIDTH / 2)
                            }px, ${node.top * (HEIGHT / 2)}px)`,
                          }}
                        />
                      )
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExtendedFamily
