import React, { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import VerticalLayout from "components/VerticalLayout"
import HorizontalLayout from "components/HorizontalLayout"
import PersonalInformation from "pages/AccountInfo/PersonalInformation"
import Dashboard from "pages/Dashboard"
//constants
import { layoutTypes } from "../../constants/layout"
import { useNavigate } from "react-router-dom"

const Authmiddleware = props => {
    const history = useNavigate()
    const { path, forUser } = { ...props.route }
    const { layoutType } = useSelector(state => ({
        layoutType: state.Layout.layoutType,
    }))

    const navigate = useNavigate()

    const hasFinishedKYC = () => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        const { addres1, city, user_phone, zip, region, country } = userData.user
        return addres1 && city && user_phone && zip && region && country
    }

    const getLayout = layoutType => {
        let Layout = VerticalLayout
        switch (layoutType) {
            case layoutTypes.VERTICAL:
                Layout = VerticalLayout
                break
            case layoutTypes.HORIZONTAL:
                Layout = HorizontalLayout
                break
            default:
                break
        }
        return Layout
    }

    const Layout = getLayout(layoutType)

    const userData = JSON.parse(localStorage.getItem("userData"))?.user
    const otpStatus = parseInt(localStorage.getItem("otp_status"))

    if (!userData || !otpStatus) {
        return (
            <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }


    // naviate user to dashboard page, if the route is not available for the user.
    if (forUser && forUser.indexOf(userData.user_role) == -1) {
        history("/dashboard")
        return (
            <React.Fragment>
                <Layout><Dashboard /></Layout>
            </React.Fragment>
        )
    }

    // If customer has no personal inforamtion is not filled in, navigate to the Personal information page.
    if (!hasFinishedKYC() && forUser && forUser.indexOf(userData.user_role) == 0 && path != '/personal-information') {
        history("/personal-information")
        console.log()
        return (
            <React.Fragment>
                <Layout><PersonalInformation /></Layout>
            </React.Fragment>
        )
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const today = new Date()

            const login = localStorage.getItem("loginAt")

            if (login) {
                const __login = new Date(login)

                const difference = (today.getTime() - __login.getTime()) / (1000 * 60 * 60)

                if (difference > 4) {
                    localStorage.clear()
                    navigate("/login")
                }
            }
        }, 120000)

        return () => clearInterval(interval)
    }, [])

    return (
        <React.Fragment>
            <Layout>{props.children}</Layout>
        </React.Fragment>
    )
}

export default Authmiddleware
