import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddWillQuestion = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,    
  })

  const [inititalState, setInitialState] = useState({
    wq_name: "",
        
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
      // country: Yup.string().required("Please Enter Popup/Message No."),
      // region: Yup.string().required("Please Enter Popup Text"),
       // mal_rep: Yup.string().required("Please Enter Replacements"),
       // mal_process: Yup.string().required("Please Enter Process"),
       // mal_app: Yup.string().required("Please Enter Application"),
      
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/update_will_question`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/add_will_question`

      const data = {
        wq_id: id || 0,
        wq_name: values.wq_name,                     
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addWillQuestion = await response.json()

        if (addWillQuestion?.success === true) {
          showMessage(addWillQuestion.message, "success")
          setTimeout(() => {
            history("/willquestion_list")
          }, 3000)
        } else {
          showMessage("Adding Will Question failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/get_will_question_list?wq_id=${
            id || 0
          }`
        )
        const data = await response.json()
        const assetquestioninfo = data?.data[0] || ""
        if (assetquestioninfo) {
          let reInitialValues = { ...inititalState }
          reInitialValues.wq_id = assetquestioninfo.wq_id || ""
          reInitialValues.wq_name = assetquestioninfo.wq_name || ""         
                   
          setApiStatus({
            ...apiStatus,
           // application: [typeinfo.Applications],
            // role_name: [typeinfo.Role_Name],
          })

          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

 

  document.title = "Add Asset Question | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>

          
          <Row>
            <Col xs="12" className="m-6">
              <div className="page-title-box">
                <h4 className="font-size-18"> {id ? "EDIT WILL QUESTION" : "ADD WILL QUESTION"} </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="/">Admin</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/">  {id ? "Edit Will Question" : "Add Will Question"} </a>
                  </li>
                </ol>
              </div>            
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >                   
                       

                      <Row>                      
                        <Col xl="10">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="wq_name">
                            Will Question
                            </Label>
                            <Input
                              name="wq_name"
                              className="form-control"
                              placeholder="Enter Will Question"
                              type="text"
                              id="wq_name"
                              required
                              onChange={validation.handleChange}                              
                              value={validation.values.wq_name || ""}
                            />
                          </div>
                        </Col>                      
                      </Row>
                     

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"} 
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/willquestion_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddWillQuestion
