import AssetWalletTable from "components/DataTables/AssetWalletTable"
import React, { useEffect } from "react"
import { Container } from "reactstrap"

const AssetWallet = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("assetId")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">ASSET WALLET</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Asset Wallet</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <AssetWalletTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AssetWallet
