import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import { Container, Spinner,
    Button,
    Modal, ModalBody, ModalHeader,

   } from "reactstrap"
  import "../../../../src//styles/errorfield.scss"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { loginUserData, userId } from "helpers/userId"
  
  const initialFormDetails = {
    dv_name: "",
    dv_comment: "",
    dv_type: "",
    dv_sub_type: "",
    dv_file: "",   
  }
  
  const MakeDocumentSearch = () => {
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const [errors, setErrors] = useState({})  


    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
    const [assetImage, setAssetImage] = useState(null);


    useEffect(() => {
      const fetchData = async () => {
        
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_make_document?doc_id=${id}`
        )
        const data = await response.json()
        setAssetImage({ ...assetImage, data: data?.data || [] })
      }
      fetchData()
    }, [])
 
    // -- Fetch Document Vault list start -- //
    const fetchData = async () => {
      try {
        
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_document_rep_details?doc_id=${id}`
        )
        const data = await response.json()
  
        if (data?.success) {
          const acData = data?.data 
         
          setForm({
            ...form,
            short_description: acData.short_description,
            doc_id: acData.doc_id,
            doc_name: acData.doc_name,             
          })
        }
      } catch (err) {        
          history("/document_search_list")
      }
    }  
    useEffect(() => {
      if (id) {
        fetchData()
      }
    }, [])


    const rows = assetImage?.data?.map((each, index) => ({
      id: each.id || index,
      step_no: each.step_no,
      step_name: each.step_name,
      icon: each.icon, 
      step_description: each.step_description, 
  }));


  
    // -- Fetch Document Vault list end -- //  
  
    const handleForm = e => {
      let { name, value } = e.target
  
      if (name === "user_country") {
       // fetchRegionsList(value)
      } else {
        setForm({ ...form, [name]: value })
      }
    }
  
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">MAKE DOCUMENT</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/document_vault_list">Services</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/document_vault_list">Make Document</Link>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">


                  
                <div className="col-md-6">
                  <h2>{form.doc_name}</h2> 
                  <p>{form.short_description}</p>
                </div>

                <div className="col-md-2">
                  <Link
                    to={`/make_document_view?id=${form.doc_id}`}
                      className="btn btn-primary waves-effect waves-light me-1"                      
                  >
                    Make Document
                  </Link>                 
                </div>

    
    
 

                 

                  <div className="col-md-4">                   
                  <img
                    id="output_image"
                    className="rounded card-img img-thumbnail border border-2 p-0 d-inline-block"
                    alt="Your Image"
                    width="150"
                    height="300"
                    src={ "" || "default-profile.png"}
                  />
                  </div> 
                </div>                        
                
             

          <table>
            <tbody>
              <tr>
                {rows && rows.length > 0 ? (
                    rows.map((row) => (
                        
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                              <table><tr>
                                 <td>
                                    <img 
                                      src={row.icon || 'build-icon.png'} 
                                      alt={`${row.step_name} Icon`} 
                                      className="icon"
                                      width="50" 
                                      height="50"
                                  />
                                 </td>
                                 <td>
                                    <h3>{row.step_no}.{row.step_name}</h3>
                                    <p>{row.step_description}</p>
                                 </td>
                                 </tr></table>
                            </td>                        
                    ))
                ) : (
                    <tr>
                        <td colSpan="3" style={{ textAlign: 'center' }}>No data available</td>
                    </tr>
                )}</tr>
            </tbody>
          </table>
         
</div>
</div>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default MakeDocumentSearch
  