import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"  
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import BpContactTable from "components/DataTables/BpContactTable"
  
  
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  
  
  const ListOfOwners = lazy(() =>
    import("components/Customers/BP/ListOfOwners")
  ) 
  
  
  const initialFormDetails = {
    uid: "",
    
    RegionList: [],

    user_fname: "",
    user_mname: "",
    user_lname: "",
    user_email: "",
    user_phone: "",   
    CDesignation: "",
    user_country: "",
    user_zip: "",    
    regionsList: [],
    addres1: "",
    addres2: "",
    city: "",
    region: "",      
      
    ListOfOwners: [],
       
  }
  
  const BusinessPartnersContactInfo = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const relationsList = JSON.parse(localStorage.getItem("relationsList"))
  
    
    async function fetchRegionsList(countryName, type) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        )
  
        const data = await response.json()
        if (type != 1) {
          setForm({
            ...form,
            RegionList: data?.data || [],
            Region: data.data[0]?.region || "",
            Country: countryName,
          })
        }
  
        return data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  
    
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    
     
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
        case "addListOfOwner":
          let latestNumber = 2
          if (form.ListOfOwners.length) {
            let numberList = form.ListOfOwners.map(each => each.number)
  
            latestNumber = Math.max(...numberList) + 1
          }
          form.ListOfOwners.push({
            number: form.ListOfOwners.length ? latestNumber : 1,
            relationsList: relationsList,
          })
          setForm({ ...form, ListOfOwners: form.ListOfOwners })
          break
        case "ownersList":
          const modfiedListOfOwners = []
  
          for (let i = 0; i < form.ListOfOwners.length; i++) {
            const owner = {
              ...form.ListOfOwners[i],
            }
  
            if (i === key) {
              let regionsList = owner.regionsList || []
              if (name === "pol_country") {
                regionsList = await fetchRegionsList(value)
                owner.pol_region = regionsList[0]?.region || ""
              }
  
              modfiedListOfOwners.push({
                ...owner,
                [name]: value,
                regionsList,
              })
            } else {
              modfiedListOfOwners.push(owner)
            }
          }
  
          setForm({
            ...form,
            ListOfOwners: modfiedListOfOwners,
          })
          break
        case "removeListOfOwners":
          const modifiedList = form.ListOfOwners.filter(
            (each, idx) => idx !== key
          )
          setForm({ ...form, ListOfOwners: modifiedList })
          break       
       
        default:
          null
      }
    }
  
  
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">CONTACT INFORMATION</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">My Account</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/">Contact Information</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                         
                          <TabPane tabId={1}>

                          <Suspense
                              fallback={
                                <div className="text-center m-4">Loading...</div>
                              }
                            >                             
  
                              <BpContactTable
                                form={form}
                                handleForm={handleForm}                                
                                user_id={userId}                                
                              />
                          </Suspense>
                          </TabPane>
                                                  
                        </TabContent>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default BusinessPartnersContactInfo
  