import DocumentSearchTable from "components/DataTables/DocumentSearchTable"
import React, { useEffect,useState } from "react"
import { Form,Row, Col, Card, CardBody, Container } from "reactstrap"


const DocumentSearchList = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("assetId")
  }, [])

  const initialFormDetails = {                
    
    RegionList: [],
          
  }

  const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })

  async function fetchRegionsList(countryName, type) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
      )

      const data = await response.json()
      if (type != 1) {
        setForm({
          ...form,
          RegionList: data?.data || [],
          region: data.data[0]?.region || "",
          country: countryName,
        })
      }

      return data?.data || []
    } catch (error) {
      console.log(error)
    }
  }

  const handleForm = async (e, action, key) => {      
    let { name, value, type, checked } = e?.target || {}
    switch (action) {
      case "documentseach":

      if (name === "country") {
          fetchRegionsList(value)
        } else{
          setForm({
            ...form,
            [name]: value,
          })
        }    
        break      
      default:
        null
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">DOCUMENT SEARCH LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Document Search List</a>
              </li>
            </ol>
          </div>
            <Row>
                <Col className="col-12">
                <Card>
                    <CardBody>              
                    <div className="row">
                           <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor="user_fname" className="form-label">
                                Document Search
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  onChange={e =>handleForm(e, "documentseach")}
                                /> 
                              </div>
                            </div>
                            <div className="col-md-4">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="country"
                                        className="form-label"
                                      >
                                        Country
                                      </label>
                                      <select
                                        className="form-select"
                                        name="country"
                                        id="country"
                                        value={form.country}
                                        onChange={e =>handleForm(e, "documentseach")}
                                      >
                                        <option value="" selected="">Select Country</option>
                                        <option value="USA">USA</option>
                                        <option value="INDIA">INDIA</option>
                                      </select>
                                    </div>
                                    </div>  
                                    <div className="col-md-4">
                                    <div className="mb-3">
                                        <label
                                        htmlFor="region"
                                        className="form-label"
                                        >
                                        Region
                                        </label>
                                        <select
                                        className="form-select"
                                        name="region"
                                        id="region"
                                        value={form.region}
                                        onChange={e => handleForm(e, "documentseach")}
                                        >
                                        {form.RegionList.length ===
                                        0 ? (
                                            <option value="" selected="">
                                            Select Region
                                            </option>
                                        ) : (
                                          <>
                                          <option value="" defaultValue>
                                            Select Region
                                          </option>
                                          {  form.RegionList.map(each => (
                                            <option
                                                value={each.region}
                                                key={each.region}
                                            >
                                                {each.region}
                                            </option>
                                            )) }
                                           </>
                                        )}
                                        </select>
                                    </div>
                                    </div>	
                                    
                    </div>              
                    </CardBody>
                </Card>
                </Col>
            </Row>

          <div className="card">
            <DocumentSearchTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DocumentSearchList
