import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  
  import React, { Suspense, lazy, useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import classnames from "classnames"
  import {
    Spinner,
    Container,
    Form,
    NavItem,
    NavLink,
    Button,
    TabContent,
    TabPane,
  } from "reactstrap"
  import { loginUserData, userId } from "helpers/userId"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { change } from "redux-form" // changes
  
  const ListofBeneficiaries = lazy(() =>
    import("components/Customers/Investment/BeneficiaryInformation")
  )
  
  const initialFormDetails = {
    bs_account_number: "",
    bs_financial_institution_name: "",
    bs_account_type: "",
    bs_account: "",
    bs_accountHolderName1: "",
    bs_accountHolderName2: "",
    bs_amount: "",
    bs_currency: "",
    bs_dateOfUpdate: "",
  
    // Beneficiary Information
    bs_beneficiariesName: "",
    bs_relationship: "",
    bs_ownPercentage: "",
  
    ListofBeneficiaries: [],
  
    ListOfExistingBeneficiary: [],
  
    errors: {
        bs_account_number: "",
        bs_account_type: "",
        bs_account: "",
        bs_financial_institution_name: "",
      
    },
  }
  
  const AddInvestment = () => {
    const [activeTab, setactiveTab] = useState(
      (localStorage.getItem("tab") &&
        parseInt(JSON.parse(localStorage.getItem("tab")))) ||
        1
    )
  
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const relationsList = JSON.parse(localStorage.getItem("relationsList"))
  
    const [errors, setErrors] = useState({})
    const [changed, setChanged] = useState(undefined) // changed
  
    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
    const banksyncid = parseInt(JSON.parse(localStorage.getItem("banksyncid")))
  
    async function toggleTab(tab) {
      if (activeTab !== tab) {
        if (tab >= 1 && tab <= 2) {
          setactiveTab(tab)
          localStorage.setItem("tab", tab)
  
          if (activeTab == 2) {
            await fetchBeneficiaryData()
          }          
        }
      }
    }
  
    const validateForm = () => {
      var isValid = true
      const newErrors = {}
  
      
      
  
      setErrors(newErrors)
      return isValid
    }
  
    // -- Fetch relationships list start -- //
    async function fetchRelationsList() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
        )
        const data = await response.json()
  
        if (data?.data) {
          localStorage.setItem("relationsList", JSON.stringify(data?.data))
        }
  
        return data?.data
      } catch (error) {
        console.log(error)
      }
    }
  
     
    async function fetchBeneficiaryData() {
      try {
        const url = `${
          process.env.REACT_APP_DOMAIN_URL
        }api/v1/customer/services/get_invest_beneficiaries_list?bs_id=${
          id || banksyncid
        }&bs_uid=${userId}`
  
        const response = await fetch(url)
        const data = await response.json()
        const decryptedData = data?.data?.map((each, idx) => {
          return {
            ...each,
            number: idx + 1,
          bs_beneficiariesName: each.bs_beneficiariesName || "",
          bs_relationship: each.bs_relationship || "",
          bs_ownPercentage: each.bs_ownPercentage || "",
          relationsList: relationsList || [],
          }
        })
  
        setForm({
          ...form,
          ListofBeneficiaries: decryptedData || [],
          listOfOwners: [],
        })
      } catch (err) {}
    }
  
    let timeoutId
    useEffect(() => {
      if (activeTab === 2) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
          fetchBeneficiaryData()
        }, 100) // Delay to debounce API calls
      }
      return () => clearTimeout(timeoutId) // Clean up on unmount
    }, [activeTab])
  
    const handleRemoveBeneficiary = async (bi_id, idx) => {
      try {
        if (bi_id) {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_invest_beneficiaries`
          const data = {
            bi_id: bi_id,
          }
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
          const removeData = await response.json()
  
          if (removeData?.success === true) {
            showMessage(removeData.message, "success")
            setForm({
              ...form,
              ListofBeneficiaries: form.ListofBeneficiaries.filter(
                each => each.bi_id !== bi_id
              ),
            })
          } else {
            showMessage(removeData.message, "error", false)
          }
        } else if (idx >= 0) {
          setForm({
            ...form,
            ListofBeneficiaries: form.ListofBeneficiaries.filter(
              (each, index) => index != idx
            ),
          })
        }
      } catch (error) {
        showMessage(removeData?.message || "Something went wrong", "error", false)
      }
    }
  
   
  
    {
      /* */
    }
  
    useEffect(() => {
      if (activeTab === 2) {
        setTimeout(() => {
          fetchBeneficiaryData()
        }, [activeTab])
      }     
    }, [activeTab])
  
    useEffect(() => {
      fetchRelationsList()
    }, [activeTab])
  
    useEffect(() => {
      if (activeTab) {
        async function setFormData(data) {
          if (data.success) {
            const decryptedData = data?.data[0]
            if (decryptedData) {
             
              const assetInformation = {
                ...decryptedData,
                bs_account_number: decryptedData.bs_account_number || "",
                bs_financial_institution_name:
                  decryptedData.bs_financial_institution_name || "",
                bs_account_type: decryptedData.bs_account_type || "",
                bs_account: decryptedData.bs_account || "",
                bs_accountHolderName1: decryptedData.bs_accountHolderName1 || "",
                bs_accountHolderName2: decryptedData.bs_accountHolderName2 || "",
                bs_amount: decryptedData.bs_amount || "",
                bs_currency: decryptedData.bs_currency || "",
                bs_dateOfUpdate: decryptedData.bs_dateOfUpdate || "",
  
                // Beneficiary Information
                bs_beneficiariesName: decryptedData.bs_beneficiariesName || "",
                bs_relationship: decryptedData.bs_relationship || "",
                bs_ownPercentage: decryptedData.bs_ownPercentage || "",
              }
                
              setForm({
                ...form,
                ...assetInformation,                
              })
            }
          }
  
          return true
        }
        if (banksyncid && !id) {
          try {
            const fetchData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_investment_info?user_id=${userId}&bs_id=${banksyncid}`
              )
  
              const data = await response.json()
              await setFormData(data)
            }
  
            fetchData()
          } catch (e) {}
        }
  
        if (id) {
          try {
            const fetchDataEditData = async () => {
              const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_investment_info?user_id=${userId}&bs_id=${id}`
              )
  
              const data = await response.json()
              setFormData(data)
            }
  
            fetchDataEditData()
          } catch (e) {}
        }
      }
    }, [activeTab])
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      switch (action) {
      case "accountInformation":
        setForm({
          ...form,
          [name]: type == "checkbox" ? checked : value,
          errors: { ...form.errors, [name]: value?.trim() ? "" : "Required" },
        })

        break
      case "addListofBeneficiaries":
        let latestNumbers = 2
        if (form.ListofBeneficiaries.length) {
          let numberList = form.ListofBeneficiaries.map(each => each.number)

          latestNumbers = Math.max(...numberList) + 1
        }
        form.ListofBeneficiaries.push({
          number: form.ListofBeneficiaries.length ? latestNumbers : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, ListofBeneficiaries: form.ListofBeneficiaries })
        break
      case "removeListofBeneficiaries":
        const modifiedLists = form.ListofBeneficiaries.find(
          (each, idx) => idx === key
        )
        handleRemoveBeneficiary(modifiedLists?.bi_id || 0, key)
        break
      case "beneficiariesList":
        setForm({
          ...form,
          ListofBeneficiaries: form.ListofBeneficiaries.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break
      default:
          null
      }
    }
  
     
    const handleFormSubmit = async () => {
        const isValid = validateForm();
  
      if (!isValid) {
        showMessage("Please fill in the required fields", "error", true)
        return;
      }
      switch (activeTab) {
        case 1:
          try {
            if (!userId) {
              showMessage("User ID is required", "error");
              break;
            }
        
            const url = id
              ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_investment_information`
              : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/investment_informations`;
        
            let hasErrors = false;
        
            // Validate form fields
            if (!form?.bs_account_number) {
              setErrors((prev) => ({
                ...prev,
                bs_account_number: "Please enter the account number",
              }));
              hasErrors = true;
            }
        
            if (!form?.bs_financial_institution_name) {
              setErrors((prev) => ({
                ...prev,
                bs_financial_institution_name:
                  "Please enter the financial institution name",
              }));
              hasErrors = true;
            }
        
            if (!form?.bs_account_type) {
              setErrors((prev) => ({
                ...prev,
                bs_account_type: "Please select the account type",
              }));
              hasErrors = true;
            }
        
            if (!form?.bs_amount) {
              setErrors((prev) => ({
                ...prev,
                bs_amount: "Please enter the account amount",
              }));
              hasErrors = true;
            }
        
            if (!form?.bs_currency) {
              setErrors((prev) => ({
                ...prev,
                bs_currency: "Please select a currency",
              }));
              hasErrors = true;
            }
        
            // Exit if validation fails
            if (hasErrors) {
              break;
            }
        
            // Prepare data
            const data = {
              bs_id: id || banksyncid,
              bs_uid: userId,
              bs_account_number: form.bs_account_number,
              bs_financial_institution_name: form.bs_financial_institution_name,
              bs_account_type: form.bs_account_type,
              bs_account: form.bs_account,
              bs_accountHolderName1: form.bs_accountHolderName1,
              bs_accountHolderName2: form.bs_accountHolderName2,
              bs_amount: form.bs_amount,
              bs_currency: form.bs_currency,
              bs_dateOfUpdate: form.bs_dateOfUpdate,
            };
        
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            };
        
            setApiStatus({ ...apiStatus, inProgress: true });
        
            // Make the API call
            const response = await fetch(url, options, {});
            const addOrUpdateAssetInformation = await response.json();
        
            // Handle API response
            if (addOrUpdateAssetInformation?.success === true) {
              console.log("reached")
              setApiStatus({ ...apiStatus, inProgress: false });
        
              // Store bankSyncId after a successful API call
              const newBankSyncId = addOrUpdateAssetInformation.banksyncid;
              if (newBankSyncId) {
                localStorage.setItem("banksyncid", newBankSyncId);
              }
        
              // Navigate to the next tab
              setactiveTab(2);
              localStorage.setItem("tab", 2);
        
              // Fetch additional data if required
              await fetchBeneficiaryData();
        
              // Show success message
              showMessage(addOrUpdateAssetInformation.message, "success");
            } else {
              // Handle failure response
              showMessage(addOrUpdateAssetInformation?.message, "error", false);
              setApiStatus({ ...apiStatus, inProgress: false });
            }
          } catch (error) {
            console.error("Error in case 1:", error);
            showMessage("An unexpected error occurred", "error");
          }
          break;
        
        case 2:
            
           try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_beneficiari_info`
  
            const data = {
              bs_id: id || banksyncid,
              bs_uid: userId,
              listOfBeneficiaries: form.ListofBeneficiaries.map(
                eachBeneficiary => {
                  return {
                  bi_id: eachBeneficiary.bi_id || 0,
                  bs_beneficiariesName : eachBeneficiary.bs_beneficiariesName  || "",
                  bs_relationship: eachBeneficiary.bs_relationship || "",
                  bs_ownPercentage: eachBeneficiary.bs_ownPercentage || "",
                  }
                }
              ),
            }
  
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
  
            const response = await fetch(url, options, {})
  
            const addOrUpdateListOfBeneficiaries = await response.json()
  
            if (addOrUpdateListOfBeneficiaries?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })              
              showMessage(addOrUpdateListOfBeneficiaries.message, "success")
              setTimeout(() => {
                history("/investment_list")
              }, 3000)             
            } else {
              showMessage(addOrUpdateListOfBeneficiaries?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
        
          break
        default:
          break
      }
  
      if (!(id || banksyncid) && activeTab != 1) {
        setactiveTab(1)
        localStorage.setItem("tab", 1)
      }
    }
  
    const handleBlur = e => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value?.trim(),
        errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
      })
    }
    useEffect(() => {
      if (changed) {
        setErrors(prev => {
          const copy = JSON.parse(JSON.stringify(prev))
          delete copy[changed]
          return copy
        })
      }
    }, [changed]) // changed
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">FINANCIAL INSTITUTIONS</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Services</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/investment_list">Financial Institutions</Link>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(1)
                              } else {
                                activeTab > 1 && setactiveTab(1)
                              }
                              //activeTab > 1 && setactiveTab(1)
                              localStorage.setItem("tab", 1)
                            }}
                          >
                            <span className="number">1.</span> Account
                            Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={async () => {
                              if (id) {
                                setactiveTab(2)
                              } else {
                                activeTab > 2 && setactiveTab(2)
                              }

                              localStorage.setItem("tab", 2)
                              await fetchBeneficiaryData()
                            }}
                          >
                            <span className="number">2.</span>
                            Beneficiary Information
                          </NavLink>
                        </NavItem>                          
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          
                        <TabPane tabId={1}>
                          <Form>
                            <h4 className="page-title-box">
                              Account Information
                            </h4>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pro_asset_name"
                                    className="form-label"
                                  >
                                    Financial Institution Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.bs_financial_institution_name
                                        ? "invalid-inputfield form-control"
                                        : "form-control"
                                    }
                                    name="bs_financial_institution_name"
                                    id="bs_financial_institution_name"
                                    placeholder="Enter Finanical Institution Name"
                                    required
                                    onChange={e => {
                                      setChanged("bs_financial_institution_name")
                                      handleForm(e, "accountInformation")
                                    }}
                                    value={form.bs_financial_institution_name}
                                    onBlur={handleBlur}
                                  />
                                  {errors.bs_financial_institution_name && (
                                    <div className="invalid-input">
                                      {errors.bs_financial_institution_name}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="bs_account_number"
                                    className="form-label"
                                  >
                                    Account Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.bs_account_number
                                        ? "invalid-inputfield form-control"
                                        : "form-control"
                                    }
                                    name="bs_account_number"
                                    id="bs_account_number"
                                    placeholder="Account Number"
                                    required
                                    onChange={e => {
                                      setChanged("bs_account_number")
                                      handleForm(e, "accountInformation")
                                    }}
                                    value={form.bs_account_number}
                                    onBlur={handleBlur}
                                  />
                                  {errors.bs_account_number && (
                                    <div className="invalid-input">
                                      {errors.bs_account_number}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="bs_account_type"
                                    className="form-label"
                                  >
                                    Account Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="bs_account_type"
                                    name="bs_account_type"
                                    required
                                    onChange={e => {
                                      setChanged("bs_account_type")
                                      handleForm(e, "accountInformation")
                                    }}
                                    value={form.bs_account_type}
                                  >
                                    <option value="">
                                      Select Account Type
                                    </option>
                                    <option value="SavingsAccount">
                                      Savings Account
                                    </option>
                                    <option value="CheckingAccount">
                                      Checking Account
                                    </option>
                                  </select>
                                  {errors.bs_account_type && (
                                    <div className="invalid-input">
                                      {errors.bs_account_type}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="bs_account"
                                    className="form-label"
                                  >
                                    Financial Institution Type{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="bs_account"
                                    name="bs_account"
                                    required
                                    onChange={e => {
                                      setChanged("bs_account")
                                      handleForm(e, "accountInformation")
                                    }}
                                    value={form.bs_account}
                                  >
                                    <option value="">
                                      Select Financial Institution Type
                                    </option>
                                    <option value="Bank">Bank</option>
                                  </select>
                                  {errors.bs_account && (
                                    <div className="invalid-input">
                                      {errors.bs_account}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="bs_amount"
                                    className="form-label"
                                  >
                                    Amount
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="bs_amount"
                                    id="bs_amount"
                                    placeholder="Enter Ammount"
                                    onChange={e =>
                                      handleForm(e, "accountInformation")
                                    }
                                    value={form.bs_amount}
                                  />
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="bs_currency"
                                    className="form-label"
                                  >
                                    Currency
                                  </label>
                                  <select
                                    className="form-select"
                                    id="bs_currency"
                                    name="bs_currency"
                                    onChange={e =>
                                      handleForm(e, "accountInformation")
                                    }
                                    value={form.bs_currency}
                                  >
                                    <option value="">Select Currency</option>
                                    <option value="INR">INR</option>
                                    <option value="USD">USD</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="bs_accountHolderName1"
                                    className="form-label"
                                  >
                                    Name Of Account Holder One
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="bs_accountHolderName1"
                                    id="bs_accountHolderName1"
                                    placeholder="Account Holder One"
                                    onChange={e =>
                                      handleForm(e, "accountInformation")
                                    }
                                    value={form.bs_accountHolderName1}
                                  />
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="bs_accountHolderName2"
                                    className="form-label"
                                  >
                                    Name Of Account Holder Two
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="bs_accountHolderName2"
                                    id="bs_accountHolderName2"
                                    placeholder="Account Holder Two"
                                    onChange={e =>
                                      handleForm(e, "accountInformation")
                                    }
                                    value={form.bs_accountHolderName2}
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">
                              List Of Beneficiaries
                            </h4>

                            <ListofBeneficiaries
                              form={form}
                              handleForm={handleForm}
                              proId={id || banksyncid}
                              activeTab={activeTab}
                            />
                          </Suspense>
                        </TabPane>

                          
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Button
                              type="button"
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                              onClick={() => {
                                // activeTab != handleFormSubmit()
                                toggleTab(activeTab - 1)
                              }}
                              disabled={activeTab === 1}
                            >
                              Previous
                            </Button>
                          </li>
                          <li
                            className={activeTab === 2 ? "next disabled" : "next"}
                          >
                            <Button
                              disabled={activeTab === 2}
                              type="button"
                              onClick={() => {
                               
                                const isFormValid = handleFormSubmit()
  
                                if (isFormValid) {
                                  toggleTab(activeTab + 1)
                                } else {
                               
                                  console.log("Form is invalid")
                                }
                              }}
                            >
                              Next
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {banksyncid || id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={"/investment_list"}
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        localStorage.removeItem("banksyncid")
                        localStorage.removeItem("tab")
                      }}
                    >
                      Cancel
                    </Link>
                    &nbsp; &nbsp;
                    {id ? (
                      <Link
                        to={`/shate_asset-wallet?id=${id || banksyncid}`}
                        className="btn btn-primary waves-effect waves-light me-1"
                      >
                        Share it with Friend or Family
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddInvestment
  