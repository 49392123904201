import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { userId } from "helpers/userId";
import { useFormik } from "formik";
import * as Yup from "yup";
import AlertMessage, { showMessage } from "components/alertmessages/Alertmessages";

const Changepassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const handleIsActive = () => {
    setIsActive(true);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter your new password")
        .matches(/[A-Z]/, "At least one capital letter")
        .matches(/[a-z]/, "At least one lowercase letter")
        .matches(/[0-9]/, "At least one number")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "At least one special character")
        .min(8, "Password must be at least 8 characters"),
    }),
    onSubmit: async (values) => {
      setError("");
      setSuccess("");

      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/change_password`;
        const data = { oldPassword, newPassword: values.password, userId };
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };

        const response = await fetch(url, options);
        const updatePassword = await response.json();

        if (updatePassword?.success) {
          showMessage(updatePassword.message, "success");
          setTimeout(() => {
            navigate("/personal-information");
          }, 3000);
        } else {
          showMessage(updatePassword.message, "error");
        }
      } catch (err) {
        setError("An error occurred while updating the password.");
      }
    },
  });

  const isCapital = /[A-Z]/.test(formik.values.password);
  const isLowercase = /[a-z]/.test(formik.values.password);
  const isNum = /[0-9]/.test(formik.values.password);
  const isSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(formik.values.password);
  const isMinLength = formik.values.password.length >= 8;

  return (
    <div className="page-content">
      <AlertMessage />
      <Container fluid>
        <div className="page-title-box">
          <h4 className="font-size-18">Change Password</h4>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="/">Account Info</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/">Change Password</a>
            </li>
          </ol>
        </div>

        <div className="col-xl-6">
          <div className="card">
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3 position-relative">
                      <label htmlFor="oldPassword" className="form-label">
                        Old Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="oldPassword"
                        id="oldPassword"
                        placeholder="Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                      />
                      {formik.errors.oldPassword && formik.touched.oldPassword && (
                        <div className="text-danger">{formik.errors.oldPassword}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3 position-relative">
                      <label htmlFor="newPassword" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="newPassword"
                        placeholder="New Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onClick={handleIsActive}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <div className="text-danger">{formik.errors.password}</div>
                      )}

                      {isActive && (
                        <div className="verify-password">
                          <div
                            className={isLowercase ? "password-valid" : "password-invalid"}
                          >
                            At least one lowercase letter
                          </div>
                          <div
                            className={isCapital ? "password-valid" : "password-invalid"}
                          >
                            At least one capital letter
                          </div>
                          <div
                            className={isNum ? "password-valid" : "password-invalid"}
                          >
                            At least one number
                          </div>
                          <div
                            className={isSpecialChar ? "password-valid" : "password-invalid"}
                          >
                            At least one special character
                          </div>
                          <div
                            className={isMinLength ? "password-valid" : "password-invalid"}
                          >
                            Minimum 8 characters long
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">{success}</div>}

                <div className="row">
                  <div className="col-xl-6">
                    <div className="card">
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light me-1"
                        >
                          Submit
                        </button>
                        &nbsp; &nbsp;
                        <Link
                          to="/personal-information"
                          className="btn btn-secondary waves-effect"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Changepassword;
