import React, { useState, useRef ,useEffect} from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Label,
  Button,
  Input,
} from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { BrowserMultiFormatReader } from "@zxing/library";

const QRCodeLogin = () => {
  document.title = "Upload QR Code to Login";

  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const [qrCode, setQrCode] = useState(null);
  const [status, setStatus] = useState("");
  const [qrOtp, setQrOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const codeReader = new BrowserMultiFormatReader();

  const validateQRCode = async (code) => {
    try {
      const [tokenPart, userIdPart] = code.split(", ");
      const token = tokenPart?.split(":")[1]?.trim();
      const userId = userIdPart?.split(":")[1]?.trim();
  
      if (!token || !userId) {
        setStatus("Invalid QR Code format. Please upload a valid QR Code.");
        return false;
      }
  
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/get_personal_info?user_id=${userId}`
      );
  
      if (response?.data?.success) {
        const personalInfo = response.data.data;
  
        if (personalInfo && personalInfo.tokenCode === token) {
          const Verify = {
            user_id: personalInfo.user_id,
            user_email: personalInfo.user_email,
            token_code: personalInfo.tokenCode,
            user_role: personalInfo.user_role,
            user_key: personalInfo.user_key,
          };
          handleLoginAndVerifyOtp(Verify, token);
          return true;
        } else {
          setStatus("User not found. Please check your QR Code or register.");
          return false;
        }
      } else {
        setStatus("User not found. Please check your QR Code or register.");
        return false;
      }
    } catch (error) {
      setStatus("Error validating QR Code. Please try again.");
      console.error("Validation error:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };
  
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          codeReader
            .decodeFromImage(image)
            .then(async (result) => {
              if (result?.text) {
                setQrCode(result.text);
                setStatus(""); 
                
                // Validate QR Code
                const isValid = await validateQRCode(result.text);
                if (!isValid) {
                  setStatus("User not found. Please upload a valid QR Code.");
                  setQrCode(null);
                }
              }
            })
            .catch((error) => {
              setQrCode(null);
              setStatus("Please upload a valid QR Code.");
              console.error("Decode error:", error);
            });
        };
  
        image.onerror = () => {
          setQrCode(null); 
          setStatus("Please upload a valid image file."); 
        };
  
        image.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };
  
  const handleLoginAndVerifyOtp = async (values, token) => {
    const loginUrl = `${process.env.REACT_APP_DOMAIN_URL}api/v1/qrlogin`;
    const loginData = {
      user_id : values.user_id,
      user_email : values.user_email,
      token_code : values.token_code,
      user_role : values.user_role,
      user_key : values.user_key
    };
  
    try {
      const loginOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      };
  
      const loginResponse = await fetch(loginUrl, loginOptions,{});
      const loginResult = await loginResponse.json();
    
      if (loginResult?.success === true) {
        localStorage.setItem("loginAt", new Date());
        localStorage.setItem(
          "userData",
          JSON.stringify(loginResult?.data || {})
        );
  
        const personalInfo = loginResult.data;
  
        const isOtpValid = await validateAndVerifyOtp(personalInfo.user, token);  
        if (isOtpValid) {
          localStorage.setItem("otp_status", 1);
          navigate("/dashboard");
        }
      } else {
        setApiStatus((prev) => ({ ...prev, inProgress: false }));
      }
    } catch (error) {
      console.error("Login Error:", error);
    }
  };
  
  const validateAndVerifyOtp = async (personalInfo, token) => {
    if (personalInfo?.tokenCode === token) {
      const verifyOtpUrl = `${process.env.REACT_APP_DOMAIN_URL}api/v1/verify_otp`;
  
      const otpData = {
        user_email: personalInfo.user_email,
        otp: personalInfo.user_otp,
      };
  
      try {
        const otpOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(otpData),
        };

        const otpResponse = await fetch(verifyOtpUrl, otpOptions);
        const otpVerifyResult = await otpResponse.json();
    
        if (otpVerifyResult?.success === true) {
          return true;
        } else {
          if (otpVerifyResult?.code === 2090) {
            navigate("/membership_expire");
          } else {
          }
          return false;
        }
      } catch (error) {
        console.error("Error during OTP verification:", error);
        return false;
      }
    } else {
      return false;
    }
  };
  

  useEffect(() => {
    if (qrOtp) {
    }
  }, [qrOtp]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">Verify QR Code</h5>
                  </div>
                </div>
                <CardBody className="p-4">
                  {status && (
                    <Alert color="danger" className="mt-3">
                      {status}
                    </Alert>
                  )}

                  <div className="text-center mb-3">
                    <Label className="form-label">Upload QR Code Image</Label>
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={handleFileUpload}
                      innerRef={fileInputRef}
                      disabled={loading}
                    />
                  </div>

                  { qrCode&& (
                    <Alert color="info" className="text-center">
                      <strong>QR Code Detected,Wait a Mooment...</strong>
                    </Alert>
                  )}

                  {loading && (
                    <div className="text-center mt-3">
                      <Alert color="info">Validating QR Code...</Alert>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 text-end">
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember it?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    Sign In here
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} SOF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QRCodeLogin;
