import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const VendorRequestServiceTable = () => {
  const [vendorrequestServices, setRequestServices] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_vendor_request_services`
      )
      const data = await response.json()
      setRequestServices({ ...vendorrequestServices, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/delete_request_services`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        Reqid: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setRequestServices({
          ...vendorrequestServices,
          data: vendorrequestServices?.data?.filter(each => each.Reqid !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Request Number",
        field: "ReqNo",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date",
        field: "ReqDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Subject",
        field: "ReqSubject",
        sort: "asc",
        width: 100,
      },
      {
        label: "User Name",
        field: "user_id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Read Status",
        field: "ResReadStatus",
        sort: "asc",
        width: 150,
      },
      {
        label: "Service Type",
        field: "Reqservice_type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Request Status",
        field: "ReqStatus",
        sort: "asc",
        width: 150,
      },
    ],
    rows: vendorrequestServices?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit_vendor_service_request?Reqid=${each.Reqid}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
          ReqNo: each?.ReqNo || "",
          ReqDate: each?.ReqDate || "",
          ReqSubject: each?.ReqSubject || "",
          user_id: `${each?.user_fname} ${each?.user_lname}` || "",          
          ResReadStatus: each?.ResReadStatus || "",
          Reqservice_type: each?.Reqservice_type || "",
          ReqStatus: each?.ReqStatus || "",
        }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Request Service list</CardTitle>
              <div className="col-12 d-flex">
                
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default VendorRequestServiceTable
