import React from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import WillQuestionTable from "components/DataTables/WillQuestionTable"

const WillQuestionList = () => { 

  document.title = "Coupon LIST | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <WillQuestionTable />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WillQuestionList

