import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const DocumentSearchTable = () => {
  const [documentsearch, setDocumentSeach] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/document_repository`
      )
      const data = await response.json()
      setDocumentSeach({ ...documentsearch, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document_vault`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
       // user_id: userData?.user?.user_id,
       doc_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setDocumentSeach({
          ...documentsearch,
          data: documentsearch?.data?.filter(each => each.doc_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "List of Documents",
        field: "docname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Document Type",
        field: "doctype",
        sort: "asc",
        width: 270,
      },
      {
        label: "Document Sub Type",
        field: "subtype",
        sort: "asc",
        width: 200,
      },
      {
        label: "Country",
        field: "Country",
        sort: "asc",
        width: 100,
      }, 
      {
        label: "Region",
        field: "Region",
        sort: "asc",
        width: 100,
      },      
      {
        label: "Select",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: documentsearch?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        docname: each?.doc_name || "",
          doctype: each?.doc_type || "",        
          subtype: each?.doc_subtype || "",
          Country: each?.country || "",
          Region:  each?.region || "",
        action:
        (
            <Link
              to={`/make_document_view?id=${each.doc_id}`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <AlertMessage />

      

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DocumentSearchTable
