import React, { useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody } = require("reactstrap")
import "../../styles/datatables.scss"

const SendFamilyRequestTable = ({ userData, handleSelectedUser }) => {
  const handleFormSubmit = async each => {
    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/send_family_request`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         to_uid: each.user_id,
    //         by_uid: userId,
    //       }),
    //     }
    //   )
    //   const data = await response.json()
    //   if (response.status === 200) {
    //     setSendFamilyRequest(data)
    //     showMessage(data.message, "success")
    //   } else {
    //     showMessage(data.message, "error")
    //   }
    // } catch (e) {}
    handleSelectedUser(each)
  }
  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 200,
      },
      {
        label: "Country",
        field: "contry",
        sort: "asc",
        width: 100,
      },
      {
        label: "Active Type",
        field: "active",
        sort: "asc",
        width: 150,
      },
    ],
    rows: userData.map((each, index) => {
      return {
        sno: index + 1,
        name: each?.full_name || "",
        city: each?.city || "",
        contry: each?.country || "",
        active: (
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleFormSubmit(each)}
          >
            Select
          </button>
        ),
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SendFamilyRequestTable
