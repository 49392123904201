
import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"


const CouponTable = () => {

  const [coupon, setCoupon] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/coupon_list`
      )
      const data = await response.json()
      setCoupon({ ...coupon, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_coupon_list`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setCoupon({
          ...coupon,
          data: coupon?.data?.filter(each => each.id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 270,
      },
      {
        label: "Coupon Code",
        field: "coupon_code",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Discount",
        field: "coupon_amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Currency",
        field: "currency",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: coupon?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add_coupon?id=${each.id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
          coupon_code: each?.coupon_code || "",
          amount: each?.amount || "",  
          coupon_amount: each?.coupon_amount || "",  
          currency: each?.currency || "",        
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.id &&
                  window.confirm(
                    `Pls confirm deletion of ${each.coupon_code}?`
                  ) &&
                  handleRemoveRole(each.id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),  
  }

  return (
    <React.Fragment>
      <Row>
      <Col className="col-12">
          <div className="page-title-box">
            <h4 className="font-size-18">COUPONS LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Coupons List</a>
              </li>
            </ol>
          </div>
        
          <Card>
            <CardBody>
              
             
              <div className="col-12">
                <Link
                  to="/add_coupon"
                  className="btn btn-danger my-2"
                  type="button"
                >
                  Add Coupon
                </Link>
              </div>

              <MDBDataTable responsive bordered data={data} noBottomColumns={true} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CouponTable
