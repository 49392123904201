import axios from "axios"

export const FileUpload = async selectedFiles => {
  const formData = new FormData()
  for (const file of selectedFiles) {
    formData.append("files", file)
  }

  try {
    const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/upload`;
    const response = await axios.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    console.log(response);
    return response?.data
  } catch (error) {
    console.error("Error uploading files:", error)
  }
}
