import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddCustomerInfo = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,    
  })

  const [inititalState, setInitialState] = useState({
    user_fname: "",
    user_lname: "",
    user_name: "",
    user_email: "",
    user_pass: "",  
    user_role: "",  
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
      user_email: Yup.string().email().required("Please Enter Your Email"),
      user_pass: Yup.string()
        .required("Please Enter Your Password")
        .matches(/[A-Z]/, "Atleast one Capital letter")
        .matches(/[a-z]/, "Atleast one lower letter")
        .matches(/[0-9]/, "Atleast one number")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Atleast one special character")
        .min(8, "Password minimum of 8 characters"),
        user_fname: Yup.string().required("Please Enter First Name"),
        user_lname: Yup.string().required("Please Enter Llast Name"),
        user_role: Yup.string().required("Please process type"),
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url =  `${process.env.REACT_APP_DOMAIN_URL}api/v1/signUpByAdmin`

      const data = {
        user_id: id || 0,
        user_fname: values.user_fname,
        user_lname: values.user_lname,
        user_name: values.user_name,        
        user_email: values.user_email, 
        user_pass: values.user_pass,
        user_role: values.user_role,        
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const {result } = await response.json()

        if (result?.success === true) {
          showMessage(result.message, "success")
          setTimeout(() => {
            history("/customers_list")
          }, 3000)
        } else {
          showMessage("Adding User failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })



 

  document.title = "Add User | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
          <Row>
            <Col xs="12" className="m-6">
               <div className="page-title-box">
                  <h4 className="font-size-18"> {id ? "EDIT USER" : "ADD USER"} </h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/">Admin</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/">  {id ? "Edit User" : "Add User"} </a>
                    </li>
                  </ol>
                </div>    
              
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      
                      <Row>
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="user_fname">
                            First Name
                            </Label>
                            <Input
                              name="user_fname"
                              className="form-control"
                              placeholder="Enter First Name"
                              type="text"
                              id="user_fname"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_fname || ""}
                            />
                          </div>
                        </Col>
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="user_lname">
                            Last Name
                            </Label>
                            <Input
                              name="user_lname"
                              className="form-control"
                              placeholder="Enter Last Name"
                              type="text"
                              id="user_lname"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_lname || ""}
                            />
                          </div>
                        </Col>
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="user_name">
                            User Name
                            </Label>
                            <Input
                              name="user_name"
                              className="form-control"
                              placeholder="Enter User Name"
                              type="text"
                              id="user_name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_name || ""}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        
                        <Col xl="4">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="user_email"
                            >
                              Email ID
                            </Label>
                            <Input
                              name="user_email"
                              className="form-control"
                              placeholder="Enter Email id"
                              type="text"
                              id="user_email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_email || ""}
                            />
                          </div>
                        </Col>

                        <Col xl="4">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="user_pass"
                            >
                              Password
                            </Label>
                            <Input
                              name="user_pass"
                              className="form-control"
                              placeholder="Enter Password"
                              type="text"
                              id="user_pass"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_pass || ""}
                            />
                          </div>
                        </Col>
                      
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="user_role">
                              Process Name
                            </Label>
                            <Input
                              name="user_role"
                              className="form-control"
                              type="select"
                              id="user_role"
                              onChange={validation.handleChange}
                              value={validation.values.user_role}
                            >
                              <option value="">Select</option>
                              <option value="0">Customer</option>
                              <option value="1">Admin</option>
                              <option value="2">Operations</option>
                              <option value="3">Business Partner</option>
                            </Input>
                          </div>
                        </Col>                      
                      </Row>

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"} 
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/customers_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCustomerInfo
