import * as React from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useState } from "react"
import { SyncLoader } from "react-spinners"
import { v4 as uuid } from 'uuid'
import SuccessPayment from "../../assets/images/purchase-success.png"
import FailedPayment from "../../assets/images/purchase-fail.png"
import { toast } from 'react-toastify'

const PaymentFailed = ({ status }) => {
	const [loading, setLoading] = useState(false)

	const { uid, price, coupon_id, coupon_code, currency_code, txn_id, checkout_session_id } = useParams()

    const navigate = useNavigate()

	const updatesuccess = async () => {
		setLoading(true);
		
		try {
			const { data } = await axios.post(`${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/Payments/save`, {
				uid,
				item_price_currency: currency_code,
				item_number: coupon_id,
				item_name: coupon_code,
				item_price: price,
				paid_amount: status === "success" ? price : 0,
				paid_amount_currency: currency_code,
				txn_id,
				checkout_session_id,
				payment_status: status
			});
	
			if (data.success) {
				setLoading(false);
			} else {
				toast.warn("Something went wrong. Please try again.");
				navigate("/dashboard");
			}
		} catch (error) {
			setLoading(false);
	
			if (error.response) {
				toast.error(`Error: ${error.response.data.message || 'Something went wrong!'}`);
			} else if (error.request) {
				toast.error("Network error: Unable to reach the server. Please try again later.");
			} else {
				toast.error("An unexpected error occurred.");
			}
	
			navigate("/dashboard");
		}
	}
	

	useEffect(() => {
		updatesuccess()
	}, [status])

	return (
		<>
			<div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white" }} className="app-page-thankyou">
				<div className="app-page-thankyou-wrapper">
					{loading ? (
						<div>
							<SyncLoader color="#ff9774" />
						</div>
					) : (
						<div className="app-page-thankyou-card">
							<img
								style={{ width: "400px" }}
								className="app-page-thankyou-card-image"
								src={status === "success" ? SuccessPayment : FailedPayment}
							/>
							{status === "success" ? <h1>Thankyou</h1> : <h1>Sorry..</h1>}
							{status === "success" ? <p>Your payment is successful</p> : <p>We couldn't complete your payment</p>}
							<div><strong>Transaction:</strong> {txn_id}</div>
							<div><strong>Session:</strong> {checkout_session_id}</div>
							<div className="app-page-thankyou-card-btn">
								<Link to="/dashboard">
									<button
										style={{ marginTop: "30px", border: "none", borderRadius: "4px", backgroundColor: "#A5B68D", color: "white", padding: "10px 18px" }}
									>
										{' '}
										Go to dashboard{' '}
									</button>
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
export default PaymentFailed
