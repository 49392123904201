import WillDocumentListTable from "components/DataTables/WillDocumentListTable"
import React from "react"
import { Container } from "reactstrap"

const GenerateWillDocumentsList = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">WILL DOCUMENT LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Add Will</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Will Document List</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <WillDocumentListTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GenerateWillDocumentsList
