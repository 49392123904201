import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
	showMessage,
} from "components/alertmessages/Alertmessages"

const RequestServiceTable = () => {
	const [requestServices, setRequestServices] = useState({ data: [] })

	const fetchData = async () => {
		const user_id =
			JSON.parse(localStorage.getItem("userData")).user.user_id || 0
		const response = await fetch(
			`${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_request_services?user_id=${user_id}`
		)
		const data = await response.json()
		setRequestServices({ ...requestServices, data: data?.data || [] })
	}

	useEffect(() => {
		fetchData()
	}, [])

	const handleRemoveRole = async id => {
		try {
			const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/delete_request_services`
			const userData = JSON.parse(localStorage.getItem("userData"))
			const data = {
				user_id: userData?.user?.user_id,
				Reqid: id,
			}
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}

			const response = await fetch(url, options, {})
			const removeData = await response.json()

			if (removeData?.success === true) {
				setRequestServices({
					...requestServices,
					data: requestServices?.data?.filter(each => each.Reqid !== id),
				})

				showMessage(removeData.message, "success")
			} else {
				showMessage(removeData.message, "error", false)
			}
		} catch (error) {
			showMessage(removeData.message, "error", false)
		}
	}

	const data = {
		columns: [
			{
				label: "S. No.",
				field: "sno",
				sort: "asc",
				width: 150,
			},
			{
				label: "Edit",
				field: "edit",
				sort: "asc",
				width: 150,
			},
			{
				label: "Request Number",
				field: "ReqNo",
				sort: "asc",
				width: 270,
			},
			{
				label: "Date",
				field: "ReqDate",
				sort: "asc",
				width: 150,
			},
			{
				label: "Subject",
				field: "ReqSubject",
				sort: "asc",
				width: 100,
			},
			{
				label: "Read Status",
				field: "ReadStatus",
				sort: "asc",
				width: 150,
			},
			{
				label: "Payment Status",
				field: "Pay_status",
				sort: "asc",
				width: 150,
			},
			{
				label: "Request Status",
				field: "ReqStatus",
				sort: "asc",
				width: 150,
			},
		],
		rows: requestServices?.data?.map((each, index) => {
			return {
				sno: <div>{index + 1} </div>,
				edit:
					(
						<Link
							to={`/edit_request_service?Reqid=${each.Reqid}&type=0`}
							className="btn btn-outline-primary btn-sm edit"
							title="Edit"
						>
							<i className="fas fa-pencil-alt"></i>
						</Link>
					) || "",
				ReqNo: each?.ReqNo || "",
				ReqDate: each?.ReqDate || "",
				ReqSubject: each?.ReqSubject || "",
				ReadStatus: each?.ReadStatus || "",
				Pay_status: each?.Pay_status || "",
				ReqStatus: each?.ReqStatus || "",
			}
		}),
	}

	return (
		<React.Fragment>
			<AlertMessage />
			<Row>
				<Col className="col-12">
					<Card>
						<CardBody>
							<CardTitle className="h4">Request Service list</CardTitle>
							<div className="col-12 d-flex">
								<div>
									<Link
										to="/add_request_service"
										className="btn btn-danger my-2"
										type="button"
									>
										Add Request Service
									</Link>
								</div>
							</div>
							<MDBDataTable
								responsive
								bordered
								data={data}
								noBottomColumns={true}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}

export default RequestServiceTable
