import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"

const WillDocumentListTable = () => {
  const [willlist, setWillList] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_will_list?wl_uid=${userId}`
      )

      const data = await response.json()
      return {
        ...each,
        wl_type_of_document: each.wl_type_of_document,
        wl_name_of_document: each.wl_name_of_document,
      }
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/delete_asset_wallet`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        wl_uid: userData?.user?.wl_uid,
        wl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setWillList({
          ...willlist,
          data: willlist?.data?.filter(each => each.wl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Simple Will",
        field: "simple_will",
        sort: "asc",
        width: 150,
      },
      {
        label: "GDF Power of Attorney",
        field: "gdf_power_of_attorney",
        sort: "asc",
        width: 270,
      },
      {
        label: "Document Name",
        field: "document_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Healthcare Directive",
        field: "healthcare_directive",
        sort: "asc",
        width: 150,
      },
      {
        label: "Created On",
        field: "created_on",
        sort: "asc",
        width: 150,
      },
    ],
    rows: willlist?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add-will?id=${each.wl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        wl_type_of_document: each?.wl_type_of_document,
        wl_name_of_document: each?.wl_name_of_document,
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.wl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.pro_asset_name}?`
                  ) &&
                  handleRemoveRole(each.wl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Will Document List</CardTitle>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default WillDocumentListTable

{
  /*    

export const ListOfExistingOwners = ({ proId, activeTab }) => {
  const [listOfOwners, setListOfOwners] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/owners_list?pro_id=${proId}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()

        const decryptedData = data?.data?.map(each => {
          return {
            ...each,
            pol_fname: decryptData(each.pol_fname) || "",
            pol_lname: decryptData(each.pol_lname) || "",
            pol_relitionship: decryptData(each.pol_relitionship) || "",
            pol_persant: decryptData(each.pol_persant) || "",
          }
        })

        setListOfOwners({ ...listOfOwners, data: decryptedData || [] })
      } catch (err) {}
    }

    fetchData()
  }, [proId, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_asset_owner`
      const data = {
        pol_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfOwners({
          ...listOfOwners,
          data: listOfOwners?.data?.filter(each => each.pol_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "% of ownership",
        field: "ownership",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: listOfOwners?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit-owner?id=${each.pol_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pol_fname} ${each?.pol_lname}`,
        relationship: each?.pol_relitionship,
        ownership: each?.pol_persant || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pol_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pol_fname} ${each?.pol_lname}?`
                  ) &&
                  handleRemoveRole(each.pol_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ListofExistingBeneficiaries = ({ proId, activeTab }) => {
  const [ListofBeneficiaries, setListofBeneficiaries] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/beneficiaries_list?pro_id=${proId}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()

        const decryptedData = data?.data?.map(each => {
          return {
            ...each,
            pbl_name: decryptData(each.pbl_name) || "",
            pbl_relitionship: decryptData(each.pbl_relitionship) || "",
            pbl_persant: decryptData(each.pbl_persant) || "",
          }
        })

        setListofBeneficiaries({
          ...ListofBeneficiaries,
          data: decryptedData || [],
        })
      } catch (err) {}
    }

    fetchData()
  }, [proId, activeTab])

  const handleRemoveRole = async pbl_id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_beneficiaries`
      const data = {
        pbl_id: pbl_id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Relationship",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "% of ownership",
        field: "ownership",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofBeneficiaries?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/?id=${each.pbl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pbl_name}`,
        relationship: each?.pbl_relitionship,
        ownership: each?.pbl_persant || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pbl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pbl_name}?`
                  ) &&
                  handleRemoveRole(each.pbl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const ListofExistingDocuments = ({ proId, activeTab }) => {
  const [ListofDocuments, setListofDocuments] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/document_list?pro_id=${proId}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()

        const decryptedData = data?.data?.map(each => {
          return {
            ...each,
            pdl_document_name: decryptData(each.pdl_document_name) || "",
            pdl_tyep_document: decryptData(each.pdl_tyep_document) || "",
            pol_relitionship: each.pdl_image || "",
          }
        })

        setListofDocuments({ ...ListofDocuments, data: decryptedData || [] })
      } catch (err) {}
    }

    fetchData()
  }, [proId, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document`
      const data = {
        pdl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListofDocuments({
          ...ListofDocuments,
          data: ListofDocuments?.data?.filter(each => each.pdl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name of the document",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Type of Document",
        field: "relationship",
        sort: "asc",
        width: 200,
      },
      {
        label: "Images",
        field: "Image",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListofDocuments?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add-asset-wallet?id=${each.pdl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.pdl_document_name}`,
        relationship: each?.pdl_tyep_document,
        Image: each?.pdl_image || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.pdl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.pdl_document_name}?`
                  ) &&
                  handleRemoveRole(each.pdl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
*/
}
