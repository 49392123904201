import axios from "axios"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"



const HealthcareDownloadDocx = () => {
    const [loading, setLoading] = useState(false)

    const location = useLocation()
    const history = useNavigate()

    // Create a URLSearchParams object from the location's search property
    const queryParams = new URLSearchParams(location.search)

    // Get specific query parameters
    const gal_id = queryParams.get('gal_id')
    const type = queryParams.get('type')

    const updateSuccess = async () => {
        setLoading(true)

        const { data } = await axios.post(`${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/healthcare_directive_generated_will`, {
            gal_id
        })

        if (data.success) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}uploads/healthcare-directive-will-${gal_id}.docx`
            
           // const newTab = window.open();

            // Make an Axios GET request to fetch the file as a blob
            const response = await axios.get(url, {
                responseType: 'blob', // Important: this tells Axios to return a Blob
            })

            // Create a new Blob object using the response data
            const blob = new Blob([response.data], { type: response.data.type })

            // Create a download link and trigger the download
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob) // Create an object URL from the Blob
            link.setAttribute('download', `healthcare-directive-will-${gal_id}.docx`) // Set the download attribute with the desired file name
            document.body.appendChild(link)
            link.click() // Trigger the download

            setTimeout(() => {
                history("/generated_will_documet_list")
              }, 3000)

            window.location.href = '/generated_will_documet_list';

            // Cleanup
            link.remove()
            window.URL.revokeObjectURL(link.href) // Release the Blob URL
        }
        else {
            toast.warn("Something went wrong")
        }

        setLoading(false)
    }

    useEffect(() => {
        updateSuccess()
    }, [])

    return (
        <div>Downloading...</div>
    )
}

export default HealthcareDownloadDocx
