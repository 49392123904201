import CryptoJS from "crypto-js"

const encryptionKey = `MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGj/T0RssmtSAXjprWxpr2Q0QJOK
+izzv+7AGZLNMP0uNc0iwr4KCSJe+5f4sakTQ4HdPuK23srO70SBcYDKRyspJGpE
ZOBRrngx1XmBa8LLiT9GWUmmRz4bgn22ANLFkGDiEXhlv3fsBEjxfTL7d7U7Q9fJ
98sw+pZL+gXkrTWXAgMBAAE=`

// Function to encrypt data
export const encryptData = data => {
  const ciphertext = data
    ? CryptoJS.AES.encrypt(data, encryptionKey).toString()
    : ""
  return ciphertext
}

// Function to decrypt data
export const decryptData = ciphertext => {
  try {
    if (!ciphertext) {
      return ""
    }

    const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey)
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)

    return decryptedData
  } catch (error) {
    console.error("Error decrypting data:", error)
    return ""
  }
}
