import React, { useEffect, useState } from "react"
import { useLocation, useNavigate  } from "react-router-dom"
import { Container } from "reactstrap"
import { updateNotificationView } from "./UpdateNotificationView"
import { userId } from "helpers/userId"
import { showMessage } from "components/alertmessages/Alertmessages"

const initialFormData = {
  date: "",
  notify_type: "",
  content: "",
}

const FamilyRequest = () => {
  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  const [form, setForm] = useState(initialFormData)
  const history = useNavigate()

  useEffect(() => {
    if (id) {
      updateNotificationView(id)
    }
  }, [id])

  async function fetchNotificationData() {
    try {
      const notificationsData = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/notifications/get_notifications?user_id=${userId}&id=${id}`
      )

      const resultData = await notificationsData.json()
      if (resultData.success && resultData?.data?.length) {
        const data = resultData.data[0]
        setForm({
          ...form,
          date: data.notify_date,
          content: data.content,
          notify_type: data.notify_type,
          notification_id: data.id,
          notify_view: data.notify_view,
          notify_status: data.notify_status,
          notify_name: data.notify_name,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchNotificationData()
    }

    fetchData()
  }, [])

  const handleSubmitRelation = async () => {
    if (form.notification_id && form.notify_status == "No") {
      try {
        const data = {
          notification_id: form.notification_id,
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/notifications/update_account_info`,
          options
        )

        const resultData = await response.json()

        if (resultData.success) {
          showMessage(resultData.message, "success")
          setTimeout(() => {
            history("/notifications")
          }, 3000)
        } else {
          showMessage(resultData.message, "error", false)
        }
      } catch (error) {
        showMessage(error.message, "error", false)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="page-title-box">
            <h4 className="font-size-18">FAMILY REQUEST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Family Request</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Notifications View </h4>
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>View</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Date of Notification</th>
                      <td>{form.date || "NA"}</td>
                    </tr>
                    <tr>
                      <th scope="row">Type of Information</th>
                      <td>{form.notify_type}</td>
                    </tr>

                    <tr>
                      <th scope="row">Content</th>
                      <td>{form.content}</td>
                    </tr>
                    
                    <tr>
                      <th scope="row">Action Type</th>
                      <td>                 

                            {form.notify_name === '' ? (
                            // Show alternate content or nothing if notify_name is empty
                            <>
                              <input
                                type="submit"
                                className={`btn ${
                                  form.notify_status === "Yes"
                                    ? "btn-secondary"
                                    : "btn-primary"
                                } waves-effect mx-4`}
                                name=""
                                value={form.notify_status === "Yes" ? "Relationship Accepted" : "Relationship Accept"}
                                onClick={handleSubmitRelation}
                              />
                              <input
                                type="submit"
                                className="btn btn-secondary waves-effect mx-4"
                                name="Ignore_Relation"
                                value="Ignore Relationship"
                              />
                            </>
                          ) : (
                            //  Show buttons if notify_name is not empty
                            <p>{form.notify_name}</p>
                          )}

                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FamilyRequest
