import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddCoupon = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,    
  })

  const [inititalState, setInitialState] = useState({
    coupon_code: "",
    amount: "",
    coupon_amount: "",
    currency: "",
    
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
        coupon_code: Yup.string().required("Please Enter Coupon Code."),
      //  amount: Yup.string().required("Please Enter amount."),
        //coupon_amount: Yup.string().required("Please Enter Coupon Discount."),
        // currency: Yup.string().required("Please Currency."),
    
      
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/updateCoupon`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/addCoupon`

      const data = {
        id: id || 0,
        coupon_code: values.coupon_code,  
        amount: values.amount,  
        coupon_amount: values.coupon_amount,  
        currency: values.currency,                    
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addCouponData = await response.json()

        if (addCouponData?.success === true) {
          showMessage(addCouponData.message, "success")
          setTimeout(() => {
            history("/coupon_list")
          }, 3000)
        } else {
          showMessage("Adding Coupon failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/coupon_list?id=${
            id || 0
          }`
        )
        const data = await response.json()
        const Coupondata = data?.data[0] || ""
        if (Coupondata) {
          let reInitialValues = { ...inititalState }
          reInitialValues.id = Coupondata.id || ""
          reInitialValues.coupon_code = Coupondata.coupon_code || ""
          reInitialValues.amount = Coupondata.amount || ""
          reInitialValues.coupon_amount = Coupondata.coupon_amount || ""
          reInitialValues.currency = Coupondata.currency || ""
         
          setApiStatus({
            ...apiStatus,            
            id: [Coupondata.id],
          })

          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

 

  document.title = "Add Coupon | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
         
          <Row>
            <Col xs="12" className="m-6">
            <div className="page-title-box">
            <h4 className="font-size-18"> {id ? "EDIT COUPON" : "ADD COUPON"} </h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/"> {id ? "Edit Coupon" : "Add Coupon"} </a>
              </li>
            </ol>
          </div>
              
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                                            

                      <Row>                       
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="coupon_code">
                            {id ? " Coupon Code" : " Coupon Code"}  
                            </Label>
                            <Input
                              name="coupon_code"
                              className="form-control"                              
                              type="text"
                              id="coupon_code"
                              onChange={validation.handleChange}                              
                              value={validation.values.coupon_code || ""}
                              required
                            />
                          </div>
                        </Col>
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="amount">
                            {id ? " Amount" : " Amount"}  
                            </Label>
                            <Input
                              name="amount"
                              className="form-control"                              
                              type="text"
                              id="amount"
                              onChange={validation.handleChange}                              
                              value={validation.values.amount || ""}
                              required
                            />
                          </div>
                        </Col>    
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="coupon_amount">
                            {id ? " Discount" : " Discount"}  
                            </Label>
                            <Input
                              name="coupon_amount"
                              className="form-control"                              
                              type="text"
                              id="coupon_amount"
                              onChange={validation.handleChange}                              
                              value={validation.values.coupon_amount || ""}
                              required
                            />
                          </div>
                        </Col>  
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="url_name">
                            {id ? " Currency" : " Currency"}  
                            </Label>
                            <select
                                className="form-select"
                                id="Currency"
                                name="Currency"
                                required
                                onChange={validation.handleChange}
                                value={validation.values.Currency}
                            > 
                            <option value="" selected="">Select Currency</option>
                            <option value="INR">INR</option>
                            <option value="USD">USD</option>                                                            
                            <option value="EURO">EURO</option> 
                            </select>
                          </div>
                        </Col> 
                                       
                      </Row>
                     

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"} 
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/coupon_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCoupon
