import { customAlphabet, urlAlphabet } from "nanoid"

export const randomId = (size = 12, caps = false) => {
  if (caps) {
    const nanoid = customAlphabet("1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ", size)

    return nanoid()
  }

  const nanoid = customAlphabet(urlAlphabet, size)
  return nanoid()
}
