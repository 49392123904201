import React from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const showMessage = (message, type, autoClose = 2500) => {
  /* Auto-close only for success messages */
  if (!toast.isActive(message) && message) {
    toast[type](message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: autoClose,
      limit: 1,
      draggable: true,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      toastId: message,
      theme: "light",
    })
  }
}

const AlertMessage = () => {
  return (
    <div className="App">
      <ToastContainer
        theme="colored"
        autoClose={3000}
        limit={1}
        position="top-center"
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        draggable
      />
    </div>
  )
}

export default AlertMessage
