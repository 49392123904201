import React, { useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody } = require("reactstrap")
import "../../../styles/datatables.scss"

const GetSofAssetTable = ({ userData, handleSelectedUser }) => {
  const handleFormSubmit = async each => {
    handleSelectedUser(each)
  }
  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 200,
      },
      {
        label: "Country",
        field: "contry",
        sort: "asc",
        width: 100,
      },
      {
        label: "Active Type",
        field: "active",
        sort: "asc",
        width: 150,
      },
    ],
    rows: userData.map((each, index) => {
      return {
        sno: index + 1,
        name: `${each?.ac_fname} ${each?.ac_lname}` || "",
        city: each?.ac_city || "",
        contry: each?.ac_country || "",
        active: (
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={() => handleFormSubmit(each)}
          >
            Select
          </button>
        ),
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GetSofAssetTable
