import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Redux
import { connect, useDispatch } from "react-redux"

// import { withRouter, Link } from "react-router-dom"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logoSm from "../../assets/images/soflogo-min.png"

const ResetPassword = props => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const [isActive, setisActive] = useState(false)
  const [form, setForm] = useState({ message: "" })

  const handleIsActive = () => {
    setisActive(true)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(/[A-Z]/, "Atleast one Capital letter")
        .matches(/[a-z]/, "Atleast one Lower letter")
        .matches(/[0-9]/, "Atleast one number")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Atleast one special character")
        .min(8, "Password minimum of 8 characters"),
    }),
    onSubmit: async values => {
      async function verify() {
        const splitDetails = props?.router?.location?.search
          ?.replace("?", "")
          ?.split("&")
        if (splitDetails.length > 1) {
          const tokenCode = splitDetails[0].split("=")[1]
          const email = splitDetails[1].split("=")[1]

          if (tokenCode && email) {
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/reset_password`
            const data = {
              user_email: email,
              user_token: tokenCode,
              password: values.password,
            }

            try {
              const options = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
              const response = await fetch(url, options, {})

              const verifyData = await response.json()

              if (verifyData?.success === true) {
                setForm({ ...form, message: verifyData.message })
              } else {
                alert(verifyData?.message || "")
              }
            } catch (err) {
              alert(err)
            }
          }
        }
      }

      verify()
    },
  })

  const capitalRegex = /[A-Z]/
  const lowerCaseLetter = /[a-z]/
  const numRegex = /[0-9]/
  const specialRegex = /[!@#$%^&*(),.?":{}|<>]/

  const isCapital = capitalRegex.test(validation.values.password)
  const isLowercase = lowerCaseLetter.test(validation.values.password)
  const isNum = numRegex.test(validation.values.password)
  const isSpecialChar = specialRegex.test(validation.values.password)
  const isMinLength = validation.values.password.length > 7

  document.title = "Forgot Password | SOF"

  useEffect(() => {
    async function verify() {
      let isValid = false
      const splitDetails = props?.router?.location?.search
        ?.replace("?", "")
        ?.split("&")
      if (splitDetails.length > 1) {
        const tokenCode = splitDetails[0].split("=")[1]
        const email = splitDetails[1].split("=")[1]

        if (tokenCode && email) {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/reset_password`
          const data = {
            user_email: email,
            user_token: tokenCode,
            verify: 1,
          }

          try {
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
            const response = await fetch(url, options, {})

            const verifyData = await response.json()

            if (verifyData?.success === true) {
              isValid = true
            } else {
              alert("Invalid operation")
              history("/login")
            }
          } catch (err) {
            alert(err)
          }
        }
      }

      if (!isValid) {
        history("/login")
      }
    }

    verify()
  }, [props?.router?.location?.pathname])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">
                      Reset Password
                    </h5>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  {props.forgetError && props.forgetError ? (
                    <Alert
                      color="danger"
                      style={{ marginTop: "13px" }}
                      className="mt-5"
                    >
                      {props.forgetError}
                    </Alert>
                  ) : null}
                  {props.forgetSuccessMsg ? (
                    <Alert
                      color="success"
                      style={{ marginTop: "13px" }}
                      className="mt-5"
                    >
                      {props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    className="mt-4"
                  >
                    {form.message && (
                      <h5 className="font-size-20 p-2">{form.message}</h5>
                    )}
                    {!form.message && (
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          New Password
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          id="userpassword"
                          className="form-control mb-3"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          onClick={handleIsActive}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {isActive && (
                          <div className="verify-password">
                            <div
                              className={
                                !isLowercase
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one lower letter
                            </div>
                            <div
                              className={
                                !isCapital
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one captital letter
                            </div>
                            <div
                              className={
                                !isNum ? "password-invalid" : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one Number
                            </div>
                            <div
                              className={
                                !isSpecialChar
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Atleast one Special Character
                            </div>
                            <div
                              className={
                                !isMinLength
                                  ? "password-invalid"
                                  : "password-valid"
                              }
                            >
                              {" "}
                              Minimum 8 charaters long
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {!form.message ? (
                      <div className="row  mb-0">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </Form>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Sign In here{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} SOF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ResetPassword)
)
