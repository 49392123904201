
import React, { useEffect, useState } from "react"
const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle ,Button,} = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import moment from 'moment';


const GeneratedWillDocumentListTable = () => {

  const [generatedDoc, setgeneratedDoc] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const user_id =
        JSON.parse(localStorage.getItem("userData")).user.user_id || 0
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_generated_will_info?user_id=${user_id}`
      )
      const data = await response.json()
      setgeneratedDoc({ ...generatedDoc, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_generated_will`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        gal_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setgeneratedDoc({
          ...generatedDoc,
          data: generatedDoc?.data?.filter(each => each.gal_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const generateSimpleWill = async id => {
    const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/simple_generated_will`;
          
    const data = {      
      gal_id: id,
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }

    const response = await fetch(url, options, {})

    const addOrUpdatenotesandinvoiceInformation = await response.json()

    if (addOrUpdatenotesandinvoiceInformation?.success === true) {
                   downloadURI(addOrUpdatenotesandinvoiceInformation.url);
      showMessage(addOrUpdatenotesandinvoiceInformation.message, "success")
    } else {
      showMessage(addOrUpdatenotesandinvoiceInformation?.message, "error", false)
      
    }

  }

  const downloadURI = (uri) => {
    const link = document.createElement('a');
   link.setAttribute('download', '');
   link.setAttribute('target', '_blank');
   link.href = uri;
   document.body.appendChild(link);
   link.click();
   link.remove();
    };

  const generatepowerofattorney = async id => {
    const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/power_of_attorney_generated_will`;
          
    const data = {      
      gal_id: id,
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }

    const response = await fetch(url, options, {})

    const addOrUpdatenotesandinvoiceInformation = await response.json()

    if (addOrUpdatenotesandinvoiceInformation?.success === true) {
                   downloadURIpower(addOrUpdatenotesandinvoiceInformation.url);
      showMessage(addOrUpdatenotesandinvoiceInformation.message, "success")
    } else {
      showMessage(addOrUpdatenotesandinvoiceInformation?.message, "error", false)
      
    }

  }

  const downloadURIpower = (uri) => {
    const link = document.createElement('a');
   link.setAttribute('download', '');
   link.setAttribute('target', '_blank');
   link.href = uri;
   document.body.appendChild(link);
   link.click();
   link.remove();
    };


    const generatehealthcare = async id => {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/healthcare_directive_generated_will`;
            
      const data = {      
        gal_id: id,
      }
  
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
  
      const response = await fetch(url, options, {})
  
      const addOrUpdatenotesandinvoiceInformation = await response.json()
  
      if (addOrUpdatenotesandinvoiceInformation?.success === true) {
                     downloadURIhealthcare(addOrUpdatenotesandinvoiceInformation.url);
        showMessage(addOrUpdatenotesandinvoiceInformation.message, "success")
      } else {
        showMessage(addOrUpdatenotesandinvoiceInformation?.message, "error", false)
        
      }
  
    }
  
    const downloadURIhealthcare = (uri) => {
      const link = document.createElement('a');
     link.setAttribute('download', '');
     link.setAttribute('target', '_blank');
     link.href = uri;
     document.body.appendChild(link);
     link.click();
     link.remove();
      };

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },      
      {
        label: "Simple Will",
        field: "simgplewill",
        sort: "asc",
        width: 200,
      },
      {
        label: "GDF Power of Attorney",
        field: "gdfattorney",
        sort: "asc",
        width: 100,
      },
      {
        label: "Healthcare Directive",
        field: "healthcare",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "createdOn",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: generatedDoc?.data?.map((each, index) => {
      
      const formattedDate = moment(each.gal_created_on).format('YYYY-MM-DD');

      return {
        sno: <div>{index + 1} </div>,
        simgplewill:
          ( 
            //<Button color="primary" onClick={() => generateSimpleWill(each.gal_id)}>
            // Simple Will
           //  </Button>             
            <Link
            to={`/simple_will?gal_id=${each.gal_id}&type=0`}
            className="btn btn-primary"
            title="Edit"
            >
            Simple Will
            </Link>
           ) || "",
          gdfattorney:
          (
            <Link
            to={`/gdf_attorney?gal_id=${each.gal_id}&type=0`}
            className="btn btn-primary"
            title="Edit"
          >
            GDF Power of Attorney
          </Link>

           // <Button color="primary" onClick={() => generatepowerofattorney(each.gal_id)}>
            // GDF Power of Attorney 
           // </Button>
           ) || "",          
          healthcare:
          (  

            <Link
							to={`/healthcare_doc?gal_id=${each.gal_id}&type=0`}
							className="btn btn-primary"
							title="Edit"
						>
							Healthcare Directive
						</Link>

            // <Button color="primary" onClick={() => generatehealthcare(each.gal_id)}>
            // Healthcare Directive 
           // </Button>
          ) || "",

          createdOn: formattedDate,          
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.gal_id &&
                  window.confirm(
                    `Are you sure do you want to delete Document?`
                  ) &&
                  handleRemoveRole(each.gal_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),  
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Generated Will Document List </CardTitle>
              <MDBDataTable responsive bordered data={data} noBottomColumns={true} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GeneratedWillDocumentListTable
