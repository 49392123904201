import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddRole = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,
    application: [],
    role_name: [],
  })

  const [inititalState, setInitialState] = useState({
    Process_Name: "",
    Mode: "",
    Role_Name: "",
    Role_Description: "",
    createBy: "",
    updateBy: "",
    updatedDate: "",
    createDate: "",
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
        Process_Name: Yup.string().required("Please Select Process Name"),
        Mode: Yup.string().required("Please Select Aplication"),
        Role_Name: Yup.string().required("Please Enter Role Name"),
        Role_Description: Yup.string().required("Please Enter Posible Values"),      
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/updateuserrole`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/adduserrole`

      const data = {
        Ur_id: id || 0,
        Process_Name: values.Process_Name,
        Mode: values.Mode,
        Role_Name: values.Role_Name,
        Role_Description: values.Role_Description,        
        
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addRole = await response.json()

        if (addRole?.success === true) {
          showMessage(addRole.message, "success")
          setTimeout(() => {
            history("/roll_list")
          }, 3000)
        } else {
          showMessage("Adding role failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/user_roles?Ur_id=${
            id || 0
          }`
        )
        const data = await response.json()
        const roleCollection = data?.data[0] || ""
        if (roleCollection) {
          let reInitialValues = { ...inititalState }

          reInitialValues.Process_Name = roleCollection.Process_Name || ""
          reInitialValues.Mode = roleCollection.Mode || ""
          reInitialValues.Role_Name = roleCollection.Role_Name || ""
          reInitialValues.Role_Description = roleCollection.Role_Description || ""         
          reInitialValues.createBy = roleCollection.Created_By || ""
          reInitialValues.updateBy = roleCollection.updated_by || ""
          reInitialValues.updatedDate = roleCollection.Mode || ""
          reInitialValues.createDate = roleCollection.created_Date || ""
          
          setApiStatus({
            ...apiStatus,
            application: [roleCollection.Applications],
            role_name: [roleCollection.Role_Name],
          })

          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

  useEffect(() => {
    if (validation.values.Process_Name) {
      const fetchApplicationName = async () => {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/role_types?type=1&name=${validation.values.Process_Name}`
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
        try {
          const response = await fetch(url, options, {})
          const application = await response.json()
          if (application?.success === true) {
            setApiStatus({
              ...apiStatus,
              application:
                application?.data?.map(each => each?.pal_application || "") ||
                [],
              role_name: [],
            })
          } else {
            showMessage(application?.message, "error", false)
          }
        } catch (err) {
          showMessage(err, "error", false)
        }
      }

      fetchApplicationName()
    } else {
      setApiStatus({ ...apiStatus, application: [], role_name: [] })
    }
  }, [validation.values])

  

  document.title = "Add Roles collection list | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
          <Row>
            <Col xs="12" className="m-6">
                <div className="page-title-box">
                  <h4 className="font-size-18"> {id ? "EDIT ROLE" : "ADD ROLE"} </h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/">User Roles</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/">  {id ? "Edit Role" : "Add Role"} </a>
                    </li>
                  </ol>
                </div>               
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      
                      <Row>
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="Process_Name">
                              Process Name
                            </Label>
                            <Input
                              name="Process_Name"
                              className="form-control"
                              type="select"
                              id="Process_Name"
                              onChange={validation.handleChange}
                              value={validation.values.Process_Name}
                            >
                              <option value="">Select</option>
                              <option value="Account Info">Account Info</option>
                              <option value="Services">Services</option>
                              <option value="Workspace">Workspace</option>
                              <option value="Platform">Platform</option>
                            </Input>
                          </div>
                        </Col>
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="Mode">
                              Application
                            </Label>
                            <Input
                              name="Mode"
                              className="form-control"
                              type="select"
                              id="Mode"
                              onChange={validation.handleChange}
                              value={validation.values.Mode}
                            >
                              <option value="">Select</option>
                              {apiStatus.application.map(each => (
                                <option value={each}>{each}</option>
                              ))}
                            </Input>
                          </div>
                        </Col>
                      </Row>
                      <Row> 
                        <Col xl="6">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="Role_Name">
                            Role Name
                            </Label>
                            <Input
                              name="Role_Name"
                              className="form-control"
                              placeholder="Enter Role"
                              type="text"
                              id="Role_Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Role_Name || ""}
                            />
                          </div>
                        </Col>
                        <Col xl="3">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="Role_Description"
                            >
                              Possible Values
                            </Label>
                            <Input
                              name="Role_Description"
                              className="form-control"
                              placeholder="Enter Possible Values"
                              type="text"
                              id="Role_Description"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Role_Description || ""}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"}
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/roll_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddRole
