import React, { useEffect } from "react"

const Timer = ({ resendOtp, seconds, setSeconds }) => {
  useEffect(() => {
    if (seconds === 0) {
      resendOtp()
    }
  }, [seconds])

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  return (
    <div>
      {seconds === 0 ? null : (
        <span>{`${minutes}:${
          remainingSeconds < 10 ? "0" : ""
        }${remainingSeconds}`}</span>
      )}
    </div>
  )
}

export default Timer
