import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const RoleTable = () => {
  const [roleList, setRoleList] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/user_roles`
      )
      const data = await response.json()
      setRoleList({ ...roleList, data: data?.data || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/delete_user_roles`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        user_id: userData?.user?.user_id,
        Ur_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      debugger
      const removeData = await response.json()

      if (removeData?.success === true) {
        setRoleList({
          ...roleList,
          data: roleList?.data?.filter(each => each.Ur_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }
  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Process Name",
        field: "Process_Name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Application",
        field: "Mode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Role Name",
        field: "Role_Name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Possible Values",
        field: "Role_Description",
        sort: "asc",
        width: 150,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: roleList?.data?.map((each, index) => {
      return {
        sno: (
          <div>
            {index + 1}{" "}            
          </div>
        ),
        edit: (
          <div>            
            <Link
              to={`/add_role?id=${each.Ur_id}`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </div>
        ),
        Process_Name: each?.Process_Name || "",
        Mode: each?.Mode || "",
        Role_Name: each?.Role_Name || "",
        Role_Description	: each?.Role_Description	 || "",       
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.Ur_id &&
                  window.confirm(
                    `Pls confirm deletion of ${each.Process_Name}?`
                  ) &&
                  handleRemoveRole(each.Ur_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }
  return (
    <React.Fragment>
      <AlertMessage />
      <Row>
        <Col className="col-12">
        <div className="page-title-box">
            <h4 className="font-size-18">ROLES LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">User Roles</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Roles List</a>
              </li>
            </ol>
          </div>
          <Card>
            <CardBody>
             
              <div className="col-12">
                <Link
                  to="/add_role"
                  className="btn btn-danger my-2"
                  type="button"
                >
                  Add Role 
                </Link>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RoleTable
