import AlertMessage, {
	showMessage,
} from "components/alertmessages/Alertmessages"
import { Container } from "reactstrap"
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, } from "react-router-dom"
import { loginUserData, userId } from "helpers/userId"
// import { loadStripe } from '@stripe/stripe-js'
import axios from "axios"

const initialFormDetails = {
	coupon: "",
}

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const UserMembership = () => {


	const [form, setForm] = useState(initialFormDetails)
	const [apiStatus, setApiStatus] = useState({ inProgress: false })

	const [errors, setErrors] = useState({})

	// history for navigation
	const history = useNavigate()

	// to get api params
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const id = searchParams.get("id")
	const type = searchParams.get("type")

	const [couponDetails, setCouponDetails] = useState(null)
	const [coupon, setCoupon] = useState('')
	const [activeScreen, setActiveScreen] = useState({ 1: '', 2: '', 3: '' })
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [sessionLoading, setSessionLoading] = useState(false)

	const navigate = useNavigate()


	const handleFormSubmit = async () => {
		setSessionLoading(true)
		const { data, status } = await axios.post("api/v1/customer/Payments/create-checkout", { 
			uid: userId, 
			coupon_id: couponDetails?.id, 
			amount: couponDetails && couponDetails.coupon_amount ? 20000 - (couponDetails.coupon_amount * 100) : 20000,
			coupon_code: couponDetails && couponDetails.coupon_code ? couponDetails.coupon_code : "Nocoupon" ,
			currency: couponDetails && couponDetails.currency ? couponDetails.currency : "USD"
		})
		
		window.location = data.session.url

		return

		try {
			const data = {
				user_id: userId,
				FirstName: firstName,
				LastName: lastName,
				coupon: coupon,
			}

			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			}

			const response = await fetch(
				`${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/add_membershep_details`,
				options
			)

			const resultData = await response.json()

			if (resultData.success) {
				showMessage(resultData.message, "success")
				setTimeout(() => {
					history("/asset-wallet")
				}, 3000)
			} else {
				showMessage(resultData.message, "error", false)
			}
		} catch (error) {
			showMessage(error.message, "error", false)
		}

	}


	const searchcoupon = async () => {
		if (coupon) {
			try {
				const notificationsData = await fetch(
					`${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/search_coupon?coupon=${coupon}`
				)

				const resultData = await notificationsData.json()

				if (resultData.success) {
					const data = resultData.data
					setCouponDetails(data)
					setActiveScreen({ 1: 'success', 2: '', 3: '' })
				} else {
					setActiveScreen({ 1: 'error', 2: '', 3: '' })
				}
			} catch (error) {
				console.log(error)
			}
		}
		else {
			setActiveScreen({ 1: 'success', 2: '', 3: '' })
		}
	}

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid={true}>
					<div className="page-title-box">
						<h4 className="font-size-18">Membership</h4>
						<ol className="breadcrumb mb-0">
							<li className="breadcrumb-item">
								<a href="/"></a>
							</li>
							<li className="breadcrumb-item">
								<a href="/">Membership</a>
							</li>
						</ol>
					</div>

					{!activeScreen['1'] && <div className="row">
						<div className="col-md-4">
							<div className="card">
								<div className="card-body">
									<div class="table-responsive">
										<table class="table mb-0 table">
											<tr>
												<td>Membership fees </td>
												<td>$ 200</td>
											</tr>
										</table>
									</div>

									<div className="col-md-12">
										<div className="mb-3 position-relative">
											<label htmlFor="user_fname" className="form-label">
												Add coupon Code
											</label>
											<input
												type="text"
												className="form-control"
												name="coupon"
												placeholder="Enter Coupon Code"
												onChange={(e) => setCoupon(e.target.value)}
											/>
											<p>
												Note: You don't have Coupon Please enter "Nocoupon"
											</p>

										</div>
									</div>

									<button
										type="button"
										className="btn btn-primary waves-effect waves-light me-1"
										onClick={searchcoupon}
									>
										Validate Coupon
									</button>
								</div>
							</div>
						</div>
					</div>}

					{activeScreen['1'] && activeScreen[2] !== 'success' && <div className="row">
						<div className="col-md-4">
							<div className="card">
								<div className="card-body">
									{activeScreen['1'] === 'error' ? <div class="h4 card-title">Please enter valid coupon!</div> : <div class="h4 card-title">Coupon Validated...</div>}
									{activeScreen['1'] === 'success' &&
										<>
											{couponDetails?.coupon_code === 'IMMFAM2024' && <div class="h4 card-title">Please enter introducing immediate family member!</div>}
											{couponDetails?.coupon_code === 'EXTFAM2024' && <div class="h4 card-title">Please enter introducing extended family member!</div>}
											<div class="h4 card-title">Introduced By</div>
											<div className="col-md-12">
												<div className="mb-3 position-relative">
													<label htmlFor="user_fname" className="form-label">
														First Name
													</label>
													<input
														type="text"
														className="form-control"
														name="first_name"
														placeholder="Enter First Name"
														onChange={(e) => setFirstName(e.target.value)}
													/>


												</div>
											</div>
											<div className="col-md-12">
												<div className="mb-3 position-relative">
													<label htmlFor="user_fname" className="form-label">
														Last Name
													</label>
													<input
														type="text"
														className="form-control"
														name="last_name"
														placeholder="Enter Last Name"
														onChange={(e) => setLastName(e.target.value)}
													/>


												</div>
											</div>
										</>
									}
									{activeScreen[1] === 'success' ? <button
										type="button"
										className="btn btn-primary waves-effect waves-light me-1"
										onClick={() => setActiveScreen({ 1: 'success', 2: 'success', 3: '' })}
									>
										Next
									</button> : <button
										type="button"
										className="btn btn-primary waves-effect waves-light me-1"
										onClick={() => setActiveScreen({ 1: '', 2: '', 3: '' })}
									>
										Back
									</button>}
								</div>
							</div>
						</div>
					</div>}

					{activeScreen[2] === 'success' && <div className="row">
						<div className="col-md-4">
							<div className="card">
								<div className="card-body">
									<div class="table-responsive">
										<table class="table mb-0 table">
											<tr>
												<td>Membership fees </td>
												<td>$ 200</td>
											</tr>
											<tr>
												<td>Coupon Code  {couponDetails?.coupon_code}  </td>
												<td> - $ {couponDetails ? couponDetails.coupon_amount : 0}</td>
											</tr>
											<tr>
												<td>Total</td>
												<td>$ {200 - parseInt(couponDetails ? couponDetails.coupon_amount : 0)} </td>
											</tr>
										</table>
									</div>
									<button
										type="button"
										className="btn btn-primary waves-effect waves-light me-1"
										onClick={handleFormSubmit}
									>
										Buy
									</button>
								</div>
							</div>
						</div>



					</div>}



				</Container>
			</div>
		</React.Fragment>
	)
}

export default UserMembership
