import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddRelation = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,    
  })

  const [inititalState, setInitialState] = useState({
    url_name: "",
    
     
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
        url_name: Yup.string().required("Please Enter Popup/Message No."),
     // region: Yup.string().required("Please Enter Popup Text"),
       // mal_rep: Yup.string().required("Please Enter Replacements"),
       // mal_process: Yup.string().required("Please Enter Process"),
       // mal_app: Yup.string().required("Please Enter Application"),
      
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/updateRelation`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/addRelation`

      const data = {
        url_id: id || 0,
        url_name: values.url_name,                    
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addRelationData = await response.json()

        if (addRelationData?.success === true) {
          showMessage(addRelationData.message, "success")
          setTimeout(() => {
            history("/relation_list")
          }, 3000)
        } else {
          showMessage("Adding Relation failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/relation_list?url_id=${
            id || 0
          }`
        )
        const data = await response.json()
        const Relationdata = data?.data[0] || ""
        if (Relationdata) {
          let reInitialValues = { ...inititalState }
          reInitialValues.url_id = Relationdata.url_id || ""
          reInitialValues.url_name = Relationdata.url_name || ""
         
          setApiStatus({
            ...apiStatus,            
            url_id: [Relationdata.url_id],
          })

          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

 

  document.title = "Add Relations | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
         
          <Row>
            <Col xs="12" className="m-6">
            <div className="page-title-box">
            <h4 className="font-size-18"> {id ? "EDIT RELATION" : "ADD RELATION"} </h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Admin</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/"> {id ? "Edit Relation" : "Add Relation"} </a>
              </li>
            </ol>
          </div>
              
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                                            

                      <Row>                       
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="url_name">
                            {id ? " Relation" : " Relation"}  
                            </Label>
                            <Input
                              name="url_name"
                              className="form-control"                              
                              type="text"
                              id="url_name"
                              onChange={validation.handleChange}                              
                              value={validation.values.url_name || ""}
                              required
                            />
                          </div>
                        </Col>                      
                      </Row>
                     

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"} 
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/relation_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddRelation
