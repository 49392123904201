import VendorRequestServiceTable from "components/DataTables/VendorRequestServiceTable"
import React from "react"
import { Container } from "reactstrap"

const VendorRequestServiceList = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">REQUEST SERVICE LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Workspace</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Request Service list</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <VendorRequestServiceTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorRequestServiceList
