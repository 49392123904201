import InvoiceListTable from "components/DataTables/InvoiceListTable"
import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
const InvoiceList = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("assetId")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">INVOICE LIST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Invoice List</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <InvoiceListTable />
          </div>
        </Container>
        &nbsp; &nbsp;
        <Link
          to="/personal-information"
          type="submit"
          className="btn btn-primary waves-effect waves-light me-1"
        >
          Back
        </Link>
      </div>
    </React.Fragment>
  )
}

export default InvoiceList
