import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { userId } from "helpers/userId"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { memo, useEffect, useState } from "react"
  import { useNavigate, useLocation } from "react-router-dom"
  import { Container, Form, Button, Spinner } from "reactstrap"
  
  const intialForm = {
    relationsList: [],
    regionsList: [],
    gcil_id: "",
    gcil_fname: "",
    gcil_mname: "",
    gcil_lname: "",
    gcil_email: "",
    gcil_phone: "",
    gcil_relitionship: "",    
    gcil_street1: "",
    gcil_street2: "",
    gcil_city: "",
    gcil_country: "",
    gcil_region: "",
    gcil_zip: "",
  }
  
  const EditGenerateContact = () => {
    const [form, setForm] = useState(intialForm)
    // history for navigation
    const history = useNavigate()
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const relationsList = JSON.parse(localStorage.getItem("relationsList"))
  
    async function fetchRelationsList() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
        )
        const data = await response.json()
  
        if (data?.data) {
          localStorage.setItem("relationsList", JSON.stringify(data?.data))
        }
  
        return data?.data
      } catch (error) {
        console.log(error)
      }
    }
  
    useEffect(() => {
      fetchRelationsList()
    }, [])
  
    const handleForm = async (e, action, key) => {
      let { name, value, type, checked } = e?.target || {}
  
      if (name === "gcil_country") {
        const regionList = (await fetchRegionsList(value)) || []
        setForm({
          ...form,
          [name]: value,
          regionsList: regionList,
          gcil_region: regionList[0]?.region || "",
        })
      } else {
        setForm({ ...form, [name]: value })
      }
    }
  
    const handleFormSubmit = async () => {
      try {
        setApiStatus({ ...apiStatus, inProgress: true })
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_single_list_of_generated_contact`
  
        const data = {
          gcil_id: form.gcil_id,
          gcil_fname: form.gcil_fname,
          gcil_mname: form.gcil_mname,
          gcil_lname: form.gcil_lname,
          gcil_email: form.gcil_email,
          gcil_phone: form.gcil_phone,
          gcil_relitionship: form.gcil_relitionship,          
          gcil_street1: form.gcil_street1,
          gcil_street2: form.gcil_street2,
          gcil_city: form.gcil_city,
          gcil_country: form.gcil_country,
          gcil_region: form.gcil_region,
          gcil_zip: form.gcil_zip,
        }
  
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
  
        const response = await fetch(url, options, {})
  
        const updateOwnersList = await response.json()
  
        if (updateOwnersList?.success === true) {
          setApiStatus({ ...apiStatus, inProgress: false })
          showMessage(updateOwnersList.message, "success")
  
          setTimeout(() => {
            history(-1)
          }, [2000])
        } else {
          showMessage(updateOwnersList?.message, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (e) {
        showMessage(updateOwnersList?.message, "error", false)
      }
    }
  
    const handleImage = (e, action, key) => {
      const { name, files } = e?.target || {}
  
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader()
        reader.readAsDataURL(files[i])
        reader.onload = () => {
          setForm({
            ...form,
            [name]: reader.result,
          })
        }
      }
    }
  
    async function fetchRegionsList(countryName) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
        )
  
        const data = await response.json()
  
        return data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  
    useEffect(() => {
      async function fetchData() {
        try {
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_generate_contact?gcil_id=${id}`
  
          const response = await fetch(url)
  
          const data = await response.json()
  
          if (data?.success) {
            const regionsList =
              (await fetchRegionsList(data?.data[0].gcil_country)) || []
  
            const decryptedData = data?.data?.map(each => {
              
              return {
                ...each,                
                relationsList: relationsList,
                regionsList: regionsList,
              }
            })
  
            const resultData = decryptedData[0]
  
            setForm({ ...form, ...resultData })
          }
        } catch (err) {
          console.log(err)
        }
      }
  
      fetchData()
    }, [])
    return (
      <React.Fragment>
        <div className="page-content">
          <AlertMessage />
          <Container fluid>
          <div className="page-title-box">
              <h4 className="font-size-18">EDIT Generate Person</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="/">Services</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/">Edit Generate Person</a>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
  
                      <Form className="my-4">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="gcil_fname" className="form-label">
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="gcil_fname"
                                id="gcil_fname"
                                placeholder="First Name"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.gcil_fname}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="gcil_mname" className="form-label">
                                Middle Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="gcil_mname"
                                id="gcil_mname"
                                placeholder="Middle Name"
                                value={form.gcil_mname}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="gcil_lname" className="form-label">
                                Last name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="gcil_lname"
                                id="gcil_lname"
                                placeholder="Last name"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.gcil_lname}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label
                                htmlFor="validationTooltipUsername"
                                className="form-label"
                              >
                                Email Address
                              </label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="validationTooltipUsernamePrepend"
                                  >
                                    @
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="gcil_email"
                                  id="validationTooltipUsername"
                                  placeholder="Email Address"
                                  aria-describedby="validationTooltipUsernamePrepend"
                                  disabled=""
                                  onChange={e => handleForm(e, "ownersList")}
                                  value={form.gcil_email}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="validationTooltip03" className="form-label">
                                Phone Number
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                name="gcil_phone"
                                id="validationTooltip03"
                                placeholder="Phone Number"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.gcil_phone}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3 position-relative">
                              <label htmlFor="validationTooltip01" className="form-label">
                                Relationship
                              </label>
                              <select
                                className="form-select"
                                id="validationTooltip01"
                                name="gcil_relitionship"
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.gcil_relitionship}
                              >
                                <option value="" selected="">
                                  Select Relationship
                                </option>
                                {form.relationsList.map(each => (
                                  <option key={each.url_id} value={each.url_name}>
                                    {each.url_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                       
                          <h4 className="card-title">Address</h4>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="validationCustom05" className="form-label">
                                Street 1
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="gcil_street1"
                                id="validationCustom05"
                                placeholder="Street 1"
                                value={form.gcil_street1}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="validationCustom06" className="form-label">
                                Street 2
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="gcil_street2"
                                id="validationCustom06"
                                placeholder="Street 2"
                                value={form.gcil_street2}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
  
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="validationCustom07" className="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="gcil_city"
                                id="validationCustom07"
                                placeholder="City"
                                value={form.gcil_city}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="country" className="form-label">
                                Country
                              </label>
                              <select
                                className="form-select"
                                name="gcil_country"
                                id="country"
                                value={form.gcil_country}
                                onChange={e => handleForm(e, "ownersList")}
                              >
                                <option value="">Select Country</option>
                                <option value="USA" selected="">
                                  USA
                                </option>
                                <option value="INDIA">INDIA</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="region" className="form-label">
                                Region
                              </label>
                              <select
                                className="form-select"
                                name="gcil_region"
                                id="gcil_region"
                                value={form.gcil_region}
                                onChange={e => handleForm(e, "ownersList")}
                              >
                                {form.regionsList?.length === 0 ? (
                                  <option value="" selected="">
                                    Select Region
                                  </option>
                                ) : (
                                  <>
                                  <option value="" defaultValue>
                                    Select Region
                                  </option>
                                  { form.regionsList?.map(each => (
                                    <option value={each.region} key={each.region}>
                                      {each.region}
                                    </option>
                                  )) }
                                  </>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mb-3">
                              <label htmlFor="validationCustom07" className="form-label">
                                zip
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="gcil_zip"
                                oninput="validateZIP()"
                                id="zipCode"
                                placeholder="zip"
                                value={form.gcil_zip}
                                onChange={e => handleForm(e, "ownersList")}
                              />
                              <div id="zipError"></div>
                            </div>
                          </div>
                        </div>
                      </Form>
                  </div>
                </div>
              </div>           
            </div>
  
            
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        Save
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        history(-1)
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default memo(EditGenerateContact)
  