import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddUniversalData = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,    
  })

  const [inititalState, setInitialState] = useState({
    mal_mid: "",
    mal_text: "",
    mal_rep: "",
    mal_process: "",
    mal_app: "",    
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
        mal_mid: Yup.string().required("Please Enter Popup/Message No."),
        mal_text: Yup.string().required("Please Enter Popup Text"),
       // mal_rep: Yup.string().required("Please Enter Replacements"),
       // mal_process: Yup.string().required("Please Enter Process"),
       // mal_app: Yup.string().required("Please Enter Application"),
      
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/updateMessage`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/addMessage`

      const data = {
        mal_id: id || 0,
        mal_mid: values.mal_mid,
        mal_text: values.mal_text,
        mal_rep: values.mal_rep,
        mal_process: values.mal_process,
        mal_app: values.mal_app,        
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addRole = await response.json()

        if (addRole?.success === true) {
          showMessage(addRole.message, "success")
          setTimeout(() => {
            history("/universal_data_list")
          }, 3000)
        } else {
          showMessage("Adding Univerasal Data failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/universal_data_list?mal_id=${
            id || 0
          }`
        )
        const data = await response.json()
        const roleCollection = data?.data[0] || ""
        if (roleCollection) {
          let reInitialValues = { ...inititalState }
          reInitialValues.mal_mid = roleCollection.mal_mid || ""
          reInitialValues.mal_text = roleCollection.mal_text || ""
          reInitialValues.mal_rep = roleCollection.mal_rep || ""
          reInitialValues.mal_process = roleCollection.mal_process || ""
          reInitialValues.mal_app = roleCollection.mal_app || ""          
          setApiStatus({
            ...apiStatus,
            application: [roleCollection.Applications],
            role_name: [roleCollection.Role_Name],
          })

          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

 

  document.title = "Add Universal Data Elements list | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
          <Row>
            <Col xs="12" className="m-6">
              <div className="page-title-box">
                  <h4 className="font-size-18"> {id ? "EDIT UNIVERSAL DATA ELEMENTS" : "ADD UNIVERSAL DATA ELEMENTS"} </h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/">Admin</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/">  {id ? "Edit Universal Data Elements" : "Add Universal Data Elements"} </a>
                    </li>
                  </ol>
                </div>             
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      
                      <Row>
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="mal_mid">
                            Popup/Message No.
                            </Label>
                            <Input
                              name="mal_mid"
                              className="form-control"
                              placeholder="Enter Message No"
                              type="text"
                              id="mal_mid"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mal_mid || ""}
                            />
                          </div>
                        </Col>
                        <Col xl="8">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="mal_text">
                            Popup Text
                            </Label>
                            <Input
                              name="mal_text"
                              className="form-control"
                              placeholder="Enter Popup Text"
                              type="text"
                              id="mal_text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mal_text || ""}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="4">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="mal_rep">
                            Replacements
                            </Label>
                            <Input
                              name="mal_rep"
                              className="form-control"
                              placeholder="Enter Replacements"
                              type="text"
                              id="mal_rep"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mal_rep || ""}
                            />
                          </div>
                        </Col>
                        <Col xl="4">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="mal_process"
                            >
                              Process
                            </Label>
                            <Input
                              name="mal_process"
                              className="form-control"
                              placeholder="Enter Process Name"
                              type="text"
                              id="mal_process"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mal_process || ""}
                            />
                          </div>
                        </Col>
                      
                        <Col xl="4">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="mal_app">
                            Application
                            </Label>
                            <Input
                              name="mal_app"
                              className="form-control"
                              placeholder="Enter Application Name"
                              type="text"
                              id="mal_app"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mal_app || ""}
                            />
                          </div>
                        </Col>                        
                      </Row>

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"} 
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/universal_data_list"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddUniversalData
