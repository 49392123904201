import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Container,
  Form,
  Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"

const AddRoleCollection = props => {
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const [apiStatus, setApiStatus] = useState({
    inProgress: false,
    application: [],
    role_name: [],
  })

  const [inititalState, setInitialState] = useState({
    processName: "",
    application: "",
    roleName: "",
    roleCollection: "",
    mode: "",
    displayOrder: "",
    createBy: "",
    updateBy: "",
    updatedDate: "",
    createDate: "",
    roleStatus: 0,
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: inititalState,
    validationSchema: Yup.object({
      processName: Yup.string().required("Please Select Process"),
      application: Yup.string().required("Please Select Aplication"),
      roleName: Yup.string().required("Please Select Role"),
      roleCollection: Yup.string().required("Please Enter Role Collection"),
      mode: Yup.string().required("Please Enter Mode"),
      displayOrder: Yup.number().required("Please Enter Display Order"),
    }),
    onSubmit: async values => {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = id
        ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/updaterole`
        : `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/addrole`

      const data = {
        RC_id: id || 0,
        process_name: values.processName,
        application: values.application,
        role_name: values.roleName,
        role_collection: values.roleCollection,
        mode: values.mode,
        display_order: values.displayOrder,
        user_id: JSON.parse(localStorage.getItem("userData")).user.user_id,
        role_status: values.roleStatus,
      }

      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
        const response = await fetch(url, options, {})

        const addRole = await response.json()

        if (addRole?.success === true) {
          showMessage(addRole.message, "success")
          setTimeout(() => {
            history("/users_roll_collection")
          }, 3000)
        } else {
          showMessage("Adding role Collection failed", "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      } catch (err) {
        showMessage(err, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    },
  })

  useEffect(() => {
    if (parseInt(id)) {
      const fetchData = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/user_roles_collections?RC_id=${
            id || 0
          }`
        )
        const data = await response.json()
        const roleCollection = data?.data[0] || ""
        if (roleCollection) {
          let reInitialValues = { ...inititalState }
          reInitialValues.processName = roleCollection.Process_Name || ""
          reInitialValues.application = roleCollection.Applications || ""
          reInitialValues.roleName = roleCollection.Role_Name || ""
          reInitialValues.roleCollection = roleCollection.Role_Collection || ""
          reInitialValues.mode = roleCollection.Mode || ""
          reInitialValues.displayOrder = roleCollection.app_order || ""
          reInitialValues.createBy = roleCollection.Created_By || ""
          reInitialValues.updateBy = roleCollection.updated_by || ""
          reInitialValues.updatedDate = roleCollection.Mode || ""
          reInitialValues.createDate = roleCollection.created_Date || ""
          reInitialValues.roleStatus = roleCollection.role_status || 0
          setApiStatus({
            ...apiStatus,
            application: [roleCollection.Applications],
            role_name: [roleCollection.Role_Name],
          })

          setInitialState(reInitialValues)
        }
        // setRoleCollections({ ..., data: data?.data || [] })
      }
      fetchData()
    }
  }, [id])

  useEffect(() => {
    if (validation.values.processName) {
      const fetchApplicationName = async () => {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/role_types?type=1&name=${validation.values.processName}`
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
        try {
          const response = await fetch(url, options, {})
          const application = await response.json()
          if (application?.success === true) {
            setApiStatus({
              ...apiStatus,
              application:
                application?.data?.map(each => each?.pal_application || "") ||
                [],
              role_name: [],
            })
          } else {
            showMessage(application?.message, "error", false)
          }
        } catch (err) {
          showMessage(err, "error", false)
        }
      }

      fetchApplicationName()
    } else {
      setApiStatus({ ...apiStatus, application: [], role_name: [] })
    }
  }, [validation.values])

  useEffect(() => {
    if (validation.values.processName && validation.values.application) {
      const fetchApplicationName = async () => {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/admin/role_types?type=2&name=${validation.values.processName}&process_name=${validation.values.application}`
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
        try {
          const response = await fetch(url, options, {})
          const application = await response.json()
          if (application?.success === true) {
            setApiStatus({
              ...apiStatus,
              role_name:
                application?.data?.map(each => each?.Role_Name || "") || [],
            })

            validation.values.roleName = application?.data[0]?.Role_Name || ""

            validation.handleChange(validation.values)
          } else {
            showMessage(application?.message, "error", false)
          }
        } catch (err) {
          showMessage(err, "error", false)
        }
      }

      fetchApplicationName()
    } else {
      setApiStatus({ ...apiStatus, role_name: [] })
    }
  }, [validation.values])

  document.title = "Add Roles collection list | SOF"
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid={true}>
          <Row>
            <Col xs="12" className="m-6">
            <div className="page-title-box">
                  <h4 className="font-size-18"> {id ? "EDIT ROLES COLLECTION" : "ADD ROLES COLLECTION"} </h4>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/">User Roles</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/">  {id ? "Edit Roles Collection" : "Add Roles Collection"} </a>
                    </li>
                  </ol>
                </div>  
              
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="p-3">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      
                      <Row>
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="processName">
                              Process Name
                            </Label>
                            <Input
                              name="processName"
                              className="form-control"
                              type="select"
                              id="processName"
                              onChange={validation.handleChange}
                              value={validation.values.processName}
                            >
                              <option value="">Select</option>
                              <option value="Account Info">Account Info</option>
                              <option value="Services">Services</option>
                              <option value="Workspace">Workspace</option>
                              <option value="Platform">Platform</option>
                            </Input>
                          </div>
                        </Col>
                        <Col xl="6">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="application">
                              Application
                            </Label>
                            <Input
                              name="application"
                              className="form-control"
                              type="select"
                              id="application"
                              onChange={validation.handleChange}
                              value={validation.values.application}
                            >
                              <option value="">Select</option>
                              {apiStatus.application.map(each => (
                                <option value={each}>{each}</option>
                              ))}
                            </Input>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="3">
                          <div className="mb-3 register-passwordMain">
                            <Label className="form-label" htmlFor="roleName">
                              Role Name
                            </Label>
                            <Input
                              name="roleName"
                              className="form-control"
                              type="select"
                              id="roleName"
                              onChange={validation.handleChange}
                              value={validation.values.roleName}
                            >
                              <option value="">Select</option>
                              {apiStatus.role_name.map(each => (
                                <option value={each}>{each}</option>
                              ))}
                            </Input>
                          </div>
                        </Col>
                        <Col xl="3">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="roleCollection"
                            >
                              Role Collection
                            </Label>
                            <Input
                              name="roleCollection"
                              className="form-control"
                              placeholder="Enter Role Collection"
                              type="roleCollection"
                              id="roleCollection"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.roleCollection || ""}
                            />
                          </div>
                        </Col>
                        {id && (
                          <Col xl="3">
                            <div className="mb-3 register-passwordMain">
                              <Label
                                className="form-label"
                                htmlFor="roleStatus"
                              >
                                Role Status
                              </Label>
                              <Input
                                name="roleStatus"
                                className="form-control"
                                type="select"
                                id="roleStatus"
                                onChange={validation.handleChange}
                                value={validation.values.roleStatus}
                              >
                                <option value={0}>Active</option>
                                <option value={1}>In-Active</option>
                              </Input>
                            </div>
                          </Col>
                        )}
                        <Col xl="3">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="mode">
                              Mode
                            </Label>
                            <Input
                              name="mode"
                              className="form-control"
                              placeholder="Enter Mode"
                              type="mode"
                              id="mode"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mode || ""}
                            />
                          </div>
                        </Col>
                        <Col xl="3">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="displayOrder"
                            >
                              Display Order
                            </Label>
                            <Input
                              name="displayOrder"
                              className="form-control"
                              placeholder="Enter Display Order"
                              type="displayOrder"
                              id="displayOrder"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.displayOrder || ""}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-row justify-content-start">
                        <div>
                          {apiStatus.inProgress ? (
                            <button className="btn btn-primary" type="button">
                              <Spinner color="light" size="sm">
                                Loading ...
                              </Spinner>
                            </button>
                          ) : (
                            <button className="btn btn-primary" type="submit">
                              {id ? "Save" : "Submit"}
                            </button>
                          )}
                        </div>
                        <div>
                          <Link
                            to="/users_roll_collection"
                            className="btn btn-danger mx-2"
                            type="button"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddRoleCollection
