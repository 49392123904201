import BPServiceRequestTable from "components/DataTables/BPServiceRequestTable"
import React, { useEffect } from "react"
import { Container } from "reactstrap"

const BPServiceRequestList = () => {
  useEffect(() => {
    localStorage.removeItem("tab")
    localStorage.removeItem("assetId")
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">SERVICE REQUEST</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Workspace</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Service Request List</a>
              </li>
            </ol>
          </div>
          <div className="card">
            <BPServiceRequestTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BPServiceRequestList
