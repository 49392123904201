import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import russia from "../assets/images/flags/russia.jpg"
import india from "../assets/images/flags/india.png";

export const languages = {
  sp: {
    label: "Spanish",
    flag: spain,
  },
  gr: {
    label: "German",
    flag: germany,
  },
  it: {
    label: "Italian",
    flag: italy,
  },
  rs: {
    label: "Russian",
    flag: russia,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}


export const countries = {
  USA : {
    label: "USA",
    flag: usFlag,
  },
  INDIA : {
    label: "India",
    flag: india,
  }
}

// export default languages
