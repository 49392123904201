import GeneratedWillDocumentListTable from "components/DataTables/GeneratedWillDocumentListTable"
import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { Link } from "react-router-dom"

const GeneratedWillDocumentList = () => {  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
          <h4 className="font-size-18">Generated Document</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Services</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/will-documet-list">Generated Document List</Link>
                </li>
              </ol>
          </div>
          <div className="card">
            <GeneratedWillDocumentListTable />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GeneratedWillDocumentList
