import React, { useEffect, useState } from "react"

const { MDBDataTable } = require("mdbreact")
const { Row, Col, Card, CardBody, CardTitle } = require("reactstrap")
import "../../styles/datatables.scss"
import { Link } from "react-router-dom"
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"


const BusinessPartnerTable = () => {
  const [businessPartner, setBusinessPartner] = useState({ data: [] })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_business_partner`
      )

      const data = await response.json()

      const decryptedData = data?.data?.map(each => {
        return {
          ...each,
          pro_key: decryptData(each.pro_key),
          pro_purchase_date: decryptData(each.pro_purchase_date),
          pro_property_type: decryptData(each.pro_property_type),
          pro_asset_value: decryptData(each.pro_asset_value),
          pro_asset_value_currency: decryptData(each.pro_asset_value_currency),
          pro_present_value: decryptData(each.pro_present_value),
          pro_present_value_currency: decryptData(
            each.pro_present_value_currency
          ),
          pro_number_of_owners: decryptData(each.pro_number_of_owners),
          pro_county_asset: decryptData(each.pro_county_asset),
        }
      })
      setBusinessPartner({ ...businessPartner, data: decryptedData || [] })
    }
    fetchData()
  }, [])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_business_partner`
      const userData = JSON.parse(localStorage.getItem("userData"))
      const data = {
        pro_uid: userData?.user?.user_id,
        vil_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setBusinessPartner({
          ...businessPartner,
          data: businessPartner?.data?.filter(each => each.vil_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData.message, "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Business Name",
        field: "Bus_Name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Business ID",
        field: "BusKey",
        sort: "asc",
        width: 200,
      },
      {
        label: "Business Type",
        field: "BusType",
        sort: "asc",
        width: 100,
      },
      {
        label: "Business Email",
        field: "EmailID",
        sort: "asc",
        width: 150,
      },
      {
        label: "Business Phone",
        field: "Phone",
        sort: "asc",
        width: 100,
      },      
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: businessPartner?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/add_business_partner?id=${each.vil_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
          Bus_Name: each?.Bus_Name,
          BusKey: each?.BusKey,
          BusType: each?.BusType || "",
        EmailID: each?.EmailID || "",
        Phone: each?.Phone || "",        
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.vil_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each.Bus_Name}?`
                  ) &&
                  handleRemoveRole(each.vil_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">Business Partners list</CardTitle>
              <div className="col-12 d-flex">
                <div>
                  <Link
                    to="/add_business_partner"
                    className="btn btn-danger my-2"
                    type="button"
                  >
                    Add Business Partner
                  </Link>
                </div>
              </div>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BusinessPartnerTable


export const BpListOfExistingOwners = ({ bpId, activeTab }) => {
  const [ListOfOwners, setListOfOwners] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/service_contact_list?vil_vid=${bpId}`

        const response = await fetch(url)

        const data = await response.json()

        const decryptedData = data?.data?.map(each => {
          return {
            ...each,
            First_name: each.First_name || "",
            Last_name: each.Last_name || "",
            CDesignation: each.CDesignation || "",
            CEmailID: each.CEmailID || "",
            CPhone: each.CPhone || "",
            ContactKey: each.ContactKey || "",
          }
        })

        setListOfOwners({ ...ListOfOwners, data: decryptedData || [] })
      } catch (err) {}
    }

    fetchData()
  }, [bpId, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_service_contact`
      const data = {
        vcl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfOwners({
          ...ListOfOwners,
          data: ListOfOwners?.data?.filter(each => each.vcl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Full Name",
        field: "fullname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Designation",
        field: "CDesignation",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "CEmailID",
        sort: "asc",
        width: 100,
      },
      {
        label: "Phone",
        field: "CPhone",
        sort: "asc",
        width: 100,
      },
      {
        label: "ContactKey",
        field: "ContactKey",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListOfOwners?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit-business-contact?id=${each.vcl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
        fullname: `${each?.First_name} ${each?.Last_name}`,
        CDesignation: each?.CDesignation,
        CEmailID: each?.CEmailID || "",
        CPhone: each?.CPhone || "",
        ContactKey: each?.ContactKey || "",
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.vcl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.First_name} ${each?.Last_name}?`
                  ) &&
                  handleRemoveRole(each.vcl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export const BpListOfExistingService = ({ bpId, activeTab }) => {
  const [ListOfService, setListOfService] = useState({ data: [] })

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_business_service_list?vil_vid=${bpId}`

        const response = await fetch(url)

        const data = await response.json()

        const decryptedData = data?.data?.map(each => {
          return {
            ...each,
            pol_fname: decryptData(each.pol_fname) || "",
            pol_lname: decryptData(each.pol_lname) || "",
            pol_relitionship: decryptData(each.pol_relitionship) || "",
            pol_persant: decryptData(each.pol_persant) || "",
          }
        })

        setListOfService({ ...ListOfService, data: decryptedData || [] })
      } catch (err) {}
    }

    fetchData()
  }, [bpId, activeTab])

  const handleRemoveRole = async id => {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_business_service`
      const data = {
        vsl_id: id,
      }
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})
      const removeData = await response.json()

      if (removeData?.success === true) {
        setListOfService({
          ...ListOfService,
          data: ListOfService?.data?.filter(each => each.vsl_id !== id),
        })

        showMessage(removeData.message, "success")
      } else {
        showMessage(removeData.message, "error", false)
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  const data = {
    columns: [
      {
        label: "S. No.",
        field: "sno",
        sort: "asc",
        width: 150,
      },
      {
        label: "Edit",
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Service Code",
        field: "scode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Service Name",
        field: "sname",
        sort: "asc",
        width: 200,
      },
      {
        label: "Service Type",
        field: "ServiceType",
        sort: "asc",
        width: 100,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: ListOfService?.data?.map((each, index) => {
      return {
        sno: <div>{index + 1} </div>,
        edit:
          (
            <Link
              to={`/edit-business-service?vslid=${each.vsl_id}&type=0`}
              className="btn btn-outline-primary btn-sm edit"
              title="Edit"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>
          ) || "",
          scode: `${each?.scode}`,
          sname: each?.sname,
          ServiceType: each?.ServiceType || "",
          amount: each?.amount || "",
          
        action:
          (
            <Col xl={3} lg={4} sm={6}>
              <i
                className="ion ion-md-trash"
                title="Delete"
                type="button"
                onClick={() =>
                  each.vsl_id &&
                  window.confirm(
                    `Are you sure do you want to delete ${each?.scode} ?`
                  ) &&
                  handleRemoveRole(each.vsl_id)
                }
              ></i>
            </Col>
          ) || "",
      }
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable
                responsive
                bordered
                data={data}
                noBottomColumns={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}


