import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { Suspense, lazy, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"
import "../../../src//styles/errorfield.scss"
import { loginUserData, userId } from "helpers/userId"

import {
  Spinner,
  Container,
  Form,
  NavItem,
  NavLink,
  Button,
  TabContent,
  TabPane,
} from "reactstrap"


const initialFormDetails = {
               
  scode: "",
  sname: "",
  ServiceType: "",       
  descrip: "",
  amount: "",
  sofamount: "",
  subtype: "",
  Features: "",
  PackageDetails: "",
  chargecustomer: "",
  Currency: "",  
  Reqservice_type:"",
  ReqDate:"",
  srv_id:"",
  bp_attachment:"",   

}

const EditBPServiceRequest = () => {
  const [activeTab, setactiveTab] = useState(
    parseInt(JSON.parse(localStorage.getItem("tab"))) || 1
  )
  const [form, setForm] = useState(initialFormDetails);
 
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  
  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const Reqid = searchParams.get("Reqid")
  

  useEffect(() => {
    // Function to set the form data
    async function setFormData(data) {
      if (data.success) {
        const form = data?.data;
  
        const BpvendorInformation = {
          Reqid: form.Reqid,
          ReqNo: form.ReqNo,
          buserid: form.buserid,
          BusKey: form.BusKey,
          UserKey: form.UserKey,
          BPReq_Date: form.BPReq_Date,
          BPReqStatus: form.BPReqStatus,
          SrvType: form.SrvType,
          SrvCode: form.SrvCode,
          SrvName: form.SrvName,
          CustomerPhone: form.CustomerPhone,
          CustomerEmailID: form.CustomerEmailID,
          ReqMessage: form.ReqMessage,
          ReqNotes: form.ReqNotes,
          DeliveryDate: form.DeliveryDate,
          Delivery_Notes: form.Delivery_Notes,
          InvoiceDate: form.InvoiceDate,
          InvoiceNotes: form.InvoiceNotes,
          sofInvoiceDate: form.sofInvoiceDate,
          sofInvoiceNotes: form.sofInvoiceNotes,
          CusFeedBack: form.CusFeedBack,
          CusRating: form.CusRating,
          bp_attachment: form.bp_attachment
        };
  
        // Update the form state with the fetched data
        setForm((form) => ({
          ...form,         
          ...BpvendorInformation
        }));
      }
    }
  
    // Fetch data when Reqid is present
    if (Reqid && activeTab) {
      const fetchDataEditData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_bp_noties_invoce?Reqid=${Reqid}&user_id=${userId}`
          );
          const data = await response.json();
          await setFormData(data); // Set form data after fetching
        } catch (error) {
          console.error("Error fetching form data:", error); // Error handling
        }
      };
  
      fetchDataEditData(); // Call the function to fetch data
    }
  }, [activeTab, Reqid, userId]); // Dependencies
  
 

  const handleForm = async (e, action, key) => {
    let { name, value, type, checked } = e?.target || {}

    switch (action) {
      case "vendorService":           
            setForm({
              ...form,
              [name]: value,
            })
        break               
       default:
        null
    }
  }

    // for image upload
const handleImage = async e => {
  const { name, files } = e.target
  const result = await FileUpload(files)

  setForm({ ...form, [name]: result.files?.[0]?.url })
}

      
    const handleFormSubmit = async () => {
      switch (activeTab) {
         case 1:           
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
             
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_notes_invoice_bp`;
              
            const data = {              
              Reqid: Reqid, 
              BPReqStatus: form.BPReqStatus,                      
              ReqNotes: form.ReqNotes,
              DeliveryDate: form.DeliveryDate, 
              Delivery_Notes: form.Delivery_Notes,
              InvoiceDate: form.InvoiceDate, 
              InvoiceNotes: form.InvoiceNotes,   
              bp_attachment: form.bp_attachment,     
                                
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
              
            setApiStatus({ ...apiStatus, inProgress: true })

            const response = await fetch(url, options, {})

            const addOrUpdatenotesandinvoiceInformation = await response.json()

            if (addOrUpdatenotesandinvoiceInformation?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(4)
              localStorage.setItem("tab", 4)                
              showMessage(addOrUpdatenotesandinvoiceInformation.message, "success")
            } else {
              showMessage(addOrUpdatenotesandinvoiceInformation?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}    
        break
         
      }    
    }
  

 

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
          <h4 className="font-size-18">EDIT REQUEST SERVICE</h4>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="/">Operations</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/">Edit Request Service</a>
            </li>
          </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                      
                        <TabPane tabId={1}>
                        <Form className="mb-4"> 
                              <div className="row"> 

                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="Bus_Name"
                                          className="form-label"
                                      >
                                          Req #
                                          <span className="text-danger">*</span>
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="Bus_Name"
                                          id="Bus_Name"
                                          placeholder="Req #"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.ReqNo}
                                          disabled

                                      />
                                      

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="Phone"
                                          className="form-label"
                                      >
                                          BP Req Date
                                      </label>
                                      <input
                                          type="date"
                                          className="form-control"
                                          name="Phone"
                                          id="Phone"
                                          placeholder="Business Phone"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.BPReq_Date}
                                          disabled

                                      />

                                      </div>
                                  </div>
                                  <div className="col-md-4">                                        
                                  </div>

                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="SrvType"
                                          className="form-label"
                                      >
                                          Service Type
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="SrvType"
                                          id="SrvType"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.SrvType}
                                          disabled
                                      />
                                      </div>
                                  </div> 
                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="SrvCode"
                                          className="form-label"
                                      >
                                          Service Code
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="SrvCode"
                                          id="SrvCode"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.SrvCode}
                                          disabled
                                      />
                                      </div>
                                  </div> 
                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="SrvName"
                                          className="form-label"
                                      >
                                          Service Name
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="SrvName"
                                          id="SrvName"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.SrvName}
                                          disabled
                                      />
                                      </div>
                                  </div>    

                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="Bus_Name"
                                          className="form-label"
                                      >
                                         Customer First Name
                                          <span className="text-danger">*</span>
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="Bus_Name"
                                          id="Bus_Name"
                                          placeholder="Customer First Name"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.Bus_Name}
                                          disabled
                                      />
                                      

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="Phone"
                                          className="form-label"
                                      >
                                          Customer Middle Name
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="Phone"
                                          id="Phone"
                                          placeholder="Customer Middle Name"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.Phone}
                                          disabled
                                      />

                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="EmailID"
                                          className="form-label"
                                      >
                                          Customer Last Name
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="EmailID"
                                          id="EmailID"
                                          placeholder="Customer Last Name"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.EmailID}
                                          disabled
                                      />
                                      </div>
                                  </div> 


                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="EmailID"
                                          className="form-label"
                                      >
                                          Customer Email Address
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="EmailID"
                                          id="EmailID"
                                          placeholder="Customer Email Address"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.CustomerEmailID}
                                          disabled
                                      />
                                      </div>
                                  </div> 

                                  <div className="col-md-4">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="EmailID"
                                          className="form-label"
                                      >
                                          Customer Phone Number
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="EmailID"
                                          id="EmailID"
                                          placeholder="Customer Phone Number"
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.CustomerPhone}
                                          disabled
                                      />
                                      </div>
                                  </div>  

                                   

                                  <div className="col-md-4">
                                          <div className="mb-3 position-relative">
                                          <label htmlFor="BPReqStatus" className="form-label">
                                          Request Status
                                          </label>
                                          <select
                                              className="form-select"
                                              id="BPReqStatus"
                                              name="BPReqStatus"                                        
                                              onChange={e => handleForm(e, "vendorService")}
                                              value={form.BPReqStatus}                      > 
                                              <option value="In Processing" >In Processing</option> 
                                              <option value="Delivered">Delivered</option> 
                                              <option value="Invoiced" >Invoiced</option>                      
                                          </select>                     
                                          </div>
                                  </div> 

                                  <div className="col-md-12">
                                          <div className="mb-3 position-relative">
                                          <label htmlFor="ReqMessage" className="form-label">
                                          Message
                                          </label>
                                          <textarea
                                          className="form-control"
                                          rows="5"
                                          for="validationTooltip16"
                                          name="ReqMessage"
                                          onkeypress="check_length(this.form);"
                                          onkeydown="check_length(this.form);"
                                          onChange={e => handleForm(e, "vendorService")}
                                              value={form.ReqMessage} 
                                              disabled  
                                          cols="30"
                                          ></textarea>
                                          <span className="help-inline col-xs-12 col-sm-7"></span>
                                          </div>
                                  </div>                           


                                  <div className="col-md-12">
                                    <label
                                        htmlFor="ReqNotes"
                                        className="form-label"
                                    >
                                        Request Notes
                                        
                                    </label>
                                    <textarea
                                        id="ReqNotes"
                                        name="ReqNotes"
                                        rows="3"
                                        className="form-control"
                                        placeholder="Request Notes"
                                        required
                                        onChange={e => handleForm(e, "vendorService")}
                                        value={form.ReqNotes}
                                        
                                    ></textarea>
                                        
                                  </div>
                                  

                                  <div className="col-md-4">
                                          <div className="mb-3 position-relative">
                                          <label htmlFor="DeliveryDate" className="form-label">
                                          Delivery Date
                                          </label>
                                          <input
                                              type="date"
                                              className="form-control"
                                              name="DeliveryDate"
                                              id="DeliveryDate"                                                                        
                                              onChange={e => handleForm(e, "vendorService")}
                                              value={form.DeliveryDate}
                                              
                                          />
                                          </div>
                                  </div>
                                  <div className="col-md-8">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="Delivery_Notes"
                                          className="form-label"
                                      >
                                          Delivery Notes
                                          
                                      </label>
                                      <textarea
                                          id="Delivery_Notes"
                                          name="Delivery_Notes"
                                          rows="3"
                                          className="form-control"
                                          placeholder="Delivery Notes"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.Delivery_Notes}
                                          
                                      ></textarea>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                          <div className="mb-3 position-relative">
                                          <label htmlFor="InvoiceDate" className="form-label">
                                          Invoice Date
                                          </label>
                                          <input
                                              type="date"
                                              className="form-control"
                                              name="InvoiceDate"
                                              id="InvoiceDate"                                                                        
                                              onChange={e => handleForm(e, "vendorService")}
                                              value={form.InvoiceDate}
                                              
                                          />
                                          </div>
                                  </div>
                                   <div className="col-md-8">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="InvoiceNotes"
                                          className="form-label"
                                      >
                                          Invoice Notes
                                          
                                      </label>
                                      <textarea
                                          id="InvoiceNotes"
                                          name="InvoiceNotes"
                                          rows="3"
                                          className="form-control"
                                          placeholder="Invoice Notes"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.InvoiceNotes}
                                          
                                      ></textarea>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                          <div className="mb-3 position-relative">
                                          <label htmlFor="sofInvoiceDate" className="form-label">
                                          SOF Invoice Date
                                          </label>
                                          <input
                                              type="date"
                                              className="form-control"
                                              name="sofInvoiceDate"
                                              id="sofInvoiceDate"                                                                        
                                              onChange={e => handleForm(e, "vendorService")}
                                              value={form.sofInvoiceDate}
                                              disabled
                                              
                                          />
                                          </div>
                                  </div>
                                  <div className="col-md-8">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="sofInvoiceNotes"
                                          className="form-label"
                                      >
                                          SOF Invoice Notes
                                          
                                      </label>
                                      <textarea
                                          id="PackageDetails"
                                          name="sofInvoiceNotes"
                                          rows="3"
                                          className="form-control"
                                          placeholder="SOF Invoice Notes"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.sofInvoiceNotes}
                                          disabled
                                      ></textarea>
                                      </div>
                                  </div>

                                  <div className="col-md-12">
                                      <div className="mb-3">
                                      <label
                                          htmlFor="CusFeedBack"
                                          className="form-label"
                                      >
                                          Customer Feedback
                                          
                                      </label>
                                      <textarea
                                          id="CusFeedBack"
                                          name="CusFeedBack"
                                          rows="3"
                                          className="form-control"
                                          placeholder="Customer Feedback"
                                          required
                                          onChange={e => handleForm(e, "vendorService")}
                                          value={form.CusFeedBack}
                                          disabled
                                      ></textarea>
                                      </div>
                                  </div>

                                  <div className="col-md-4">
                                        <div className="mb-3 position-relative">
                                        <label htmlFor="CusRating" className="form-label">
                                        Customer CusRating
                                        </label>
                                        <select
                                            className="form-select"
                                            id="CusRating"
                                            name="CusRating"                                        
                                            onChange={e => handleForm(e, "vendorService")}
                                            value={form.CusRating}  
                                            disabled                                                                 >                        
                                            <option value="">Select Rating</option>  
                                            <option value="1" >1</option>
                                            <option value="2" >2</option>
                                            <option value="3" >3</option>
                                            <option value="4" >4</option>
                                            <option value="5" >5</option>
                                                                  
                                        </select>                     
                                        </div>
                                  </div>
                                  

                                  <div className="col-md-4">
                                    <div className="mb-3 position-relative">
                                       <label htmlFor="CusRating" className="form-label">
                                       Upload Attachment
                                        </label>
                                  <input
                                    type="file"
                                    name="bp_attachment"
                                    accept="image/*"
                                    className="upload"
                                    id="bp_attachment"
                                    //style={{ display: "none" }}
                                    onChange={handleImage}
                                  />                                   
                                 </div>
                                </div>

                                
                                 <div className="col-md-4">
                                   <div className="mb-3 position-relative">                                         
                                        <Button  color="primary">
                                        Attachment
                                         </Button>                                          
                                    </div>
                                 </div>


                              </div>
                            </Form>
                        </TabPane>  
                      </TabContent>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {Reqid || Reqid ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        history(-1)
                      }}
                    >
                      Cancel
                    </Button>                 
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditBPServiceRequest
